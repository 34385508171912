<form>
  <mat-checkbox
    class="scope-ui-checkbox"
    [disableRipple]="true"
    [(ngModel)]="value"
    [formControl]="control"
    (change)="updateValue($event.checked)">
    {{ title }}
  </mat-checkbox>
</form>
