import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule } from '@angular/forms';
import { SharedModule } from "@shared/shared.module";
import { NgxMaskDirective } from "ngx-mask";
import { MatInput } from '@angular/material/input'
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'scope-ui-chips',
  standalone: true,
  imports: [CommonModule, FormsModule, SharedModule, NgxMaskDirective],
  templateUrl: './scope-ui-chips.component.html',
  styleUrls: ['./scope-ui-chips.component.scss']
})

export class ScopeUiChipsComponent {
  @Input() inputLabel!: string;

  @Input() addOnBlur: boolean = true;

  @Input() values: any[] = [];

  @Input() type?: string = 'text';

  @Input() set isDisabled(value: boolean) {
    if (value) {
      this.control.disable()
    } else {
      this.control.enable()
    }
  }

  @ViewChild(MatInput) input: MatInput

  @Input() control: FormControl = new FormControl([])

  @Output() valuesChange = new EventEmitter<any[]>()

  readonly separatorKeysCodes = [ENTER, COMMA] as const

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.values.push(value);
      this.valuesChange.emit(this.values);
      this.control.setValue(this.values);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(option: string): void {
    const index = this.values.indexOf(option);

    if (index >= 0) {
      this.values.splice(index, 1);
      this.valuesChange.emit(this.values);
      this.control.setValue(this.values);
    }
  }

  edit(option: string, event: MatChipEditedEvent) {
    // Prevents default behaviour of keeping focus on edited chip
    event.chip.disabled = true;
    setTimeout(() => event.chip.disabled = false);

    const value = event.value.trim();

    // Remove option if it no longer has a name
    if (!value) {
      this.remove(option);
      this.valuesChange.emit(this.values);
      this.control.setValue(this.values);
      return;
    }

    // Edit existing option
    const index = this.values.indexOf(option);
    if (index >= 0 && this.values[index] != value) {
      this.values[index] = value;
      this.valuesChange.emit(this.values);
      this.control.setValue(this.values);
    }
  }
}
