<form>
  <ng-container *ngIf="!isTextarea">
    <mat-form-field [ngClass]="inputClass" subscriptSizing="dynamic" [floatLabel]="inputLabel ? 'auto':  'always'">
      <mat-label>{{ inputLabel }}</mat-label>
      <input
        matInput
        type="{{ type }}"
        [placeholder]="inputPlaceholder"
        [required]="required"
        [formControl]="control"
        [class.no-arrows]="noArrows"
        (blur)="onInputBlur($event)"
        (input)="onInput($event)"
        (keydown)="onKeydown($event)"
        (paste)="preventPaste ? onPaste($event) : null"
        mask="{{mask}}"
        prefix="{{prefix}}"
        suffix="{{suffix}}"
        thousandSeparator=","
        [maxlength]="_isCurrency ? 14 : null"
        min="{{minimum}}"
        max="{{maximum}}" />
      <mat-hint *ngIf="resultsCount" align="start">Results found: {{resultsCount}}</mat-hint>
      <mat-error *ngIf="control.hasError('required')">This is required</mat-error>
      <mat-error *ngIf="control.hasError('min')">Cannot be less than {{minimum}}</mat-error>
      <mat-error *ngIf="control.hasError('max')">Cannot be more than {{maximum}}</mat-error>
      <mat-error *ngIf="control.hasError('conflict')">This name already exists</mat-error>
      <mat-error *ngIf="control.hasError('already_exists')">{{ control.errors['already_exists'] }}</mat-error>
      <mat-error *ngIf="control.hasError('custom')">{{control.getError('custom')}}</mat-error>
      <mat-icon *ngIf="icon" class="material-symbols-rounded" style="padding: 2px" matPrefix>{{ icon }}</mat-icon>
      <mat-icon *ngIf="control.value && cancelIcon" (click)="cancel()" class="material-symbols-rounded fill cancel-icon" matSuffix>{{ cancelIcon }}</mat-icon>
      <mat-icon *ngIf="control.value && fetchIcon" (click)="fetch()" class="material-symbols-rounded fill cancel-icon orange f-16 pointer" matTooltip="Make a call to fetch data from external system" matSuffix>{{ fetchIcon }}</mat-icon>
      <mat-icon *ngIf="_isDate" class="material-symbols-rounded orange f-16 pointer" matSuffix
                (click)="moveDate(-1)">
        chevron_left
      </mat-icon>
      <mat-icon *ngIf="_isDate" class="material-symbols-rounded orange f-16 pointer" matSuffix
                (click)="moveDate(1)">
        chevron_right
      </mat-icon>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="isTextarea">
    <mat-form-field [ngClass]="inputClass" subscriptSizing="dynamic" [floatLabel]="inputLabel ? 'auto':  'always'">
      <mat-label>{{ inputLabel }}</mat-label>
      <textarea
        matInput
        type="{{ type }}"
        placeholder="{{ inputPlaceholder }}"
        [ngClass]="inputClass"
        [class.overflow-hidden]="autoExpand"
        [required]="required || control.hasValidator(Validators.required)"
        [formControl]="control"
        [rows]='textareaRows'
        (blur)="onInputBlur($event)"
        (input)="onInput($event)"
        (keydown)="onKeydown($event)"></textarea>
      <mat-error *ngIf="control.hasError('required')">This is required</mat-error>
      <mat-error *ngIf="control.hasError('min')">Cannot be less than {{minimum}}</mat-error>
      <mat-error *ngIf="control.hasError('max')">Cannot be more than {{maximum}}</mat-error>
      <mat-icon *ngIf="icon" class="material-symbols-rounded" style="padding: 2px" matPrefix>{{ icon }}</mat-icon>
    </mat-form-field>
  </ng-container>
</form>
