import { Type } from 'class-transformer';
import { User } from '@core/model/user.model';
import { ScenarioCategory } from '@app/features/scope-overview/model/scenario-category.model';

export class Scenario {
  id!: number
  name!: string
  categories: ScenarioCategory[] = []
  @Type(() => User) createdBy!: User
  @Type(() => Date) createdOn!: Date
  @Type(() => Date) updatedOn?: Date
}
