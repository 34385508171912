import { Injectable } from '@angular/core'
import { ScopeSubmitter } from '@app/core/model/scope-submitter.model'
import { ScopeVersion, StatusType } from '@app/core/model/scope-version'
import { User } from '@app/core/model/user.model'
import { ScopeIdentity } from '../model/scope-identity'
import { ScopeReviewer } from '../model/scope-reviewer.model'
import { Store } from '@ngrx/store'
import { ScopeStateMember, ScopeTeamMember } from '@app/core/model/scope-team.model'
import { DeliverableReviewer } from '../model/deliverable-reviewer.interface'
import { Deliverable } from '@app/features/scoping/models/deliverable.model'
import { LanguageService } from '@app/core/service/language.service'
import { DeliverableReviewTableData, VoteReviewStatus, Votes } from '../model/deliverable-review-table.model'
import { ApprovalChecks } from '@app/features/scope-overview/model/approval-checks.interface'
import { ScopeTraffickingService } from '@app/features/scope-overview/service/scope-trafficking.service'
import { AuthService } from '@core/service/auth.service'

@Injectable({
  providedIn: 'root',
})
export class ApprovalFlowService {
  stateChecks: ApprovalChecks;

  votes: Votes = {
    noVote: {
      icon: 'help_outline',
      classes: 'pending',
    },
    voteAccepted: {
      icon: 'done',
      classes: 'accepted',
    },
    voteRejected: {
      icon: 'clear',
      classes: 'rejected',
    },
  }

  constructor(private store: Store, private lang: LanguageService, private traffickingService: ScopeTraffickingService, private authService: AuthService) {}

  defaultStateChecks() {
    this.stateChecks = {
      isDraft: false,
      isInReview: false,
      isStateReviewed: false,
      isStateAgencyApproved: false,
      isStateClientApproved: false,
      isCurrentVersion: false,
      teamRestrictedActionAllowed: false,
      draftState: {
        currentMember: {} as ScopeStateMember,
      },
      inReviewState: {
        isAlreadyReviewed: false,
        currentMember: {} as ScopeStateMember,
        isAccepted: false,
        isRejected: false,
      },
      reviewedState: {
        isAlreadyAgreed: false,
        currentMember: {} as ScopeStateMember,
      },
      deliverableReviewState: {
        areAllDeliverablesReviewedByMe: false,
      },
      agencyApprovedState: {
        currentMember: {} as ScopeStateMember,
        isAlreadyAgreed: false,
      },
      clientApprovedState: {
        currentMember: {} as ScopeStateMember,
        isAlreadyAgreed: false,
      },
      traffickedState: {
        isTraffickable: false,
        isTraffickableThroughWorkato: false,
        isTrafficking: false,
        isTraffickedDisabled: false,
        isReadyToTraffic: false,
        currentMember: {} as ScopeTeamMember,
        identificationType: false,
        scopeTraffickingComponent: false,
      },
      overallState: {
        currentMemberState: '',
        showQuickAddMember: false,
      },
      deliverableStatus: {
        deliverableAccepted: false,
        deliverableRejected: false,
        deliverablesInReview: false,
        deliverableInReview: undefined,
        isApprover: false,
        isStateApproved: false,
      },
      deliverableVote: {
        vote: '',
        deliverableId: undefined,
      },
      currentStateMembers: undefined
    };
  }

  isCollaborator(scope: ScopeVersion): boolean {
    return scope.identity.team.collaborators.some((a) => {
      return a.user.id === this.authService.loggedInUser.id
    })
  }

  isReviewer(scope: ScopeVersion): boolean {
    return scope.identity.team.reviewers.some((a) => {
      return a.user.id === this.authService.loggedInUser.id
    })
  }

  isApprover(scope: ScopeVersion): boolean {
    return scope.identity.team.approvers.some((a) => {
      return a.user.id === this.authService.loggedInUser.id
    })
  }

  isTrafficker(scope: ScopeVersion): boolean {
    return scope.identity.team.traffickers.some((a) => {
      return a.user.id === this.authService.loggedInUser.id
    })
  }

  isSuperCollaborator(currentUser: User, currentScope: ScopeVersion): boolean {
    let superVoter = false
    if (currentUser) {
      superVoter = currentScope.identity.team.findCollaboratorByUserId(currentUser.id)?.superVoter
    }
    return currentScope.collaborators.length > 1 && superVoter
  }

  isSuperApprover(currentUser: User, currentScope: ScopeVersion): boolean {
    let superVoter = false
    if (currentUser) {
      superVoter = currentScope.identity.team.findApproverByUserId(currentUser.id)?.superVoter
    }
    return currentScope.approvers.length > 1 && superVoter
  }

  /* Trafficking State Checks */

  getDeliverables(currentScope: ScopeVersion) {
    return currentScope.deliverables.flatMap((s) => s)
  }

  isReadyToTraffic(currentScope: ScopeVersion) {
    let deliverables = this.getDeliverables(currentScope)
    return (
      (currentScope.readyToTraffic || deliverables.some((it) => it.readyToTraffic))
    )
  }

  isTrafficDisabled(currentScope: ScopeVersion) {
    let deliverables = this.getDeliverables(currentScope)
    return !this.isReadyToTraffic(currentScope) || !deliverables.every((it) => !it.isTradeProcessing()) || !this.isTraffickableOnStatus(currentScope)
  }

  isTraffickableOnStatus(currentScope: ScopeVersion) {
    var hasSetting = (setting) => {
      return this.authService.loggedInUser.company.hasApplicationSetting(setting)
    }

    return (
      (this.isStateDraft(currentScope.status) && hasSetting('TRAFFICKABLE_ON_STATUS__DRAFT')) ||
      (this.isStateInReview(currentScope.status) && hasSetting('TRAFFICKABLE_ON_STATUS__REVIEW')) ||
      (((this.isStateAgencyApproved(currentScope.status) && !currentScope.approved) ||
        this.isStateReviewed(currentScope.status)) &&
        hasSetting('TRAFFICKABLE_ON_STATUS__AGREE')) ||
      currentScope.approved
    )
  }

  /* Closed State  */
  isStateClosed(state: StatusType) {
    return state === StatusType.CLOSED
  }

  /* Trafficked State  */

  isStateTrafficked(state: StatusType) {
    return state === StatusType.TRAFFICKED
  }

  /* Client Approval/Client Accepted  */

  isStateClientApproval(state: StatusType) {
    return state === StatusType.CLIENT_APPROVED
  }

  isStateAgencyApproved(state: StatusType) {
    return state === StatusType.AGENCY_APPROVED
  }

  isCurrentVersion(currentScope: ScopeVersion): boolean {
    return currentScope ? currentScope.identity.currentVersionNumber == currentScope.version : false
  }

  isStateApproved(currentScope: ScopeVersion) {
    return (
      currentScope.approved &&
      (this.isStateAgencyApproved(currentScope.status) || this.isStateClientApproval(currentScope.status))
    )
  }

  /* Accept/Reject Deliverables  */

  areAllDeliverablesReviewedByMe(currentScope: ScopeVersion): boolean {
    return currentScope.deliverables.every((deliverable) => {
      let result: boolean

      if (this.authService.loggedInUser) {
        result =
          this.findDeliverableReviewer(this.authService.loggedInUser.id, deliverable) != null &&
          this.findDeliverableReviewer(this.authService.loggedInUser.id, deliverable)?.vote != null
      }

      return result
    })
  }

  findDeliverableReviewer(userId: number, deliverable: Deliverable): DeliverableReviewer | null {
    const reviewer = deliverable.reviewers?.find((reviewer: any) => reviewer.voter?.id === userId)
    return reviewer || null
  }

  /*  In Review State Checks */
  isStateReviewed(state: StatusType) {
    return state === StatusType.REVIEWED
  }

  isAlreadyAgreed(currentScope: ScopeVersion) {
    if (currentScope == null) return null

    var approvers: DeliverableReviewer[] = currentScope.approvers
    if (this.authService.loggedInUser) {
      var found = approvers.filter((approver: DeliverableReviewer) => {
        if (!approver) {
          return false
        }
        return approver.voter.id == this.authService.loggedInUser.id && approver.vote != null
      })
    }
    return found?.length
  }

  /* Submitted State Checks */
  isStateInReview(state: StatusType) {
    return state === StatusType.SUBMITTED
  }

  isAlreadyReviewed(currentScope: ScopeVersion) {
    if (currentScope == null) return null

    var reviewers = currentScope.reviewers

    var found = reviewers.filter((reviewer: ScopeReviewer) => {
      return reviewer.user.id === this.authService.loggedInUser.id && reviewer.submitted
    })

    return found.length
  }

  /* Draft State Checks */
  isStateDraft(state: StatusType) {
    return state === StatusType.DRAFT
  }

  isStateConfigDraft(state: StatusType) {
    return state === StatusType.CONFIG_DRAFT
  }

  findMember(user: User | undefined): ScopeStateMember | undefined {
    if (!user) {
      return null
    }

    if (this.stateChecks.currentStateMembers) {
      var found = this.stateChecks.currentStateMembers.filter((u) => {
        if (!u.user) {
          return false
        }
        return u.user.id == user.id
      })
      if (found.length > 0) {
        return found[0]
      }
    }
    return null
  }

  getIdentity(currentScope: ScopeVersion): ScopeIdentity {
    return currentScope.identity
  }

  getReviewSummaryRowData(currentScope: ScopeVersion): DeliverableReviewTableData {
    return {
      name: 'deliverableReviewTableData',
      cols: [
        {
          name:
            currentScope.identity.identificationType === 'SCOPE_BY_ROLE'
              ? 'task'
              : this.lang.get('deliverable').toUpperCase(),
          selected: true,
          width: 8,
        },
        {
          name: 'REVIEW STATUS',
          selected: true,
          width: 4,
          naturalOrderBy: (nameA, nameB) => {
            return nameA == 'NOT REVIEWED YET' ? -1 : 1
          },
        },
      ],
      rowClickAction: (e, event) => {},
      rowData: {
        items: currentScope?.deliverables,
        cells: currentScope?.deliverables.map((deliverable) => [
          {
            value: (e: Deliverable) => e.name,
            icon: (e: Deliverable) => (e.fixedPricing ? 'book' : 'description'),
            iconVote: (e: Deliverable) => {
              const reviewer = this.findDeliverableReviewer(this.authService.loggedInUser.id, e)
              if (reviewer && reviewer.vote) {
                return reviewer.vote === 'ACCEPT' ? this.votes.voteAccepted : this.votes.voteRejected
              }
              return this.votes.noVote
            },
          },
          {
            value: (e: Deliverable) => {
              const reviewer = this.findDeliverableReviewer(this.authService.loggedInUser.id, e)
              return reviewer && reviewer.vote ? reviewer.vote : 'Not Reviewed Yet'
            },
            deliverableIconVote: (e: Deliverable) => {
              const reviewer = this.findDeliverableReviewer(this.authService.loggedInUser.id, e)
              return reviewer && reviewer.vote ? VoteReviewStatus.Accepted : VoteReviewStatus.NotReviewedYet
            },
          },
        ])
      }
    }
  }

  /* Collabrators */
  getCurrentStateSubmittedText(currentScope: ScopeVersion): any {
    if (currentScope) {
      if (this.isStateDraft(currentScope.status)) {
        return 'submitted'
      } else if (this.isStateInReview(currentScope.status)) {
        return 'reviewed'
      } else if (this.isStateReviewed(currentScope.status)) {
        return 'company approved'
      } else if (this.isStateAgencyApproved(currentScope.status)) {
        return 'client approved'
      } else if (this.isStateApproved(currentScope)) {
        return 'trafficked'
      }
    }
  }

  showQuickAddMember(currentScope: ScopeVersion, currentUser: User): boolean {
    if (currentScope) {
      if (
        this.isStateAgencyApproved(currentScope.status) ||
        (this.isStateClientApproval(currentScope.status) && this.isStateApproved(currentScope))
      ) {
        return currentScope.traffickable || currentScope.traffickableThroughWorkato
      } else if (!this.isStateClosed(currentScope.status) && !this.isStateTrafficked(currentScope.status)) {
        return !!currentScope.identity.team.hasUser(currentUser.id)
      }
    }
    return false
  }

  updateApprovalStateChecks(currentScope: ScopeVersion): void {
    this.stateChecks.overallState.currentMemberState = this.getCurrentStateSubmittedText(
      currentScope
    )

    this.stateChecks.overallState.showQuickAddMember = this.showQuickAddMember(
      currentScope,
      this.authService.loggedInUser
    )

    if (currentScope) {
      this.confirmDeliverableReview(currentScope)
      this.stateChecks.status = currentScope.status
      this.stateChecks.isCurrentVersion = this.isCurrentVersion(currentScope)

      switch (currentScope.status) {
        case StatusType.DRAFT:
        case StatusType.CONFIG_DRAFT:
          this.setDraftStateMembers(currentScope)
          this.getDraftApprovalStatus(currentScope)
          this.getTraffickedState(currentScope)
          this.stateChecks.deliverableStatus.deliverablesInReview = false
          break
        case StatusType.SUBMITTED:
          this.setInReviewStateMembers(currentScope)
          this.getInReviewApprovalStatus(currentScope)
          this.getTraffickedState(currentScope)
          this.stateChecks.deliverableStatus.deliverablesInReview = true
          break
        case StatusType.REVIEWED:
          this.setReviewedStateMembers(currentScope)
          this.getReviewedApprovalStatus(currentScope)
          this.getTraffickedState(currentScope)
          this.stateChecks.deliverableStatus.deliverablesInReview = false
          break
        case StatusType.AGENCY_APPROVED:
          if (!currentScope.approved) {
            this.setReviewedStateMembers(currentScope)
          } else {
            this.setTraffickedStateMembers(currentScope)
          }
          this.getAgencyApprovedState(currentScope)
          this.getDeliverableTradeStatus(currentScope)
          this.getTraffickedState(currentScope)
          this.stateChecks.deliverableStatus.deliverablesInReview = false
          break
        case StatusType.CLIENT_APPROVED:
          this.setTraffickedStateMembers(currentScope)
          this.getClientApprovedState(currentScope)
          this.getDeliverableTradeStatus(currentScope)
          this.getTraffickedState(currentScope)
          this.stateChecks.deliverableStatus.deliverablesInReview = false
          break
        case StatusType.TRAFFICKED:
          this.setTraffickedStateMembers(currentScope)
          this.stateChecks.isDraft = false
          this.stateChecks.isInReview = false
          this.stateChecks.isStateReviewed = false
          this.stateChecks.isStateAgencyApproved = false
          this.stateChecks.isStateClientApproved = false
          this.getTraffickedState(currentScope)
          this.stateChecks.deliverableStatus.deliverablesInReview = true
          break
        case StatusType.CLOSED:
          break
        default:
          console.error(
            'Unknown Approval status',
            currentScope.status
          )
      }
      this.allowedToDoAction(currentScope)
    }
  }

  allowedToDoAction(currentScope: ScopeVersion) {
    switch (currentScope.status) {
      case StatusType.DRAFT:
        this.stateChecks.teamRestrictedActionAllowed = this.isCollaborator(currentScope)
        break
      case StatusType.SUBMITTED:
        this.stateChecks.teamRestrictedActionAllowed = this.isReviewer(currentScope)
        break
      case StatusType.REVIEWED:
        this.stateChecks.teamRestrictedActionAllowed = this.isApprover(currentScope)
        break
      case StatusType.AGENCY_APPROVED:
      case StatusType.CLIENT_APPROVED:
        this.stateChecks.teamRestrictedActionAllowed = this.isApprover(currentScope)
        break
      case StatusType.TRAFFICKED:
        this.stateChecks.teamRestrictedActionAllowed = this.isCollaborator(currentScope)
        break
      case StatusType.CLOSED:
      case StatusType.CONFIG_DRAFT:
        break
      default:
        console.error(
          'Unknown Approval status',
          currentScope.status
        )
    }
  }

  setDraftStateMembers(scope: ScopeVersion) {
    this.stateChecks.currentStateMembers = scope.collaborators.map((collaborator: ScopeSubmitter) => {
      return {
        id: collaborator.id,
        user: collaborator.user,
        vote: collaborator.submitted ? 'ACCEPT' : null,
      }
    })
  }

  setInReviewStateMembers(scope: ScopeVersion) {
    this.stateChecks.currentStateMembers = scope.reviewers.map((reviewer: ScopeReviewer) => {
      return {
        id: reviewer.id,
        user: reviewer.user,
        vote: reviewer.submitted ? 'ACCEPT' : null,
      }
    })
  }

  setReviewedStateMembers(scope: ScopeVersion) {
    this.stateChecks.currentStateMembers = scope.approvers.map((approver: DeliverableReviewer) => {
      return {
        id: approver.id,
        user: approver.voter,
        vote: approver.vote,
      }
    })
  }

  setTraffickedStateMembers(scope: ScopeVersion) {
    this.stateChecks.currentStateMembers = scope.identity.team.traffickers.map((trafficker) => {
      return {
        id: trafficker.id,
        user: trafficker.user,
        vote: null,
      }
    })
  }

  getDraftApprovalStatus(scope: ScopeVersion): void {
    this.stateChecks.isDraft = true
    this.stateChecks.isInReview = false
    this.stateChecks.isStateReviewed = false
    this.stateChecks.isStateAgencyApproved = false
    this.stateChecks.isStateClientApproved = false
    this.stateChecks.draftState.currentMember = this.findMember(this.authService.loggedInUser)
    this.stateChecks.draftState.hasReviewers = this.hasReviewers(scope)
    this.stateChecks.draftState.hasApprovers = this.hasApprovers(scope)
  }

  getInReviewApprovalStatus(scope: ScopeVersion): void {
    this.stateChecks.isDraft = false
    this.stateChecks.isInReview = true
    this.stateChecks.isStateReviewed = false
    this.stateChecks.isStateAgencyApproved = false
    this.stateChecks.isStateClientApproved = false
    this.stateChecks.inReviewState.isAlreadyReviewed = !!this.isAlreadyReviewed(scope)
    this.stateChecks.inReviewState.currentMember = this.findMember(this.authService.loggedInUser)
  }

  getReviewedApprovalStatus(scope: ScopeVersion): void {
    this.stateChecks.isDraft = false
    this.stateChecks.isInReview = false
    this.stateChecks.isStateReviewed = true
    this.stateChecks.isStateAgencyApproved = false
    this.stateChecks.isStateClientApproved = false
    this.stateChecks.reviewedState.isAlreadyAgreed = !!this.isAlreadyAgreed(scope)
    this.stateChecks.reviewedState.currentMember = this.findMember(this.authService.loggedInUser)
  }

  getAgencyApprovedState(scope: ScopeVersion) {
    this.stateChecks.isDraft = false
    this.stateChecks.isInReview = false
    this.stateChecks.isStateReviewed = false
    this.stateChecks.isStateAgencyApproved = true
    this.stateChecks.isStateClientApproved = false
    this.stateChecks.agencyApprovedState.currentMember = this.findMember(this.authService.loggedInUser)
    this.stateChecks.agencyApprovedState.isAlreadyAgreed = !!this.isAlreadyAgreed(scope)
  }

  getClientApprovedState(scope: ScopeVersion) {
    this.stateChecks.isDraft = false
    this.stateChecks.isInReview = false
    this.stateChecks.isStateReviewed = false
    this.stateChecks.isStateAgencyApproved = false
    this.stateChecks.isStateClientApproved = true
    this.stateChecks.clientApprovedState.currentMember = this.findMember(this.authService.loggedInUser)
    this.stateChecks.clientApprovedState.isAlreadyAgreed = !!this.isAlreadyAgreed(scope)
  }

  getTraffickedState(scope: ScopeVersion): void {
    this.stateChecks.traffickedState.isTraffickable = scope.traffickable
    this.stateChecks.traffickedState.isTraffickableThroughWorkato = scope.traffickableThroughWorkato
    this.stateChecks.traffickedState.currentMember = scope.identity.team.findTraffickerByUserId(this.authService.loggedInUser.id)
    this.stateChecks.traffickedState.identificationType = scope.identity.identificationType !== 'SCOPE_BY_ROLE'
    this.stateChecks.traffickedState.isTraffickedDisabled = this.isTrafficDisabled(scope)
    this.stateChecks.traffickedState.scopeTraffickingComponent = this.traffickingService.deliverableErrors.length > 0
    this.stateChecks.traffickedState.isReadyToTraffic = this.isReadyToTraffic(scope)
  }

  confirmDeliverableReview(scope: ScopeVersion): void {
    this.stateChecks.deliverableReviewState.areAllDeliverablesReviewedByMe =
      this.areAllDeliverablesReviewedByMe(scope)
  }

  getDeliverableTradeStatus(scope: ScopeVersion): void {
    this.stateChecks.deliverableStatus.isApprover = this.isApprover(scope)
    this.stateChecks.deliverableStatus.isStateApproved = this.isStateApproved(scope)
  }

  isScopeEditable(scope: ScopeVersion) {
    if (!scope) {
      return false;
    }
    if (scope.finalised || scope.archived) {
      return false;
    }
    let member = this.stateChecks?.draftState?.currentMember as ScopeStateMember;
    if (!member) {
      return false;
    }
    return (
      scope &&
      this.stateChecks?.isDraft &&
      member.vote !== 'ACCEPT' &&
      (!scope.identity.isTemplate || this.authService.loggedInUser.hasPrivilege('SCOPE_TEMPLATE__EDIT'))
    );
  }

  hasReviewers(scope: ScopeVersion) {
    return scope.identity.team.reviewers.length > 0
  }

  hasApprovers(scope: ScopeVersion) {
    return scope.identity.team.approvers.length > 0
  }

  getIsCommentable = () => {
    return this.stateChecks.isCurrentVersion && this.stateChecks.status !== StatusType.DRAFT && this.stateChecks.status !== StatusType.CLOSED;
  }
}
