<form>
  <mat-form-field class="example-chip-list">
    <mat-label>{{inputLabel}}</mat-label>
    <mat-chip-grid #chipGrid aria-label="Select values" [formControl]="control">
      <mat-chip-row *ngFor="let value of values"
                    (removed)="remove(value)"
                    [editable]="true"
                    (edited)="edit(value, $event)"
                    [aria-description]="'press enter to edit ' + value">
        {{value}}
        <button matChipRemove [attr.aria-label]="'remove ' + value">
          <mat-icon class="material-symbols-rounded">close</mat-icon>
        </button>
      </mat-chip-row>
      <input type="{{ type }}"
             matInput
             [matChipInputFor]="chipGrid"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputAddOnBlur]="addOnBlur"
             (matChipInputTokenEnd)="add($event)"/>
    </mat-chip-grid>
    <mat-error *ngIf="control.hasError('required')">This is required</mat-error>
    <mat-error *ngIf="control.hasError('custom')">{{control.getError('custom')}}</mat-error>
  </mat-form-field>
</form>
