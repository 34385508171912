import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SharedModule } from '@app/shared/shared.module';
import {
  ScopeUiDatepickerComponent,
} from '@app/shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component';
import {
  ScopeUiAutocompleteComponent,
} from '@app/shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxMaskDirective } from 'ngx-mask';
import { ScopeOverviewService } from '@app/features/scope-overview/service/scope-overview.service';
import {
  ScopeUiDropdownComponent,
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component';
import { Store } from '@ngrx/store';
import { SnackbarService } from '@shared/utils/snackbar.service';
import { LanguageService } from '@core/service/language.service';
import { QuestionType } from '@core/model/enums/question-type.enum';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HyperFormula } from 'hyperformula';
import { FormulaBuilderComponent } from '@shared/components/formula-builder/formula-builder.component';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import { ScopeUiChipsComponent } from '@shared/components/ui-components/scope-ui-chips/scope-ui-chips.component';
import {
  ScopeUiCheckboxComponent
} from '@shared/components/ui-components/scope-ui-checkbox/scope-ui-checkbox.component';
import { ScenarioQuestion } from '@app/features/scope-overview/model/scenario-question.model';
import { generateFieldId } from '@shared/utils/utils';

export interface AddEditQuestionModalConfig {
  type: QuestionType,
  question?: ScenarioQuestion,
  scenario: Scenario,
  confirmCallback: (question: any, fieldIDControl: FormControl) => void
}

@Component({
  selector: 'add-edit-question-modal',
  standalone: true,
  imports: [CommonModule, ScopeUiDatepickerComponent, ScopeUiAutocompleteComponent, SharedModule, MatDatepickerModule, CKEditorModule, ScopeUiInputComponent, NgxSliderModule, NgxMaskDirective, ScopeUiDropdownComponent, FormulaBuilderComponent, ScopeUiChipsComponent, ScopeUiCheckboxComponent],
  templateUrl: './add-edit-question-modal.component.html',
  styleUrls: ['./add-edit-question-modal.component.scss'],
})
export class AddEditQuestionModalComponent implements AfterViewInit {
  title: string
  instructions: string
  questionTextLabel: string
  configureTitle: string = 'Configure answer field'

  questionText: string
  required: boolean = false
  fieldLabel: string
  fieldID: string
  formulaValue: string
  options: string[] = []
  showAlways: boolean = true
  hiddenAlways: boolean = false
  showOnCondition: boolean = false
  condition: string
  formulaControl = new FormControl()
  conditionControl = new FormControl()

  hf: HyperFormula
  fieldIDControl = new FormControl('')
  optionsListControl = new FormControl([], [Validators.required, this.optionsListValidator()])

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddEditQuestionModalConfig,
    private scopeOverviewService: ScopeOverviewService,
    private store: Store,
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
    private lang: LanguageService,
    public cdr: ChangeDetectorRef
  ) {
    // Create a HyperFormula instance for field ID validation
    this.hf = HyperFormula.buildEmpty({ licenseKey: 'gpl-v3' })

    if (data.question) {
      this.questionText = data.question.displayText
      this.required = data.question.mandatory
      this.fieldLabel = data.question.fieldLabel
      this.fieldID = data.question.fieldId
      this.formulaValue = data.question.formula
      this.options = data.question.optionsList?.split(',')
      this.showAlways = !data.question.hidden && !data.question.displayCondition
      this.hiddenAlways = data.question.hidden
      this.showOnCondition = !!data.question.displayCondition
      this.condition = data.question.displayCondition
    }

    switch(data.type) {
      case QuestionType.FORMULA:
        this.title = 'Configure Calculated Answer'
        this.instructions = `1. Enter the question text and specify if answering is required.
          2. Label the answer field and assign a unique ID for tracking.
          3. Build the formula.`
        this.questionTextLabel = 'Enter description to display  (i.e: Number of doses)'
        this.configureTitle = 'Configure calculated answer field'
        break
      case QuestionType.DELIVERABLE:
        this.title = `Configure ${this.lang.get('deliverable')} prompt`
        this.instructions = `Set up the question by adding display text, marking it as required if needed, and defining
        its visibility. Choose ${this.lang.get('deliverable.p|l')} for the question, and pin them if they should always
        included in the ${this.lang.get('scope|l')}.`
        this.questionTextLabel = `Enter a prompt for selecting ${this.lang.get('deliverable.p|l')}`
        this.questionText = `Your admin suggests adding one of few of these ${this.lang.get('deliverable.p|l')}. Please select and add where required`
        this.configureTitle = `Configure ${this.lang.get('deliverable')} selection`
        break
      case QuestionType.DROPDOWN:
        this.title = `Configure Dropdown Answer`
        this.instructions = `1. Enter the question text and specify if answering is required.
          2. Label the answer field and assign a unique ID for tracking.`
        this.questionTextLabel = 'Enter question to display'
        break
      default:
        this.title = `Configure Question with ${data.type?.charAt(0) + data.type?.substring(1).toLowerCase()} Answer`
        this.instructions = `1. Enter the question text and specify if answering is required.
          2. Label the answer field and assign a unique ID for tracking.`
        this.questionTextLabel = 'Enter question to display'
    }
  }

  ngAfterViewInit() {
    if (this.data.question) {
      this.fieldIDControl.setValue(this.data.question.fieldId)
      this.fieldIDControl.updateValueAndValidity()
      this.fieldIDControl.disable()
      this.optionsListControl.setValue(this.options)
      this.optionsListControl.updateValueAndValidity()
    }
  }

  optionsListValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      return (control.value?.find((o) => o.includes(','))) ? { custom:'Option cannot contain a comma' }: null;
    }
  }

  disableSubmit(): boolean {
    return !this.questionText || !this.fieldLabel || !this.fieldID || (!this.fieldIDControl.valid && !this.fieldIDControl.disabled) ||
      (this.data.type == QuestionType.FORMULA && (!this.formulaValue || this.formulaControl.hasError('invalid'))) ||
      (this.data.type == QuestionType.DROPDOWN && (!this.options.length || !this.optionsListControl.valid)) ||
      (this.showOnCondition && (!this.condition || this.conditionControl.hasError('invalid')))
  }

  submit() {
    let condition = this.showOnCondition ? this.condition : null
    this.data.confirmCallback({ id: this.data.question?.id, displayText: this.questionText, mandatory: this.required, hidden: this.hiddenAlways,
      fieldLabel: this.fieldLabel, fieldId: this.fieldID, type: this.data.type, formula: this.formulaValue, optionsList:
      this.options.join(','), displayCondition: condition }, this.fieldIDControl)
  }

  setFieldLabel(fieldLabel: string) {
    this.fieldLabel = fieldLabel
    if (!this.data.question && !this.fieldIDControl.dirty) {
      let fieldId = generateFieldId(fieldLabel)
      this.fieldIDControl.setValue(fieldId)
      this.setFieldID(fieldId)
    }
  }

  setFieldID(fieldID: string) {
    this.fieldID = fieldID
    if (this.data.scenario.categories.flatMap((c) => c.questions).find((q) => q.fieldId === this.fieldID)) {
      this.fieldIDControl.setErrors({conflict: true})
    } else if (!this.hf.isItPossibleToAddNamedExpression(this.fieldID, null)) {
      this.fieldIDControl.setErrors({custom: 'Not a valid Field ID'})
    } else {
      this.fieldIDControl.setErrors(null)
    }
    this.fieldIDControl.markAsTouched()
  }

  protected readonly QuestionType = QuestionType;
}
