import { ChangeDetectorRef, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[isEllipsisActive]'
})
export class IsEllipsisActiveDirective {
  constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef) {}

  @HostListener('mouseenter')
  onMouseEnter(): void {
    const element = this.elementRef.nativeElement;
    if (element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight) {
      element.title = element.innerText;
      this.cdr.detectChanges();
    }
  }
}
