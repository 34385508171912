import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import { SharedModule } from '@shared/shared.module';
import { ScenarioCategory } from '@app/features/scope-overview/model/scenario-category.model';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import { QuestionType } from '@core/model/enums/question-type.enum';
import { ScopeConfiguration, ScopeVersion, StatusType } from '@core/model/scope-version';
import { cloneDeep } from 'lodash';
import { FormulaBuilderComponent } from '@shared/components/formula-builder/formula-builder.component';
import { ScenarioQuestion } from '@app/features/scope-overview/model/scenario-question.model';
import {
  ScopeUiDatepickerComponent,
} from '@shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component';
import {
  ScopeUiDropdownComponent,
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component';
import { Observable, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ScopeOverviewSelectors } from '@app/features/scope-overview/store/selectors/scope-overview.selector';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { ScopeOverviewActions } from '@app/features/scope-overview/store/actions/scope-overview.action';
import { trackById, untilDestroyed } from '@shared/utils/utils';
import { FormulaService } from '@shared/services/formula.service';
import { DetailedCellError } from 'hyperformula';
import { ApprovalFlowService } from '@app/features/scope-overview/service/approval-flow.service';

@Component({
  selector: 'scope-configuration',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ScopeUiInputComponent,
    FormulaBuilderComponent,
    ScopeUiDatepickerComponent,
    ScopeUiDropdownComponent,
    NgOptimizedImage,
  ],
  templateUrl: './scope-configuration.component.html',
  styleUrls: ['./scope-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScopeConfigurationComponent implements OnInit, OnDestroy {
  @Input() scenarioId: number
  @Input() set currentScope(scopeVersion: ScopeVersion) {
    this.editable = this.approvalFlowService.isScopeEditable(scopeVersion)
    this.status = scopeVersion.status
    this.configuration = cloneDeep(scopeVersion.configuration || {})
  }
  @Output() onSave = new EventEmitter<ScopeConfiguration>()
  @Output() onSubmit = new EventEmitter<void>()

  private readonly destroy$
  scenario: Scenario
  scenarioInitialised: boolean = false
  selectedCategoryId?: number
  editable: boolean
  status: StatusType
  configuration: ScopeConfiguration
  updateLoading$: Observable<boolean>
  formSubmitted: boolean = false
  showFormulaMap: { [questionId: number]: boolean } = {}
  categoryStates: { [categoryId: number]: { enabled: boolean, completed: boolean, updated?: boolean, errorCount?: number } } = {}
  questionStates: { [questionId: number]: { control?: FormControl, excluded?: boolean, calcError?: boolean } } = {}
  totalErrorCount: number

  constructor(private store: Store,
              private cdr: ChangeDetectorRef,
              private formulaService: FormulaService,
              private approvalFlowService: ApprovalFlowService) {
    this.destroy$ = untilDestroyed()
    this.formulaService.setExcludedFields([])
    this.updateLoading$ = this.store.select(ScopeOverviewSelectors.selectUpdateScopeDetailsLoading)
  }

  ngOnInit() {
    this.store.dispatch(ScopeOverviewActions.getScenario({ scenarioId: this.scenarioId }))

    this.store.select(ScopeOverviewSelectors.selectScenario).pipe(this.destroy$()).subscribe((scenario: Scenario) => {
      this.scenario = scenario
      if (this.scenario && !this.scenarioInitialised)
        this.initialiseScenario()
    })
  }

  initialiseScenario() {
    this.formulaService.initialise(this.scenario)
    let enableNextCategory = true

    if (this.scenario.categories.length) {
      let initialCategory
      this.scenario.categories.forEach((category) => {
        this.setExcludedQuestions(category)
        let completed = !!this.configuration[category.id] &&
          !category.questions.find((q) => q.mandatory && !this.questionStates[q.id]?.excluded && !q.value)
        this.categoryStates[category.id] = { enabled: enableNextCategory, completed: completed }
        if (!this.categoryStates[category.id].completed && !initialCategory) {
          initialCategory = category
          enableNextCategory = false
        }
      })
      initialCategory = initialCategory || (this.status === StatusType.CONFIG_DRAFT ?
        this.scenario.categories[this.scenario.categories.length - 1] : this.scenario.categories[0])
      this.selectCategory(initialCategory)
    }
    this.scenarioInitialised = true
    this.cdr.detectChanges()
  }

  dateRequiredValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      return (!control.value?.startDate) ? { required: true }: null;
    }
  }

  selectCategory(category: ScenarioCategory) {
    if (this.editable && !this.categoryStates[category.id].enabled) return
    this.formSubmitted = false

    category.questions.forEach((q) => {
      this.questionStates[q.id] = this.questionStates[q.id] || {}
      if (q.type === QuestionType.DATE) {
        this.questionStates[q.id].control = new FormControl(q.value, q.mandatory ? this.dateRequiredValidator() : null)
      } else if (q.type !== QuestionType.FORMULA) {
        this.questionStates[q.id].control = new FormControl(q.value, q.mandatory ? Validators.required : null)
      } else {
        this.calculateFormulaAnswer(q, category.id)
      }
    })

    if (!this.categoryStates[category.id].completed) this.setCategoryCompleted(category)
    if (this.categoryStates[category.id].updated === undefined)
      this.categoryStates[category.id].updated = !this.categoryStates[category.id].completed || this.categoryStates[category.id].errorCount > 0
    if (this.categoryStates[category.id].errorCount) this.formSubmitted = true
    this.selectedCategoryId = category.id
  }

  calculateFormulaAnswer(question: ScenarioQuestion, categoryId: number) {
    let result = this.formulaService.calculate(question.formula)
    this.questionStates[question.id] = this.questionStates[question.id] || {}
    if (result instanceof DetailedCellError) {
      this.questionStates[question.id].calcError = true
    } else {
      this.questionStates[question.id].calcError = false
      this.setAnswer(question, this.formulaService.calculate(question.formula), categoryId)
    }
  }

  setCategoryCompleted(category: ScenarioCategory) {
    this.categoryStates[category.id].completed =
      !category.questions.find((q) => q.mandatory && !this.questionStates[q.id]?.excluded && !q.value)
  }

  setExcludedQuestions(category: ScenarioCategory) {
    category.questions.filter((q) => q.displayCondition).forEach((q) => {
      this.questionStates[q.id] = this.questionStates[q.id] || {}
      this.questionStates[q.id].excluded = this.formulaService.calculate(q.displayCondition) === false
    })
  }

  setAnswer(question: ScenarioQuestion, $event: any, categoryId?: number) {
    if (question.value == $event) return
    if (categoryId === undefined) categoryId = this.selectedCategoryId
    question.value = $event
    this.configuration[categoryId] = this.configuration[categoryId] || {}
    this.configuration[categoryId][question.id] = $event
    this.formulaService.updateField(question)

    this.scenario.categories.forEach((c) => {
      // Run dependant formulas
      c.questions
        .filter((q) => q.formula &&
          this.formulaService.getReferencedFields(q.formula).includes(question.fieldId.toLowerCase()))
        .forEach((q) => this.calculateFormulaAnswer(q, c.id))

      // Update questions with dependant display conditions
      c.questions
        .filter((q) => q.displayCondition &&
          this.formulaService.getReferencedFields(q.displayCondition).includes(question.fieldId.toLowerCase()))
        .forEach((q) => {
          let wasExcluded = this.questionStates[q.id]?.excluded
          this.questionStates[q.id] = this.questionStates[q.id] || {}
          this.questionStates[q.id].excluded = this.formulaService.calculate(q.displayCondition) === false
          if (this.questionStates[q.id].excluded && !wasExcluded && q.value) {
            // Reset the value to null
            this.configuration[c.id][q.id] = null
            q.value = null
            this.questionStates[q.id].control?.setValue(null)
            this.formulaService.updateField(q)
          }
        })

      if (this.categoryStates[c.id].completed) {
        this.categoryStates[c.id].errorCount =
          c.questions.filter((q) => q.mandatory && !this.questionStates[q.id]?.excluded && !q.value).length
        if (this.categoryStates[c.id].errorCount) this.formSubmitted = true
      } else if (c.id === this.selectedCategoryId) {
        this.setCategoryCompleted(c)
      }
    })

    if (question.type !== QuestionType.FORMULA) {
      this.categoryStates[categoryId].updated = true
      this.totalErrorCount = Object.values(this.categoryStates).filter((c) => c.errorCount).length
      this.store.dispatch(ScopeOverviewActions.updateScenario({ scenario: cloneDeep(this.scenario), hasUpdates: true }))
      this.cdr.detectChanges()
    }
  }

  save(scenario: Scenario, category: ScenarioCategory, selectNext: boolean = false) {
    this.formSubmitted = true
    if (!category.questions.some((q) => {
      let control = !this.questionStates[q.id]?.excluded && this.questionStates[q.id]?.control
      if (control && !control.valid) {
        document.getElementById(q.fieldId).scrollIntoView()
        return true
      }
      return false
    })) {
      let nextCategory = scenario.categories[scenario.categories.indexOf(category) + 1]
      this.onSave.emit(this.configuration)
      this.updateLoading$.pipe(
        filter((loading) => !loading),
        take(1)
      ).subscribe(() => {
        if (nextCategory) this.categoryStates[nextCategory.id].enabled = true
        this.categoryStates[category.id].updated = false
        if (selectNext) this.selectCategory(nextCategory)
      })
    }
  }

  submit() {
    this.onSubmit.emit()
  }

  ngOnDestroy() {
    this.store.dispatch(ScopeOverviewActions.resetScenario())
  }

  protected readonly QuestionType = QuestionType;
  protected readonly trackById = trackById;
  protected readonly StatusType = StatusType;
}
