import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { FormControl } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select'
import _ from "lodash";

@Component({
  selector: 'scope-ui-dropdown',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './scope-ui-dropdown.component.html',
  styleUrls: ['./scope-ui-dropdown.component.scss'],
})
export class ScopeUiDropdownComponent implements OnChanges {
  private _dropdownOptions!: any[];

  @Input() selectControl = new FormControl('');

  @Input() controlLabel? = '';

  @Input() inputPlaceholder: string;

  @Input() customSelectIcon!: string;

  @Input() isStandardDropdown!: boolean;

  @Input() displayParam: string = "name";

  @Input() displayFn: (option: any) => string;

  @Input() valueFn: (option: any) => any;

  @Input() selectedValue: any;

  @Input() element: any;

  @Input() set disabled(value: boolean) {
    this.isDisabled = value
    if (value) {
      this.selectControl.disable()
    } else {
      this.selectControl.enable()
    }
  }

  @Input() dropdownClass?: string;

  @Input() required?: boolean;

  @Input() defaultOption?: any;

  @Input() set dropdownOptions(value: any[]) {
    if (value) this._dropdownOptions = this.defaultOption ? [this.defaultOption, ...value] : value;
  }
  get dropdownOptions(): any[] {
    return this._dropdownOptions;
  }

  @Input() minimum?: number

  @Input() set formSubmitted (value: boolean) {
    if (value) {
      this.selectControl.updateValueAndValidity()
      this.selectControl.markAsTouched()
    }
  }

  @Output() onSelectionChange = new EventEmitter<any>();

  @ViewChild(MatSelect) matSelect: MatSelect

  isDisabled: boolean = false

  ngOnInit() {
    this.selectControl.setValue(this.selectedValue)
    if (this.isDisabled) {
      this.selectControl.disable()
    } else {
      this.selectControl.enable()
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dropdownOptions && changes.isStandardDropdown)
      this.dropdownOptions = changes.dropdownOptions.currentValue;
  }

  onSelection(event: MatSelectChange) {
    if (this.element) {
      this.onSelectionChange.emit({value: event.value, element: this.element});
    } else {
      this.onSelectionChange.emit(event.value);
    }
  }

  compare(a: any, b: any): boolean {
    return (
      (a.value != undefined && a.value === b?.value) ||
      (a.id != undefined && a.id === b?.id) ||
      _.isEqual(a, b) ||
      (this.displayFn && _.isEqual(this.displayFn(a), this.displayFn(b)))
    )
  }

  viewOption(option: any): string {
    return this.displayFn?.(option) || option[this.displayParam || 'name'] || option
  }

  optionValue(option: any): string {
    return this.valueFn?.(option) || option
  }
}
