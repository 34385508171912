import {Component, ContentChildren, EventEmitter, Input, Output, QueryList} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScopeUiTabComponent } from '../scope-ui-tab/scope-ui-tab.component';
import { SharedModule } from '@app/shared/shared.module';
import { RouterLink } from '@angular/router';
import { CompanyManagementActions } from '@app/features/company-management/store/actions/company-management.actions';
import { TabUtil } from '@shared/utils/tabs-util.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'scope-ui-tabs',
  standalone: true,
  imports: [CommonModule, SharedModule, ScopeUiTabComponent, RouterLink],
  templateUrl: './scope-ui-tabs.component.html',
  styleUrls: ['./scope-ui-tabs.component.scss'],
})
export class ScopeUiTabsComponent {
  @Input() fontSize!: string;
  @Input() confirm?: (string) => boolean;
  @Output() onSelect: EventEmitter<string>;
  @Output() onInit: EventEmitter<string>;

  @ContentChildren(ScopeUiTabComponent) tabs!: QueryList<ScopeUiTabComponent>;

  constructor(private tabUtils: TabUtil, private store: Store) {
    this.onSelect = new EventEmitter<string>();
    this.onInit = new EventEmitter<string>();
  }

  ngAfterContentInit() {
    const activeTabs = this.tabs.filter((tab) => tab.active);

    if (activeTabs.length === 0) {
      let tab = this.tabs.find((t) => t.default && !t.disabled) ||
        this.tabs.filter((t) => !t.disabled)[0];
      tab.active = true;
      this.onInit ? this.onInit.emit(tab.tabTitle) : this.onSelect.emit(this.tabs.first.tabTitle)
    } else {
      this.onInit ? this.onInit.emit(activeTabs[0].tabTitle) : this.onSelect.emit(activeTabs[0].tabTitle)
    }
  }

  selectTab(tab: ScopeUiTabComponent) {
    if (tab?.active || tab?.disabled) return;
    if (this.tabUtils.shouldDisableTabSwitching) {
      this.store.dispatch(CompanyManagementActions.tabChangeStart({additionalCtx: {}, tabs: tab}))
      return;
    }
    if (!this.confirm || this.confirm(tab.routeLink || tab.tabTitle)) {
      this.tabs.toArray().forEach((tab) => (tab.active = false));
      if (tab) {
        tab.active = true;
        this.onSelect.emit(tab.routeLink || tab.tabTitle);
      }
    }
  }
}
