import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { AccountSetupComponent } from '@app/features/company-management/components/account-setup/account-setup.component'
import { DocumentTemplateComponent } from '@app/features/company-management/components/document-template/document-template.component'
import { GroupComponent } from '@app/features/company-management/components/group/group.component'
import { GroupsComponent } from '@app/features/company-management/components/groups/groups.component'
import { OutputTemplatesComponent } from '@app/features/company-management/components/output-templates/output-templates.component'
import { RateCardsComponent } from '@app/features/company-management/components/rate-cards/rate-cards.component'
import { RatecardComponent } from '@app/features/company-management/components/ratecard/ratecard.component'
import { ScopeTypesComponent } from '@app/features/company-management/components/scope-types/scope-types.component'
import { SecondPartiesComponent } from '@app/features/company-management/components/second-parties/second-parties.component'
import { SecondPartyComponent } from '@app/features/company-management/components/second-party/second-party.component'
import { SpreadsheetTemplateComponent } from '@app/features/company-management/components/spreadsheet-template/spreadsheet-template.component'
import { ScopeUiAutocompleteComponent } from '@app/shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component'
import { ScopeUiCardMasterComponent } from '@app/shared/components/ui-components/scope-ui-card-master/scope-ui-card-master.component'
import { MaterialModule } from '@plugins/material/material.module'
import { OutputEditorComponent } from '@shared/components/output-editor/output-editor.component'
import { ScopeUiOptionsMenuComponent } from '@shared/components/scope-ui-options-menu/scope-ui-options-menu.component'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import { ScopeUiPaginatorComponent } from '@shared/components/ui-components/scope-ui-paginator/scope-ui-paginator.component'
import { ScopeUiTabComponent } from '@shared/components/ui-components/scope-ui-tab/scope-ui-tab.component'
import { ScopeUiTabsComponent } from '@shared/components/ui-components/scope-ui-tabs/scope-ui-tabs.component'
import { SharedModule } from '@shared/shared.module'
import { CompanyManagementNgrxModule } from './company-management-ngrx.module'
import { CompanyManagementRoutingModule } from './company-management-routing.module'
import { InvitationSetupComponent } from './components/invitation-setup/invitation-setup.component'
import { ManageComponent } from './components/manage/manage.component'
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ScopeDynamicFieldSetting } from '@app/features/scoping/components/scope-dynamic-field-setting/scope-dynamic-field-setting.component';
import { ScopeUiTableComponent } from '@shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import { DataComponent } from '@app/features/company-management/components/data/data.component';
import { TasksComponent } from '@app/features/company-management/components/data/tasks/tasks.component';
import { ScenariosComponent } from '@app/features/company-management/components/scenarios/scenarios.component';
import { ScenarioComponent } from '@app/features/company-management/components/scenario/scenario.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    CompanyManagementRoutingModule,
    CompanyManagementNgrxModule,
    ScopeUiCardMasterComponent,
    ScopeUiAutocompleteComponent,
    ScopeUiTabComponent,
    ScopeUiTabsComponent,
    OutputEditorComponent,
    ScopeUiInputComponent,
    ScopeUiOptionsMenuComponent,
    ScopeUiPaginatorComponent,
    NgxJsonViewerModule,
    ScopeUiTableComponent,
  ],
  declarations: [
    ManageComponent,
    AccountSetupComponent,
    InvitationSetupComponent,
    DataComponent,
    RatecardComponent,
    SecondPartiesComponent,
    SecondPartyComponent,
    RateCardsComponent,
    OutputTemplatesComponent,
    GroupsComponent,
    GroupComponent,
    DocumentTemplateComponent,
    SpreadsheetTemplateComponent,
    ScopeTypesComponent,
    ScopeDynamicFieldSetting,
    TasksComponent,
    ScenariosComponent,
    ScenarioComponent
  ],
  providers: [],
  exports: [RouterModule],
})
export class CompanyManagementModule {}
