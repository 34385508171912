import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScopeVersion } from '@core/model/scope-version';
import { User } from '@core/model/user.model';
import { map, Observable } from 'rxjs';
import { ScopeSection } from '@app/features/scope-overview/model/scope-section';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { ScopeComponent } from '@app/features/scoping/models/component.model';
import { ThirdPartyCost } from '@app/core/model/third-party-cost.model';
import { FeeItemInstance } from '@app/features/scope-overview/model/fee-item.model';
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model';
import { MappedEntity } from '@app/features/scoping/models/mapped-entity.model';
import { MenuOptions } from '@core/model/definitions/menu-options.interface';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Preference } from '@core/model/user-preferences.interface';
import { select, Store } from '@ngrx/store';
import { LanguageService } from '@core/service/language.service';
import { MatDialog } from '@angular/material/dialog';
import { Privilege } from '@core/model/enums/privilege.enum'
import { DataMappingService } from '@app/features/scoping/service/data-mapping.service';
import { ApprovalFlowService } from '@app/features/scope-overview/service/approval-flow.service';
import { ScopeTabService } from '@app/features/scope-overview/service/scope-tab.service';
import { Router } from '@angular/router';
import { trackById, untilDestroyed } from '@shared/utils/utils';
import { ScopeOverviewSelectors } from '@app/features/scope-overview/store/selectors/scope-overview.selector';
import { plainToInstance } from 'class-transformer';
import { ScopeOverviewActions } from '@app/features/scope-overview/store/actions/scope-overview.action';
import { UserRoleLevel } from '@core/model/enums/user-role-level.enum';
import { DirectivesModule } from '@shared/directives/index.module';
import { FindReplaceComponent } from '@shared/components/find-replace/find-replace.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '@shared/pipe/index.module';
import {
  ScopeTableComponent
} from '@app/features/scope-overview/components/scope-tab/scope-table/scope-table.component';
import {
  ScopeTimelineComponent
} from '@app/features/scope-overview/components/scope-tab/scope-timeline/scope-timeline.component';
import {
  ScopeUiFinancialCardComponent
} from '@shared/components/ui-components/scope-ui-financial-card/scope-ui-financial-card.component';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import { ScopeUiOptionsMenuComponent } from '@shared/components/scope-ui-options-menu/scope-ui-options-menu.component';
import { ScopeUiTabComponent } from '@shared/components/ui-components/scope-ui-tab/scope-ui-tab.component';
import { ScopeUiTableComponent } from '@shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import { ScopeUiTabsComponent } from '@shared/components/ui-components/scope-ui-tabs/scope-ui-tabs.component';
import { SharedModule } from '@shared/shared.module';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { SortByOrderPipe } from '@shared/pipe/sort-by-order.pipe';
import {
  ScopeByRoleTableComponent
} from '@app/features/scope-overview/components/scope-tab/scope-by-role-table/scope-by-role-table.component';
import {
  ScopeByRoleTaskComponent
} from '@app/features/scope-overview/components/scope-tab/scope-by-role-task/scope-by-role-task.component';
import { Sort } from '@angular/material/sort';
import { Money } from '@app/features/scope-overview/model/money.model';
import { ThirdPartyCostFormula } from '@app/core/model/third-party-cost-formula.model';
import { CdnConfig } from '@core/model/cdn-config.model';
import { TableColumnKey } from '@app/shared/components/ui-components/scope-ui-table/table-column-key.enum';

@Component({
  selector: 'scope-by-role-tab',
  standalone: true,
  imports: [CommonModule, DirectivesModule, FindReplaceComponent, MatButtonModule, MatCheckboxModule, MatDividerModule, MatIconModule, MatMenuModule, MatSidenavModule, MatTooltipModule, PipesModule, ScopeTableComponent, ScopeTimelineComponent, ScopeUiFinancialCardComponent, ScopeUiInputComponent, ScopeUiOptionsMenuComponent, ScopeUiTabComponent, ScopeUiTableComponent, ScopeUiTabsComponent, SharedModule, CdkDropList, CdkDrag, SortByOrderPipe, SortByOrderPipe, ScopeByRoleTableComponent, ScopeByRoleTaskComponent],
  templateUrl: './scope-by-role-tab.component.html',
  styleUrls: ['./scope-by-role-tab.component.scss']
})
export class ScopeByRoleTabComponent implements OnInit {
  private readonly destroy$

  @Input() scopeVersion!: ScopeVersion;

  @Input() currentUser!: User;

  @Input() currentRatecard: RatecardVersion

  @Input() cdnConfig?: CdnConfig

  @Output() onAddRole!: EventEmitter<any>

  @Output() onAddThirdPartyCost = new EventEmitter<any>();

  @Output() onUpdateThirdPartyCost = new EventEmitter<ThirdPartyCost>();

  @Output() onDeleteThirdPartyCost = new EventEmitter<ThirdPartyCost>();

  scopeActions!: MenuOptions[];
  thirdPartyCostColumns!: Preference[];

  scopeOptions!: any[];
  feeMenuOptions!: MenuOptions[];
  showFinancialsSettings: boolean = false
  setReplace: boolean
  _showFilterView: boolean
  showFinancials: boolean = true

  @Output() onArchiveScope: EventEmitter<any>;

  @Output() onUnarchiveScope: EventEmitter<any>;

  @Output() onAddTask: EventEmitter<void>;

  @Output() onDeleteScope: EventEmitter<any>;

  @Output() onExport: EventEmitter<any>;

  @Output() onDeleteDeliverable!: EventEmitter<any>

  @Output() onAcceptDeliverable!: EventEmitter<any>

  @Output() onRejectDeliverable!: EventEmitter<any>

  @Output() onDeleteFee!: EventEmitter<any>;

  @Output() onAddFee = new EventEmitter<any>();

  @Output() onEditFee = new EventEmitter<any>();

  @Output() onUpdateFee = new EventEmitter<any>();

  @Output() onAddDiscount = new EventEmitter<any>();

  @Output() onDeleteDiscount = new EventEmitter<any>();

  @Output() onEditMsaLineItem = new EventEmitter<any>();

  @Output() onDeleteMsaLineItem = new EventEmitter<any>();

  showFinancialsFees: boolean = false

  allFeesLoaded: boolean = false

  showTimelineTpcs: boolean = false

  allTpcsLoaded: boolean = false

  allFees$: Observable<MappedEntity<FeeItemInstance>[]>

  allThirdPartyCosts$: Observable<MappedEntity<ThirdPartyCost>[]>

  financialCards: FinancialCard[] = []

  enabledCards: FinancialCard[] = []

  tableExpanded: boolean = true

  departmentSort: Sort

  scopeTpcDeleteAction: any

  constructor(
    private store: Store,
    private lang: LanguageService,
    public dialog: MatDialog,
    public mappingService: DataMappingService,
    public scopeApprovalFlowService: ApprovalFlowService,
    public scopeTabService: ScopeTabService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.onAddRole = new EventEmitter<any>()
    this.onAddThirdPartyCost = new EventEmitter<any>()
    this.onUpdateThirdPartyCost = new EventEmitter<ThirdPartyCost>()
    this.onDeleteThirdPartyCost = new EventEmitter<ThirdPartyCost>()
    this.onArchiveScope = new EventEmitter<any>();
    this.onUnarchiveScope = new EventEmitter<any>();
    this.onAddTask = new EventEmitter<void>();
    this.onDeleteScope = new EventEmitter<any>();
    this.onExport = new EventEmitter<any>();
    this.onDeleteDeliverable = new EventEmitter<any>();
    this.onAcceptDeliverable = new EventEmitter<any>();
    this.onRejectDeliverable = new EventEmitter<any>();
    this.onDeleteFee = new EventEmitter<any>();
    this.setReplace = false;
    this.destroy$ = untilDestroyed();
  }

  ngOnInit() {
    this.scopeTabService.setUserColumns(this.scopeVersion);
    this.scopeTabService.setFeeColumns()
    this.setMenu();
    this.setFinancialsCards();

    this.allFees$ = this.store.pipe(select(ScopeOverviewSelectors.selectAllFees),
      map((feeItems) =>
        this.mappingService.transformArray<FeeItemInstance>(feeItems, this.scopeTabService.scopeFeeColumns)
      )
    );

    this.store.select(ScopeOverviewSelectors.selectAllFeesLoaded)
      .pipe(this.destroy$())
      .subscribe((loaded: boolean) => {
        this.allFeesLoaded = loaded;
        if (!this.allFeesLoaded && this.showFinancialsFees) {
          this.store.dispatch(ScopeOverviewActions.getDeliverableAndComponentFees({ scopeId: this.scopeVersion.identity.id, versionId: this.scopeVersion.version }))
        }
        this.cdr.detectChanges()
      });

    this.allThirdPartyCosts$ = this.store.pipe(select(ScopeOverviewSelectors.selectAllThirdPartyCosts),
      map((thirdPartyCosts) =>
        this.mappingService.transformArray<ThirdPartyCost>(thirdPartyCosts, this.thirdPartyCostColumns)
      )
    );

    this.store.select(ScopeOverviewSelectors.selectAllThirdPartyCostsLoaded)
      .pipe(this.destroy$())
      .subscribe((loaded: boolean) => {
        this.allTpcsLoaded = loaded;
        if (!this.allTpcsLoaded && this.showTimelineTpcs) {
          this.store.dispatch(ScopeOverviewActions.getAllDeliverableTPCs({ scopeId: this.scopeVersion.identity.id, versionId: this.scopeVersion.version }))
        }
        this.cdr.detectChanges()
      });

    this.scopeTpcDeleteAction = {
      showOptionFunc: () => {
        return this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion)
      },
      clickAction: (tpc: ThirdPartyCost) => {
        this.onDeleteThirdPartyCost.emit(tpc)
      },
      buttonType: 'icon'
    }
  }

  setFinancialsCards() {
    this.financialCards = [
      {
        ngIf: () => this.scopeVersion.discount != null,
        addCardNgIf: () => this.scopeVersion.discount == null &&
          this.scopeVersion.getTotalSellingPrice().getValue() > 0 &&
          this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion),
        hasPrivilege: this.currentUser.hasPrivilege('SCOPE__VIEW_RATES'),
        hasPrivilegeEntity: this.scopeVersion,
        hasSetting: true,
        heading: 'Discount',
        addButtonLabel: 'Discount',
        value: () => this.scopeVersion.discount?.getType() == 'PERCENTAGE' ?
          (this.scopeVersion.discount?.getValueByPercentage(this.scopeVersion.getTotalSellingPrice({
            excludeFees: false, excludeMsa: true, excludeDiscount: true }).getValue())) : (this.scopeVersion.discount?.getValueFormatted()),
        sub_heading: () => this.scopeVersion.discount?.getDescription(),
        sub_value: () => this.scopeVersion.discount?.getType() == 'PERCENTAGE' ? this.scopeVersion.discount?.getValueFormatted() + '%' : '',
        showMenu: () => this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion),
        add: this.addDiscount.bind(this),
        edit: this.editDiscount.bind(this),
        delete: this.deleteDiscount.bind(this),
        key: FinancialCardType[FinancialCardType.DISCOUNT],
        order: 0,
        commentable: { entity: this.scopeVersion, key: 'discountValue', type: 'scope' }
      },
      {
        ngIf: () => this.scopeVersion.feeItems?.length > 0,
        addCardNgIf: () => this.scopeVersion.feeItems?.length === 0 &&
          this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion) &&
          this.currentUser.hasPrivilege('SCOPE__CREATE_FEES'),
        hasSetting: this.currentUser.company.hasApplicationSetting('FEE_ITEM__ENABLE'),
        hasPrivilege: this.currentUser.hasPrivilege('SCOPE__VIEW_FEES'),
        heading: 'Total Fees',
        value: () => this.scopeVersion.getFeeValueTotal().amount,
        expandable: true,
        headingClass: 'large',
        showAddButton: () => this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion) &&
          this.currentUser.hasPrivilege('SCOPE__CREATE_FEES'),
        addButtonLabel: 'Fee',
        expanded: this.showFinancialsFees,
        expandedChange: this.toggleTimelineFees.bind(this),
        add: this.addScopeFee.bind(this),
        key: FinancialCardType[FinancialCardType.FEE_ITEMS],
        order: 1
      },
      {
        ngIf: () => this.scopeVersion.thirdPartyCosts?.length > 0,
        addCardNgIf: () => this.scopeVersion.thirdPartyCosts?.length === 0 &&
          this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion),
        heading: 'Total TPCs',
        addButtonLabel: 'TPC',
        value: () => this.scopeVersion.getTpcValueTotal().amount,
        expandable: true,
        hasPrivilege: true,
        hasSetting: true,
        headingClass: 'large',
        expanded: this.showTimelineTpcs,
        expandedChange: this.toggleTimelineTpcs.bind(this),
        showAddButton: () => this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion),
        key: FinancialCardType[FinancialCardType.THIRD_PARTY_COST],
        order: 2,
        add: this.addThirdPartyCost.bind(this),
        commentable: { entity: this.scopeVersion, key: 'scopeTotalTpcCost' }
      },
      {
        ngIf: () => this.scopeVersion.msaLineItem != null,
        hasPrivilege: true,
        hasSetting: true,
        heading: 'MSA',
        sub_heading: () => this.scopeVersion.msaLineItem?.getName(),
        value: () => this.scopeVersion.msaLineItem?.getType() == 'PERCENTAGE' ?
          (this.scopeVersion.msaLineItem.getValueByPercentage(this.scopeVersion.getTotalSellingPrice({
            excludeFees: false, excludeMsa: true, excludeDiscount: false }).getValue())) :
          (this.scopeVersion.msaLineItem?.getValueFormatted()),
        sub_value: () => this.scopeVersion.msaLineItem?.getType() == 'PERCENTAGE' ? this.scopeVersion.msaLineItem.getValueFormatted() + '%' : '',
        showMenu: () => this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion),
        edit: this.editMsaLineItem.bind(this),
        delete: this.deleteMsaLineItem.bind(this),
        key: FinancialCardType[FinancialCardType.MSA],
        order: 3,
        commentable: { entity: this.scopeVersion, key: 'msaLineItem' }
      },
      {
        ngIf: () => true,
        hasSetting: true,
        hasPrivilege: this.currentUser.hasPrivilege('SCOPE__VIEW_RATES'),
        heading: 'Resources',
        value: () => this.scopeVersion.getNaturalSellingPrice({ includeTpc: false }).amount,
        key: FinancialCardType[FinancialCardType.RESOURCES],
        order: 4,
        commentable: { entity: this.scopeVersion, key: 'scopeNaturalSellingPrice' }
      },
      {
        ngIf: () => this.scopeVersion.identity.costPlus != null,
        hasSetting: this.currentUser.company.hasApplicationSetting('COST_PLUS'),
        hasPrivilege: this.currentUser.hasPrivilege('SCOPE_MARGIN__VIEW'),
        heading: 'Cost Plus',
        value: () => this.scopeVersion.identity?.costPlus?.value ? this.scopeVersion.identity?.costPlus?.value + '%' : 0,
        key: FinancialCardType[FinancialCardType.COST_PLUS],
        order: 5
      },
      {
        ngIf: () => true,
        hasSetting: true,
        hasPrivilege: this.currentUser.hasPrivilege('SCOPE__VIEW_RATES'),
        heading: 'Scope Total',
        value: () => this.scopeVersion.containsUnmappedScopeMarkRoles ? '--' :
          this.scopeVersion.getTotalSellingPrice().amount,
        cardClass: 'purple-400',
        key: FinancialCardType[FinancialCardType.SCOPE_TOTAL],
        order: 6,
        commentable: { entity: this.scopeVersion, key: 'scopeTotalSellingPrice' }
      },
      {
        ngIf: () => true,
        hasSetting: true,
        hasPrivilege: this.currentUser.hasPrivilege('SCOPE_MARKUP__VIEW') && this.currentUser.hasPrivilege('SCOPE_MARGIN__VIEW'),
        heading: 'Margin',
        value: () => this.scopeVersion.getScopeByRoleTotalMargin(),
        sub_value: () => this.scopeVersion.getTotalProfit() ? (this.scopeVersion.getTotalProfit().amount.toFixed(2)) : 'N/A',
        sub_value_label: 'Profit',
        cardClass: 'purple-800',
        key: FinancialCardType[FinancialCardType.MARGIN],
        order: 7,
        commentable: { entity: this.scopeVersion, key: 'scopeTotalProfit' }
      },
      {
        ngIf: () => true,
        hasPrivilege: true,
        hasSetting: this.currentUser.company.hasApplicationSetting('SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS'),
        heading: 'Actuals',
        value: () => this.scopeVersion.totalActualHours(),
        sub_value: () => this.scopeVersion.totalPercentageComplete() + '%',
        sub_value_label: 'Total Complete Percentage',
        key: FinancialCardType[FinancialCardType.ACTUALS],
        order: 8
      },
    ];
    if (this.scopeVersion.identity.getFinancialsOrder()) {
      this.financialCards.forEach(c => {
          const find = this.scopeVersion.identity.getFinancialsOrder().find(o => o.key === c.key);
          c.order = find?.order
          c.disabled = find?.disabled
        }
      );
    }
    this.enabledCards = this.financialCards.filter(card => card.hasPrivilege && card.hasSetting)
  }

  reinitCards() {
    const state = this.enabledCards.map(c => { return {key: c.key, order: c.order, disabled: c.disabled }});
    this.store.dispatch(ScopeOverviewActions.updateFinancialsOrder( { scopeId: this.scopeVersion.identity.id, order: state } ))
  }

  isNumber = (value: any): boolean => !isNaN(parseFloat(value)) && isFinite(value);

  addRoles() {
    this.onAddRole.emit()
  }

  deleteDeliverable(deliverable: Deliverable) {
    this.onDeleteDeliverable.emit(deliverable);
  }

  acceptDeliverable(deliverable: Deliverable) {
    this.onAcceptDeliverable.emit(deliverable);
  }

  rejectDeliverable(deliverable: Deliverable) {
    this.onRejectDeliverable.emit(deliverable);
  }

  editFeeItem(feeItem: any, sectionId?: number, deliverableId?: number, componentId?: number) {
    this.onEditFee.emit({
      feeItem: feeItem,
      sectionId: sectionId,
      deliverableId: deliverableId,
      componentId: componentId,
    });
  }

  deleteFeeItem(feeItem: any, sectionId?: number, deliverableId?: number, componentId?: number) {
    this.onDeleteFee.emit({
      feeItem: feeItem,
      sectionId: sectionId,
      deliverableId: deliverableId,
      componentId: componentId,
    });
  }

  updateFeeItem(feeItem: any, section?: ScopeSection, deliverable?: Deliverable, component?: ScopeComponent) {
    let sectionId = section?.id || feeItem.section?.id;
    let deliverableId = deliverable?.id || feeItem.deliverable?.id;
    let componentId = component?.id || feeItem.component?.id;
    this.onUpdateFee.emit({
      feeItem: feeItem,
      sectionId: sectionId,
      deliverableId: deliverableId,
      componentId: componentId,
    });
  }

  setMenu() {
    this.thirdPartyCostColumns = [
      {
        key: TableColumnKey.TPC_NAME,
        name: 'THIRD PARTY COST',
        selected: true,
        isVisibleInList: () => false,
        field: 'name',
      },
      {
        key: TableColumnKey.AMOUNT,
        name: 'Amount',
        selected: true,
        isVisibleInList: () => true,
        field: 'quantity',
        isDisabled: () => !this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion),
        inputType: () => "box-number",
        inputContext: { isDecimal: false},
        onInputChange: (tpc: ThirdPartyCost, value) => {
          if (Number.isNaN(value) || value == null) return
          let edit = {...tpc}
          edit.quantity = value
          edit.formula = plainToInstance(ThirdPartyCostFormula, {...edit.formula, quantity: value})
          tpc = plainToInstance(ThirdPartyCost, edit)
          this.onUpdateThirdPartyCost.emit(tpc)
        },
      },
      {
        key: TableColumnKey.UNIT,
        name: 'Unit',
        selected: true,
        isVisibleInList: () => false,
        field: 'formula.subjectName',
      },
      {
        key: TableColumnKey.QUANTITY,
        name: 'Quantity',
        selected: true,
        isVisibleInList: (tpc: ThirdPartyCost) => !!(tpc.formula && tpc.formula.getName),
        value: (tpc: ThirdPartyCost) => tpc.formula?.unitTypeQuantity != undefined ? tpc.formula?.unitTypeQuantity : 1,
        inputContext: { isDecimal: false},
        isDisabled: () => !this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion),
        inputType: () => "box-number",
        onInputChange: (tpc: ThirdPartyCost, value) => {
          if (Number.isNaN(value) || value == null) return
          let edit = {...tpc}
          edit.formula = plainToInstance(ThirdPartyCostFormula, {...edit.formula, unitTypeQuantity: value})
          tpc = plainToInstance(ThirdPartyCost, edit)
          this.onUpdateThirdPartyCost.emit(tpc)
        },
      },
      {
        key: TableColumnKey.UNIT_TYPE,
        name: 'Unit type',
        value: (tpc: ThirdPartyCost) => {
          if (!tpc.formula || !tpc.formula.unitType || !tpc.formula.thirdPartyCostFormulaDefinitionSnapshot.unitTypes) return '-'
          return tpc.formula.unitType.name
        },
        isDisabled: (event: any) => {
          let tpc = event.entity
          return !this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion) || ((!tpc.formula || !tpc.formula.thirdPartyCostFormulaDefinitionSnapshot || tpc.formula.thirdPartyCostFormulaDefinitionSnapshot.unitTypes.length === 0))
        },
        optionsFunction: (tpc: ThirdPartyCost) => {
          if (!tpc.formula || !tpc.formula.thirdPartyCostFormulaDefinitionSnapshot || !tpc.formula.thirdPartyCostFormulaDefinitionSnapshot.unitTypes) return ['-']
          return ['-']
            .concat(tpc.formula.thirdPartyCostFormulaDefinitionSnapshot.unitTypes
              .map(type => type.name));
        },
        hideOptionsMenu: () => false,
        optionDisplayFn: (option: any) => option,
        onChange: (event: { value: any; element: ThirdPartyCost }) => {
          let tpc = event.element
          let type = tpc.formula.thirdPartyCostFormulaDefinitionSnapshot.unitTypes.find(t => t.name === event.value)
          let edit = {...tpc}
          let editFormula = {...edit.formula}

          if (edit.formula.unitType) {
            let from = tpc.formula.unitType.id
            let to = editFormula.unitType.id
            let conversion = this.findConversion(tpc, from, to);
            if (conversion) {
              edit.cost =  new Money(edit.cost.amount * conversion.value, edit.cost.currency)
            } else {
              let reverseConversion = this.findConversion(tpc, to, from);
              if (reverseConversion) {
                edit.cost =  new Money(edit.cost.amount / reverseConversion.value, edit.cost.currency)
              }
            }
          }

          let formula = {...edit.formula, unitType: type}
          edit.formula = plainToInstance(ThirdPartyCostFormula, formula)
          tpc = plainToInstance(ThirdPartyCost, edit)
          this.onUpdateThirdPartyCost.emit(tpc)
        },
        selected: true,
        noSort: true
      },
      {
        key: TableColumnKey.UNIT_COST,
        name: 'Unit cost',
        selected: true,
        isVisibleInList: () => true,
        field: 'cost',
        isDisabled: () => !this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion),
        inputType: () => "editable-number",
        inputContext: { isDecimal: true, formClass: 'wide'},
        onInputChange: (tpc: ThirdPartyCost, value) => {
          if (Number.isNaN(value) || value == null) return
          let edit = {...tpc}
          edit.cost = new Money(value, edit.cost.currency)
          tpc = plainToInstance(ThirdPartyCost, edit)
          this.onUpdateThirdPartyCost.emit(tpc)
        },
      },
      {
        key: TableColumnKey.OVERTIME_QUANTITY,
        name: 'Overtime quantity',
        selected: true,
        isVisibleInList: (tpc: ThirdPartyCost) => !!(tpc.formula && tpc.formula.getName && tpc.formula.thirdPartyCostFormulaDefinitionSnapshot?.includeAddAmount),
        field: 'formula.overtimeUnitQuantity',
        inputContext: { isDecimal: false},
        isDisabled: (event: any) => {
          let tpc = event.entity
          return !this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion) || ((!tpc.formula || !tpc.formula.getName || !tpc.formula.thirdPartyCostFormulaDefinitionSnapshot?.includeAddAmount))
        },
        onInputChange: (tpc: ThirdPartyCost, value) => {
          if (Number.isNaN(value) || value == null) return
          let edit = {...tpc}
          edit.formula = plainToInstance(ThirdPartyCostFormula, {...edit.formula, overtimeUnitQuantity: value})
          tpc = plainToInstance(ThirdPartyCost, edit)
          this.onUpdateThirdPartyCost.emit(tpc)
        },
        inputType: () => "box-number",
      },
      {
        key: TableColumnKey.OVERTIME_UNIT_TYPE,
        name: 'Overtime unit type',
        selected: true,
        value: (tpc: ThirdPartyCost) => {
          if ((!tpc.formula || !tpc.formula.getName || !tpc.formula.thirdPartyCostFormulaDefinitionSnapshot?.includeAddAmount)) return '-'
          return this.overtimeTypes.find(t => t.id == tpc.formula.overtimeUnitType).name
        },
        isDisabled: (event: any) => {
          let tpc = event.entity
          return !this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion) || ((!tpc.formula || !tpc.formula.name || !tpc.formula.thirdPartyCostFormulaDefinitionSnapshot?.includeAddAmount))
        },
        optionDisplayFn: (option: any) => option,
        optionsFunction: (tpc: ThirdPartyCost) => {
          if (!tpc.formula || !tpc.formula.thirdPartyCostFormulaDefinitionSnapshot?.includeAddAmount) return ['-']
          return ['Hour', 'Day', "Flat fee"]
        },
        hideOptionsMenu: () => false,
        onChange: (event: { value: any; element: ThirdPartyCost }) => {
          let tpc = event.element
          let type = this.overtimeTypes.find(t => t.name === event.value)
          let edit = {...tpc}
          let formula = {...edit.formula, overtimeUnitType: type.id}
          edit.formula = plainToInstance(ThirdPartyCostFormula, formula)
          tpc = plainToInstance(ThirdPartyCost, edit)
          this.onUpdateThirdPartyCost.emit(tpc)
        },
        noSort: true
      },
      {
        key: TableColumnKey.OVERTIME_RATE,
        name: 'Overtime rate',
        selected: true,
        isVisibleInList: (tpc: ThirdPartyCost) => !!(tpc.formula && tpc.formula.getName && tpc.formula.thirdPartyCostFormulaDefinitionSnapshot?.includeAddAmount),
        isDisabled: () => !this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion),
        value: (thirdPartyCost: ThirdPartyCost) => this.getAddAmountWithCurrency(thirdPartyCost),
        inputType: () => "editable-number",
        inputContext: { isDecimal: true, formClass: 'wide'},
        onInputChange: (tpc: ThirdPartyCost, value) => {
          if (Number.isNaN(value) || value == null) return
          let edit = {...tpc}
          edit.formula = plainToInstance(ThirdPartyCostFormula, {...edit.formula, overtimeRate: value})
          tpc = plainToInstance(ThirdPartyCost, edit)
          this.onUpdateThirdPartyCost.emit(tpc)
        },
      },
      {
        key: TableColumnKey.OVERTIME_TOTAL,
        name: 'Overtime total',
        selected: true,
        isVisibleInList: () => false,
        value: (thirdPartyCost: ThirdPartyCost) => this.getTotalAddAmountWithCurrency(thirdPartyCost),
      },
      {
        key: TableColumnKey.MARK_UP,
        name: 'Markup',
        selected: true,
        isVisibleInList: () => true,
        field: 'markupPercentageDecimal',
        isDisabled: () => !this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion),
        inputType: () => "editable-number",
        inputContext: { isDecimal: true, formClass: 'wide'},
        onInputChange: (tpc: ThirdPartyCost, value) => {
          if (Number.isNaN(value) || value == null) return
          let edit = {...tpc}
          edit.markupPercentageDecimal = value
          tpc = plainToInstance(ThirdPartyCost, edit)
          this.onUpdateThirdPartyCost.emit(tpc)
        },
      },
      {
        key: TableColumnKey.SELLING_PRICE,
        name: 'Selling price',
        selected: true,
        isDisabled: () => !this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion),
        isVisibleInList: () => true,
        value: (thirdPartyCost: ThirdPartyCost) => thirdPartyCost.calculateSellingPrice(),
        inputType: () => "editable-number",
        inputContext: { isDecimal: true, formClass: 'wide'},
        onInputChange: (tpc: ThirdPartyCost, value) => {
          if (Number.isNaN(value) || value == null) return
          let edit = {...tpc}
          let sellingForOne = value / edit.quantity
          edit.markupPercentageDecimal = Number(parseFloat(((sellingForOne - edit.cost.amount) / edit.cost.amount * 100).toString()).toFixed(2))
          tpc = plainToInstance(ThirdPartyCost, edit)
          this.onUpdateThirdPartyCost.emit(tpc)
        },
      },
    ];

    this.scopeActions = [
      {
        name: () => {
          let totalRolesCount = this.getTotalRolesCount()
          let ratecardRolesCount = this.currentRatecard?.getTotalRolesCount()
          return totalRolesCount ?
            (totalRolesCount === ratecardRolesCount ? 'Remove Roles' : 'Add/Remove Roles'): 'Add Roles'
        },
        icon: () => 'assignment_ind',
        callback: () => { this.onAddRole.emit() },
      }
    ];

    this.scopeOptions = [
      {
        name: 'Archive',
        icon: 'archive',
        click: () => this.onArchiveScope.emit(),
        isVisible: () => !this.scopeVersion.archived && this.scopeVersion.hasPrivilege(Privilege.SCOPE__ARCHIVE, this.currentUser)
            && (this.scopeApprovalFlowService.findMember(this.currentUser) || this.currentUser.hasAccessRole(UserRoleLevel.ADMIN))
      },
      {
        name: 'Unarchive',
        icon: 'unarchive',
        click: () => this.onUnarchiveScope.emit(),
        isVisible: () => this.scopeVersion.archived && this.scopeVersion.hasPrivilege(Privilege.SCOPE__ARCHIVE, this.currentUser)
            && (this.scopeApprovalFlowService.findMember(this.currentUser) || this.currentUser.hasAccessRole(UserRoleLevel.ADMIN))
      },
      {
        name: `Delete ${this.scopeVersion.identity.isTemplate ? 'Template' : 'Scope'}`,
        icon: 'delete',
        click: () => this.onDeleteScope.emit(),
        isVisible: () => this.scopeApprovalFlowService.stateChecks.isDraft
            && !this.scopeVersion.scopeVersionCustomFieldValueStructure?.getCustomFieldValueById("isEngagementScopeSold")?.value
            && this.scopeVersion.hasPrivilege(this.scopeVersion.identity.isTemplate ? Privilege.SCOPE_TEMPLATE__DELETE : Privilege.SCOPE__DELETE, this.currentUser)
            && (this.scopeApprovalFlowService.findMember(this.currentUser) || this.currentUser.hasAccessRole(UserRoleLevel.ADMIN))
      },
      {
        name: `Export`,
        icon: 'save_alt',
        click: () => this.onExport.emit(),
        isVisible: () => this.scopeVersion.hasPrivilege(Privilege.SCOPE__EXPORT_XLSX, this.currentUser)
            && (this.scopeApprovalFlowService.findMember(this.currentUser) || this.currentUser.hasAccessRole(UserRoleLevel.ADMIN))
      }
    ];

    this.feeMenuOptions = []
    if (this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion) && this.currentUser.hasPrivilege('SCOPE__DELETE_FEES')) {
      this.feeMenuOptions.push({
        callback: (element: any) => this.deleteFeeItem(element.entity, element.entity.scopeSection?.id, element.entity.deliverable?.id, element.entity.component?.id),
        name: () => 'Delete',
        icon: () => 'delete'
      })
    }
    if (this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion) && this.currentUser.hasPrivilege('SCOPE__DELETE_FEES')) {
      this.feeMenuOptions.push({
        callback: (element: any) => this.editFeeItem(element.entity, element.entity.scopeSection?.id, element.entity.deliverable?.id, element.entity.component?.id),
        name: () => 'Edit',
        icon: () => 'edit'
      })
    }
  }

  overtimeTypes = [
    {id: "HOUR", name: "Hour"},
    {id: "DAY", name: "Day"},
    {id: "FLAT_FEE", name: "Flat fee"},
  ]

  findConversion(tpc, from, to) {
    return tpc.formula.unitTypeConvertors.find(c => c.fromUnitType === from && c.toUnitType === to);
  }

  private getAddAmountWithCurrency(tpc: ThirdPartyCost) {
    return new Money(tpc.formula?.overtimeRate || null, tpc.cost.currency)
  }

  private getTotalAddAmountWithCurrency(tpc: ThirdPartyCost): Money {
    return new Money(
      tpc.formula
        ? tpc.formula.overtimeRate ? tpc.formula.overtimeRate * tpc.formula.overtimeUnitQuantity : 0
        : null,
      tpc.cost.currency
    );
  }

  addTask() {
    this.onAddTask.emit()
  }

  addThirdPartyCost() {
    this.onAddThirdPartyCost.emit()
  }

  updateScopeThirdPartyCost(tpc: ThirdPartyCost) {
    this.onUpdateThirdPartyCost.emit(tpc)
  }

  addScopeFee() {
    this.onAddFee.emit();
  }

  toggleTimelineFees(event: boolean) {
    this.showTimelineTpcs = false
    this.showFinancialsFees = event

    if (!this.allFeesLoaded) {
      this.store.dispatch(ScopeOverviewActions.getDeliverableAndComponentFees({ scopeId: this.scopeVersion.identity.id, versionId: this.scopeVersion.version }))
    }
  }

  toggleTimelineTpcs(event: boolean) {
    this.showFinancialsFees = false
    this.showTimelineTpcs = event

    if (!this.allTpcsLoaded) {
      this.store.dispatch(ScopeOverviewActions.getAllDeliverableTPCs({ scopeId: this.scopeVersion.identity.id, versionId: this.scopeVersion.version }))
    }
  }

  addFee() {
    this.onAddFee.emit();
  }

  addDiscount() {
    this.onAddDiscount.emit()
  }

  editDiscount() {
    this.onAddDiscount.emit()
  }

  deleteDiscount() {
    this.onDeleteDiscount.emit()
  }

  editMsaLineItem() {
    this.onEditMsaLineItem.emit()
  }

  deleteMsaLineItem(card: any) {
    this.onDeleteMsaLineItem.emit(card)
  }

  rearrangeFinancials(event: CdkDragDrop<any>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.enabledCards, event.previousIndex, event.currentIndex)
      this.enabledCards.forEach((d, i) => d.order = i)
    }
  }

  getTotalRolesCount() {
    return this.scopeVersion.deliverables?.[0]?.components?.[0]?.departments.flatMap(d => d.roles).length;
  }

  protected readonly Array = Array;
  protected readonly Privilege = Privilege
  protected readonly trackById = trackById;
}

interface FinancialCard {
  ngIf?: () => boolean;
  addCardNgIf?: () => boolean;
  hasPrivilege?: boolean;
  hasPrivilegeEntity?: any;
  hasSetting?: boolean;
  heading: string;
  sub_heading?: () => string;
  value: () => any;
  sub_value?: () => any;
  showMenu?: () => boolean;
  edit?: () => void;
  delete?: (card: any) => void;
  expandable?: boolean;
  showAddButton?: () => boolean;
  sub_value_label?: string;
  cardClass?: string;
  headingClass?: string;
  addButtonLabel?: string;
  expanded?: boolean;
  expandedChange?: (expanded: boolean) => void;
  add?: () => void;
  disabled?: boolean;
  key: string;
  order: number;
  commentable?: { key: string, entity?: any, type?: string };
}

export enum FinancialCardType {
  DISCOUNT,
  FEE_ITEMS,
  THIRD_PARTY_COST,
  MSA,
  RESOURCES,
  COST_PLUS,
  SCOPE_TOTAL,
  MARGIN,
  ACTUALS
}


