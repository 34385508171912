<button *ngIf="!toggleButton && !separatedMenu" class="action-button" mat-button [matMenuTriggerFor]="menu">
  {{ buttonText }} <mat-icon class="material-symbols-rounded" iconPositionEnd>expand_more</mat-icon>
</button>
<button
  *ngIf="toggleButton && !separatedMenu"
  class="toggle-button float-right ml-1"
  (click)="$event.stopPropagation()"
  mat-mini-fab
  [matMenuTriggerFor]="menu">
  <mat-icon class="material-symbols-rounded">more_horiz</mat-icon>
</button>
<div class='flex' *ngIf="separatedMenu">
  <button class="main-action-button" mat-button (click)="onClick()">
    {{ buttonText }}
  </button>
  <mat-divider [vertical]="true"></mat-divider>
  <button class="secondary-action-button" mat-button [matMenuTriggerFor]="menu">
    <mat-icon class="material-symbols-rounded">expand_more</mat-icon>
  </button>
</div>
<mat-menu #menu="matMenu" backdropClass="options-menu">
  <ng-container *ngFor="let option of menuOptions">
    <button [hidden]="option.isHidden?.(element)" [has-privilege]="option.hasPrivilege?.(element)"
            [has-privilege-entity]="option.hasPrivilegeEntity?.(element)" (click)="option.callback(this.element, this.parentElement)"
            class="menu-button {{option.hasBorder ? 'border-bottom' : ''}}" mat-menu-item>
      <mat-icon class="material-symbols-rounded orange" *ngIf="option.icon">{{ option.icon(element) }}</mat-icon>
      <img *ngIf="option.img" src="{{option.img()}}" alt="">
      <span> {{ option.name?.(element) }}</span>
    </button>
  </ng-container>
</mat-menu>
