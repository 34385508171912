export enum ScenarioQuestionCondition {
    ALWAYS,
    ON_CONDITION
}

export enum ScenarioQuestionConditionTitle {
    ALWAYS = 'Always',
    ON_CONDITION = 'On condition',
    NEVER = 'Never',
}
