import {Component, Inject, OnInit} from '@angular/core'
import {CommonModule} from '@angular/common'
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog'
import {SharedModule} from '@app/shared/shared.module'
import {PipesModule} from '@app/shared/pipe/index.module'
import {ExportDocumentService} from '../../service/export-document-service'
import {
  ScopeUiAutocompleteComponent
} from '@app/shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component'
import {SecondPartyContact} from '@app/features/scope-overview/model/second-party-contact.model'
import {isValidEmail} from '@shared/utils/utils'
import {SNACKBAR_LENGTH_SHORT, SnackbarEventType, SnackbarService} from '@shared/utils/snackbar.service'
import {ExportModalConfig} from '../../model/export-modal-config'

export interface AdobeSignSigner {
  email?: string
  carbonCopy?: boolean
  name?: string
}

export interface Adobesigners {
  selectedSigners: AdobeSignSigner[]
  selectedCC: AdobeSignSigner[]
}

@Component({
  selector: 'export-adobesign',
  standalone: true,
  imports: [CommonModule, PipesModule, MatDialogModule, SharedModule, ScopeUiAutocompleteComponent],
  templateUrl: './export-adobesign.component.html',
  styleUrls: ['./export-adobesign.component.scss'],
})
export class ExportAdobsignModal implements OnInit {
  selectedOption: boolean

  isAdobesignForm: boolean

  options: string[]

  signers: Adobesigners

  noSigner: boolean

  noCarbonCopy: boolean

  outputOptions: { name: string; selected: boolean }[]

  availableContacts: SecondPartyContact[]

  filteredContacts: SecondPartyContact[]

  isFormValid: boolean

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ExportModalConfig,
    public dialogRef: MatDialogRef<ExportAdobsignModal>,
    public dialog: MatDialog,
    private exportDocumentService: ExportDocumentService,
    private snackbarService: SnackbarService
  ) {
    this.isFormValid = false
    this.selectedOption = false
    this.isAdobesignForm = true
    this.noSigner = true
    this.noCarbonCopy = true
    this.outputOptions = this.exportDocumentService.makeDocuSignAvailableOutputNames(
      this.data.currentUser,
      this.data.currentScope
    )
    this.signers = {
      selectedSigners: [],
      selectedCC: []
    }
  }

  ngOnInit() {
    this.getSecondParty()
  }

  confirmOutputFormat() {
    this.isAdobesignForm = false
  }

  getSecondParty() {
    this.exportDocumentService
      .getSecondParty(this.data.currentScope.identity.secondParty.id)
      .subscribe((secondParty) => {
        this.availableContacts = secondParty.contacts
        this.filteredContacts = secondParty.contacts
      })
  }

  onSelectionChange(contact: SecondPartyContact | string, carbonCopy) {
    if (!contact) {
      if (!carbonCopy) {
        let availableContact = this.availableContacts.find((c) => c.email === this.signers.selectedSigners[0].email)
        if (availableContact) {
          this.filteredContacts = this.filteredContacts.concat(availableContact)
        }
        this.signers.selectedSigners = []
        this.noSigner = true
        this.isFormValid = false
      }
      return
    }

    if (typeof contact === 'string') {
      if (!isValidEmail(contact)) {
        this.snackbarService.showSnackbar(`Email is invalid`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.WARNING)
        return
      } else if (this.signers.selectedSigners[0]?.email?.toLowerCase() === contact.toLowerCase() ||
        this.signers.selectedCC.find((c) => c.email.toLowerCase() === contact.toLowerCase())) {
        this.snackbarService.showSnackbar(`Email has already been added`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.WARNING)
        return
      }
    }

    let signer = {
      email: contact instanceof SecondPartyContact ? contact.email : contact,
      carbonCopy: carbonCopy,
      name: contact instanceof SecondPartyContact ? contact.contactName : '',
    }
    if (carbonCopy) {
      this.signers.selectedCC.push(signer)
      this.noCarbonCopy = false
    } else {
      this.signers.selectedSigners = [signer]
      this.isFormValid = true
      this.noSigner = false
    }
    this.filteredContacts = this.filteredContacts.filter((c) => c.email !== signer.email)
  }

  onInputChange(event: any) {
    const filterValue = event.target.value.toLowerCase()
    if (filterValue.length) {
      filterValue.length ? (this.noSigner = true) : (this.noSigner = false)
    }
  }

  optionFunction = (option: SecondPartyContact) => {
    return option instanceof SecondPartyContact ? `${option.contactName} [${option.email}]` : option
  }

  removeCCSigner(email: string) {
    let availableContact = this.availableContacts.find((c) => c.email === email)
    if (availableContact) {
      this.filteredContacts = this.filteredContacts.concat(availableContact)
    }
    this.signers.selectedCC = this.signers.selectedCC.filter((signer) => signer.email !== email)
    this.noCarbonCopy = true
  }

  onFormatOptionChange(output: { name: string; selected: boolean }) {
    output.selected = !output.selected
    this.selectedOption = this.outputOptions.findIndex((o) => o.selected) > -1
  }

  onConfirm() {
    let contacts = this.signers.selectedSigners.concat(this.signers.selectedCC)
    let files = this.outputOptions.filter((option) => option.selected).map((option) => option.name)

    this.exportDocumentService.exportToAdobesign(this.data.scopeId, contacts, files)
    this.dialogRef.close()
  }
}
