import { CanDeactivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean;
}

export const CanDeactivateGuard: CanDeactivateFn<ComponentCanDeactivate> = (
  component: ComponentCanDeactivate
): Observable<boolean | UrlTree> => {
  return new Observable<boolean | UrlTree>((obs) => {
    if (!component || !component.canDeactivate) {
      obs.next(true)
      return
    }
    return component.canDeactivate()
      ? obs.next(true)
      : obs.next(
        confirm(
          'You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.'
        )
      );
  });
};
