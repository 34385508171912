<loader-modal *ngIf="isCleaningExternalData"></loader-modal>
<div class='scope-field-container'>

  <div class='flex d-flex-justify-between pt-6 px-10'>
    <h3 class='product-heading-bold'>Scope Fields</h3>
    <div class='flex gap-1'>
      <scope-ui-input
        [icon]="'search'"
        [cancelIcon]="'cancel'"
        [inputLabel]="'Search'"
        [inputClass]="'search-field'"
        (onCancel)='onCancelPressed()'
        (onInputChange)='onSearchKeyPressed($event)' />
      <button (click)='openCreateModal()' mat-flat-button>
        <mat-icon class='material-symbols-rounded'>add</mat-icon>
        New Field
      </button>
    </div>
  </div>

  <div class='mt-6 scope-field-sub-container'>
    <ng-container class='py-3'>
      <scope-ui-table
        [loggedInUser]='loggedInUser'
        [data]='mappedDynamicFieldDataSource$ | async'
        [displayedColumns]='dynamicFieldColumnsPrefsArray'
        [noPagination]='true'
        [rowGaps]='true'
        [menuOptions]='dynamicFieldMenuOptions'
        [showToggleMenu]='true'
        [addToggleListColumn]='true'
        [noPreferences]='true'
      ></scope-ui-table>
    </ng-container>
  </div>
</div>
