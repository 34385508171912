import { ThirdPartyCost } from '@app/core/model/third-party-cost.model'
import { CompanyScopeCustomFieldsDefinition } from '@app/features/scope-overview/model/company-scope-custom-fields-definition.model'
import { FeeItemInstance } from '@app/features/scope-overview/model/fee-item.model'
import { Money } from '@app/features/scope-overview/model/money.model'
import { ScopeCustomFieldValueStructure } from '@app/features/scope-overview/model/scope-custom-field-value-structure.model'
import { ScopeIdentity } from '@app/features/scope-overview/model/scope-identity'
import { ScopeReviewer } from '@app/features/scope-overview/model/scope-reviewer.model'
import { ScopeSection } from '@app/features/scope-overview/model/scope-section'
import { Deliverable } from '@app/features/scoping/models/deliverable.model'
import { UserComment } from '@core/model/comment.model'
import { PrivilegeAware } from '@core/model/definitions/privileged-aware.interface'
import { Privilege } from '@core/model/enums/privilege.enum'
import { FolderVersion } from '@core/model/folder-version.model'
import { ScopeSubmitter } from '@core/model/scope-submitter.model'
import { User } from '@core/model/user.model'
import { isNonTradedDeliverable } from '@shared/utils/utils'
import { Type } from 'class-transformer'
import { Discount } from './discount.model'
import { ScopeMsaLineItem } from './scope-msa-line-item.model'

export class ScopeVersion implements PrivilegeAware {
  id!: number
  name!: string
  status!: StatusType
  version!: number
  traffickable!: boolean
  traffickableThroughWorkato!: boolean
  useRetainedHours!: boolean
  isDeliverablesUninitialised!: boolean
  currencyUnit!: string
  description!: string
  internalNote!: string
  readyToTraffic: boolean
  margin: number
  financialsOrder: string
  @Type(() => Date) createdTs!: Date
  @Type(() => Money) budget!: Money
  @Type(() => Date) lastTraffickedDate!: Date
  @Type(() => Date) startDate!: Date
  @Type(() => Date) endDate!: Date
  @Type(() => User) createdBy!: User
  @Type(() => ScopeIdentity) identity!: ScopeIdentity
  @Type(() => FolderVersion) scopeOfWorkVersion?: FolderVersion
  @Type(() => Deliverable) deliverables!: Deliverable[]
  @Type(() => ScopeSection) sections!: ScopeSection[]
  @Type(() => ScopeCustomFieldValueStructure) scopeVersionCustomFieldValueStructure!: ScopeCustomFieldValueStructure
  @Type(() => CompanyScopeCustomFieldsDefinition)
  companyScopeCustomFieldsDefinition!: CompanyScopeCustomFieldsDefinition
  trafficSystemEntityMetadata!: any
  naturalSellingPrice!: Money
  totalSellingPrice!: Money
  thirdPartyCostBudget!: Money
  deliverableBudgetTotal!: Money
  budgetBalance!: Money
  userPrivilegeRestrictions?: any[]
  userAdditionalPrivileges?: any[]
  containsUnmappedScopeMarkRoles!: boolean
  approved!: boolean
  archived!: boolean
  collaborators!: ScopeSubmitter[]
  reviewers!: ScopeReviewer[]
  approvers!: any[]
  @Type(() => ThirdPartyCost) thirdPartyCosts: ThirdPartyCost[]
  @Type(() => Discount) discount!: Discount
  @Type(() => FeeItemInstance) feeItems!: FeeItemInstance[]
  scopeRoles!: any[]
  @Type(() => ScopeMsaLineItem) msaLineItem: ScopeMsaLineItem
  finalised: boolean
  scopeApprovedWebHookInfo!: ScopeApprovedWebHookInfo
  @Type(() => UserComment) comments!: UserComment[]
  feeValueTotal?: number
  @Type(() => Money) tpcValueTotal?: Money
  defaultView?: DefaultView = DefaultView.table
  configuration: ScopeConfiguration
  dynamicFields?: any
  isEditable?: boolean

  getStatusText(): string {
    let statusText = this.status.toString().replace(/_/, ' ')
    return statusText.charAt(0).toUpperCase() + statusText.toLowerCase().slice(1)
  }

  getAllocatedBudget(): Money {
    return this.deliverables
      .filter(isNonTradedDeliverable)
      .map((d) => d.budget)
      .reduce(function (total, balance) {
        if (total == null) {
          return balance
        }
        return total.add(balance)
      }, new Money(0, this.currencyUnit))
  }

  getUnallocatedBudget(): Money {
    const totalDeliverableBudget = this.getTotalDeliverableBudget()
    if (!this.budget || !totalDeliverableBudget) return new Money(0, this.currencyUnit)
    return this.budget.subtractValue(totalDeliverableBudget.amount || 0)
  }

  getTotalSellingPrice(ctx = { excludeFees: false, excludeMsa: false, excludeDiscount: false }): Money {
    if (this.containsUnmappedScopeMarkRoles) {
      return null
    }

    if (this.isDeliverablesUninitialised) {
      return this.totalSellingPrice
    }

    let total = this.getNaturalSellingPrice()
    let currentTotal = total?.amount

    // Add Third Party costs to current total
    if (this.thirdPartyCosts?.length !== 0) {
      currentTotal = currentTotal + this.getScopeThirdPartyCostSum().amount
    }

    //  Add fee items if any to current total.
    if (this.feeItems != null && !ctx.excludeFees) {
      for (let id = 0; id < this.feeItems.length; id++) {
        let val = this.feeItems[id].feeItem.amount
        if (this.feeItems[id].feeItem.amountType === 'PERCENTAGE') {
          val = total.amount * (this.feeItems[id].feeItem.amount / 100)
        }
        currentTotal = currentTotal + val
      }
    }

    // Check for discount on scope
    if (this.discount != null && !ctx.excludeDiscount) {
      let discountValue = parseFloat(this.discount.getValue().toString())
      if (this.discount.getType() == 'AMOUNT') {
        currentTotal = currentTotal - discountValue
      } else {
        currentTotal = currentTotal - currentTotal * (discountValue / 100)
      }
    }

    // Check for MSA line item.
    if (this.msaLineItem != null && !ctx.excludeMsa) {
      let val: number = parseFloat(this.msaLineItem.value.toString())
      if (this.msaLineItem.type == 'PERCENTAGE') {
        val = currentTotal * (val / 100)
      }

      if (this.msaLineItem.chargeType == 'FEE') {
        currentTotal = currentTotal + val
      } else {
        currentTotal = currentTotal - val
      }
    }

    return new Money(currentTotal, total?.currency)
  }

  getNaturalSellingPrice(ctx = { includeTpc: false }): Money {
    let total = new Money(0, this.currencyUnit)
    if (this.isDeliverablesUninitialised) {
      return this.naturalSellingPrice
    }
    this.deliverables
      .filter((d) => !d.section && isNonTradedDeliverable(d))
      .forEach((d) => (total = total.add(d.getTotalSellingPrice())))
    if (ctx.includeTpc && this.thirdPartyCosts?.length !== 0) {
      total = total.add(this.getScopeThirdPartyCostSum())
    }
    this.sections.forEach((s) => (total = total.add(s.getTotalSellingPrice(this))))
    return total
  }

  getTotalDeliverableBudget(): Money {
    if (!this.isDeliverablesUninitialised) {
      return this.deliverables
        .filter(isNonTradedDeliverable)
        .map((d) => d.budget)
        .reduce(function (total, budget) {
          if (total == null) {
            return budget
          }
          return total.add(budget)
        }, new Money(0, this.currencyUnit))
    } else {
      return this.deliverableBudgetTotal
    }
  }

  getBudgetBalance(): Money {
    if (!this.isDeliverablesUninitialised || this.budgetBalance == null) {
      const totalDeliverableBudget = this.getTotalDeliverableBudget()
      let useBudget =
        (this.budget?.amount || 0) > (totalDeliverableBudget.amount || 0) ? this.budget : totalDeliverableBudget
      const scopeValueMoney = this.totalSellingPrice
      return useBudget.subtractValue(scopeValueMoney ? scopeValueMoney.amount || 0 : 0)
    } else {
      return this.budgetBalance
    }
  }

  getMarkup() {
    var totalValue = 0
    var totalCost = 0
    this.deliverables.forEach(function (deliverable) {
      if (deliverable.getTotalSellingPrice() != null && deliverable.getTotalSellingPrice() != undefined) {
        totalValue += parseFloat(String(deliverable.getTotalSellingPrice().amount))
      }
      if (deliverable.getTotalCostPrice() != null && deliverable.getTotalCostPrice() != undefined) {
        totalCost += parseFloat(String(deliverable.getTotalCostPrice().amount))
      }
    })

    if (totalCost == 0) {
      return 100.0
    }
    let grossProfit = totalValue - totalCost
    if (grossProfit == 0) {
      return 100.0
    }
    return parseFloat(String((grossProfit / totalCost) * 100)).toFixed(2)
  }

  getTotalProfit(targetDeliverables?: Deliverable[]) {
    const isScopeTotalling = targetDeliverables == null

    let deliverables = targetDeliverables ? targetDeliverables : this.deliverables

    var result = new Money(0, this.currencyUnit)
    deliverables.forEach((deliverable) => {
      result = result.add(deliverable.getTotalProfit())
    })
    if (this.thirdPartyCosts?.length !== 0) {
      this.thirdPartyCosts.forEach((cost) => {
        result = result.add(cost.getProfit())
      })
    }
    if (isScopeTotalling) {
      let totalDiscount = this.totalDiscount()
      if (totalDiscount) {
        result = result.subtractValue(totalDiscount.amount)
      }
    }
    return result
  }

  getCachedTotalScopeMarkHours() {
    var total = 0
    for (var i = 0; i < this.deliverables.length; i++) {
      total += this.deliverables[i].scopeMarkTotalHours
    }
    return total
  }

  getScopeThirdPartyCostSum() {
    let currentTotal = 0
    this.thirdPartyCosts?.forEach((cost) => {
      currentTotal = currentTotal + cost.calculateSellingPrice().amount
    })
    return new Money(currentTotal, this.getRateCardCurrency())
  }

  totalScopeMarkSellingPrice(excludeDeliverableId?: number) {
    let currency = this.getRateCardCurrency()
    var total = new Money(null, currency)
    for (var i = 0; i < this.deliverables.length; i++) {
      var deliverable = this.deliverables[i]
      if (excludeDeliverableId && excludeDeliverableId == deliverable.id) {
        continue
      }
      if (deliverable.totalScopeMarkSellingPrice(currency) == null) return null
      total = total.add(deliverable.totalScopeMarkSellingPrice(currency))
    }
    return total
  }

  totalDiscount() {
    if (this.identity.getRateCard() == null) {
      return null
    }

    if (this.discount != null) {
      return new Money(
        this.discount.getValueByPercentage(this.naturalSellingPrice ? this.naturalSellingPrice.amount : null),
        this.getRateCardCurrency()
      )
    } else {
      return new Money(0, this.getRateCardCurrency())
    }
  }

  getRateCardCurrency(): string {
    if (this.identity.getRateCard() == null) return ''

    return this.identity.getRateCard().currencyCode
  }

  getTotalMargin(targetDeliverables: Deliverable[] = []): number {
    const isScopeTotalling = targetDeliverables == null
    let deliverables = targetDeliverables ? targetDeliverables : this.deliverables
    let totalValue = 0
    deliverables.forEach(function (deliverable) {
      if (deliverable.getTotalSellingPrice() != null && deliverable.getTotalSellingPrice() != undefined) {
        totalValue += deliverable.getTotalSellingPrice().amount
      }
    })
    let totalProfit = this.getTotalProfit(isScopeTotalling ? [] : deliverables)
    if (totalValue == 0) {
      return 0
    }

    return parseFloat((((totalProfit.amount || 0) / totalValue) * 100).toFixed(2))
  }

  getTotalRateCardHours() {
    var total = 0

    this.deliverables.filter(isNonTradedDeliverable).forEach((d) => (total += d.getTotalRateCardHours()))

    return total
  }

  getFeePrice(fee: FeeItemInstance, amount: number): number {
    if (fee.feeItem.amountType === 'MONETARY') {
      return parseFloat(fee.feeItem.amount.toFixed(2))
    } else {
      return parseFloat(((Number(amount) * fee.feeItem.amount) / 100).toFixed(2))
    }
  }

  getTotalFees(amount: number | null = null) {
    let total = 0
    if (this.feeItems?.length) {
      if (amount == null && this.feeItems.find((fee) => fee.feeItem.amountType !== 'MONETARY')) {
        amount = this.getNaturalSellingPrice().amount
      }
      for (let feeItem of this.feeItems) {
        total += Number(this.getFeePrice(feeItem, amount))
      }
    }
    return parseFloat(total.toFixed(2))
  }

  getFeeValueTotal() {
    let total = this.getTotalFees()
    let currency = this.getRateCardCurrency()
    this.sections.forEach((section) => {
      let sectionTotal = section.getTotalFees(this)
      if (sectionTotal != null) {
        total += sectionTotal
      }
    })
    this.deliverables.forEach((deliverable) => {
      let deliverableTotal = deliverable.getFeeValueTotal()
      if (deliverableTotal != null) {
        total += deliverableTotal
      }
    })
    return new Money(total, currency)
  }

  getTpcValueTotal() {
    let total = this.getScopeThirdPartyCostSum()
    this.deliverables.forEach((deliverable) => {
      let deliverableTotal = deliverable.getTpcValueTotal()
      if (deliverableTotal != null) {
        total = total.add(deliverableTotal)
      }
    })
    return total
  }

  getAvgMarginOfSection(sectionId) {
    if (sectionId == null || isNaN(sectionId)) {
      throw 'Section ID is not provided'
    }
    let targetDeliverables = this.deliverables.filter((deliverable) => {
      return deliverable.section != null && deliverable.section.id == sectionId
    })
    return this.getTotalMargin(targetDeliverables)
  }

  isPrivilegeRestricted(privilege: Privilege) {
    return this.userPrivilegeRestrictions?.indexOf(privilege) != -1
  }

  hasPrivilegeValueRestriction(rule: string, value: number, currentUser: User) {
    let percentage = 0
    let amount = this.naturalSellingPrice?.amount ?? 0
    if (amount && amount > 0) {
      percentage = (value / amount) * 100
    }
    let privilege = currentUser.privilegeGroup?.privilegeRules?.find((r) => r.privilege === rule)
    let ruleRestrictions: any[] = privilege?.restrictions
    let clientRest = ruleRestrictions?.find((r) => r.secondParty && r.secondParty.id === this.identity.secondParty.id)
    let generalRest = ruleRestrictions?.find((r) => !r.secondParty)
    if (!clientRest && !generalRest) {
      return true
    } else if (!clientRest && generalRest) {
      return this.transformArrayAndCheckBudgetByPercentage(generalRest.rangeValuesAsString, percentage)
    } else if (clientRest) {
      return this.transformArrayAndCheckBudgetByPercentage(clientRest.rangeValuesAsString, percentage)
    } else {
      return false
    }
  }

  hasPrivilegeValueRestrictionByScopeValue(rule: string, currentUser: User) {
    let amount = this.naturalSellingPrice?.amount ?? 0
    let privilege = currentUser.privilegeGroup?.privilegeRules?.find((r) => r.privilege === rule)
    let ruleRestrictions: any[] = privilege?.restrictions
    let clientRest = ruleRestrictions?.find((r) => r.secondParty && r.secondParty.id === this.identity.secondParty.id)
    let generalRest = ruleRestrictions?.find((r) => !r.secondParty)

    if (!clientRest && !generalRest) {
      return true
    } else if (!clientRest && generalRest) {
      return this.transformArrayAndCheckBudget(generalRest.rangeValues, amount)
    } else if (clientRest) {
      return this.transformArrayAndCheckBudget(clientRest.rangeValues, amount)
    } else {
      return false
    }
  }

  transformArrayAndCheckBudget(array: string[], budget: number): boolean {
    let min = Infinity
    let max = -Infinity
    if (array.length === 0) {
      return true
    }
    array.forEach((item) => {
      const parts = item.split('_').filter((part) => part)
      parts.forEach((part) => {
        let value
        if (part.includes('K')) {
          value = parseInt(part.replace('K', '000'))
        } else if (part.includes('M')) {
          value = parseInt(part.replace('M', '000000'))
        } else {
          value = parseInt(part)
        }
        if (!isNaN(value)) {
          if (value < min) {
            min = value
          }
          if (value > max) {
            max = value
          }
        }
      })
    })
    return budget >= min && budget <= max
  }

  transformArrayAndCheckBudgetByPercentage(rangeValue: string, percentage: number): boolean {
    let min = Infinity
    let max = -Infinity

    const parts = rangeValue.replace('[', '').replace(']', '').replace('%', '').split('-')
    if (parts.length == 2) {
      min = +parts[0]
      max = +parts[1]
    } else {
      return true
    }
    return percentage >= min && percentage <= max
  }

  hasPrivilege(privilege: Privilege, user: User) {
    var hasAdditional = this.hasAdditionalPrivilege(privilege)
    if (hasAdditional) {
      return true
    }

    var hasPrivilege = user.hasPrivilege(privilege)
    if (hasPrivilege) {
      return !this.isPrivilegeRestricted(privilege)
    }
    return false
  }

  hasAdditionalPrivilege(privilege: Privilege) {
    return this.userAdditionalPrivileges?.indexOf(privilege) != -1
  }

  isStateApproved() {
    return this.approved && (this.status == 'AGENCY_APPROVED' || this.status == 'CLIENT_APPROVED')
  }

  isOfSow() {
    return this.scopeOfWorkVersion != null
  }

  isArchived() {
    return this.archived
  }

  isTemplate() {
    return this.identity.isTemplate
  }

  isDraftOrConfigDraft() {
    return [StatusType.DRAFT, StatusType.CONFIG_DRAFT].includes(this.status)
  }

  hasEnoughBudget() {
    if (this.budget) {
      if (this.totalSellingPrice != null) {
        return (this.budget.amount || 0) > (this.totalSellingPrice.amount || 0)
      } else {
        return true
      }
    }
    return false
  }

  removeSection(section: ScopeSection) {
    this.sections = this.sections.filter((s) => s.id != section.id)
  }

  removeDeliverable(deliverable: Deliverable) {
    this.deliverables = this.deliverables.filter((s) => s.id != deliverable.id)
  }

  isScopeByRole() {
    return this.identity.identificationType === 'SCOPE_BY_ROLE'
  }

  isScopeByScenario() {
    return this.identity.identificationType === 'SCOPE_BY_SCENARIO'
  }

  findDeliverableById(id: number): Deliverable {
    return this.deliverables.find((d) => d.id == id)
  }

  totalPercentageComplete(excludeDeliverableId?: number) {
    var totalHours = 0
    var totalPercentage = 0

    this.deliverables
      .filter(isNonTradedDeliverable)
      .filter((d) => excludeDeliverableId == null || excludeDeliverableId !== d.id)
      .forEach((d) => {
        totalHours += d.traffickedActualMinutes || 0
        totalPercentage += (d.traffickedPercentageComplete || 0) * (d.traffickedActualMinutes || 0)
      })

    return totalHours === 0 ? 0 : totalPercentage / totalHours
  }

  getAllComponents() {
    return this.deliverables.flatMap((d) => d.components)
  }

  totalActualHours(excludeDeliverableId?: number) {
    var total = 0

    this.deliverables
      .filter(isNonTradedDeliverable)
      .filter((d) => excludeDeliverableId == null || excludeDeliverableId !== d.id)
      .forEach((d) => {
        total += d.traffickedActualMinutes || 0
      })
    return total / 60
  }

  getMaximumStartDate() {
    if (!this.sections.length && !this.deliverables.length) return this.endDate
    return new Date(
      Math.min(
        ...this.sections.filter((s) => s.startDate).map((s) => s.startDate.getTime()),
        ...this.deliverables.filter((d) => d.startDate).map((d) => d.startDate.getTime())
      )
    )
  }

  getMinimumEndDate() {
    if (!this.sections.length && !this.deliverables.length) return this.startDate
    return new Date(
      Math.max(
        ...this.sections.filter((s) => s.endDate).map((s) => s.endDate.getTime()),
        ...this.deliverables.filter((d) => d.endDate).map((d) => d.endDate.getTime())
      )
    )
  }

  getScopeByRoleTotalMargin = () => {
    let scopeThirdPartyCostSum = this.getScopeThirdPartyCostSum()
    let totalValue = 0
    this.deliverables.forEach((deliverable) => {
      totalValue += deliverable.getTotalSellingPrice().getValue()
    })
    totalValue += scopeThirdPartyCostSum.amount
    if (totalValue == 0) {
      return '-'
    }
    let totalProfit = this.getTotalProfit()
    return ((totalProfit.amount / totalValue) * 100).toFixed(2) + '%'
  }

  findCollaboratorByUserId(userId: number) {
    return this.collaborators.find((collaborator) => collaborator.user.id == userId)
  }
}

export enum StatusType {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  IN_REVIEW = 'IN_REVIEW',
  REVIEWED = 'REVIEWED',
  AGENCY_APPROVED = 'AGENCY_APPROVED',
  CLIENT_APPROVED = 'CLIENT_APPROVED',
  TRAFFICKED = 'TRAFFICKED',
  CLOSED = 'CLOSED',
  SCOPING = 'SCOPING',
  CONFIG_DRAFT = 'CONFIG_DRAFT',
}

export type ScopeStatus =
  | 'DRAFT'
  | 'SUBMITTED'
  | 'REVIEWED'
  | 'AGENCY_APPROVED'
  | 'CLIENT_APPROVED'
  | 'TRAFFICKED'
  | 'CLOSED'
  | 'CONFIG_DRAFT'

export type ScopeStatusName = Record<ScopeStatus, string>

export const scopeStatuses: ScopeStatusName = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  REVIEWED: 'REVIEWED',
  AGENCY_APPROVED: 'AGENCY APPROVED',
  CLIENT_APPROVED: 'CLIENT APPROVED',
  TRAFFICKED: 'TRAFFICKED',
  CLOSED: 'CLOSED',
  CONFIG_DRAFT: 'CONFIG DRAFT',
}

interface ScopeApprovedWebHookInfo {
  [key: string]: any
}

export enum DefaultView {
  table = 'table',
  timeline = 'timeline',
}

export type ScopeConfiguration = { [scenarioCategoryId: number]: ScopeConfigurationCategory }

type ScopeConfigurationCategory = { [scenarioQuestionId: number]: string }
