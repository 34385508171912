import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '@envs/environment';
import { plainToInstance } from 'class-transformer';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';

@Injectable({
  providedIn: 'root',
})
export class ScenarioService {
  private readonly BASE_URL: string = environment.scopeApiUrl + 'scenarios';

  constructor(private http: HttpClient) {
  }

  create(scenarioRequest: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}`, scenarioRequest)
      .pipe(map((scenario) => plainToInstance(Scenario, scenario)));
  }

  getAll(): Observable<Scenario[]> {
    return this.http.get<Scenario[]>(`${this.BASE_URL}`)
      .pipe(map((scenario) => plainToInstance(Scenario, scenario)));
  }

  get(id: number): Observable<Scenario> {
    return this.http.get<Scenario>(`${this.BASE_URL}/${id}`)
      .pipe(map((scenario) => plainToInstance(Scenario, scenario)));
  }

  update(id: number, scenarioRequest: any): Observable<void> {
    return this.http.put<void>(`${this.BASE_URL}/${id}`, scenarioRequest);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.BASE_URL}/${id}`);
  }

}
