import { Action, createReducer, on } from '@ngrx/store';
import { CompanyManagementState } from '../../models/company-management.state.model';
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import {
  CompanyManagementActions
} from '@app/features/company-management/store/actions/company-management.actions';
import { Page } from '@app/features/scoping/models/page.model';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { BootstrapActions } from '@core/store';
import { taskColumnBaseConfig } from '@app/features/scoping/models/scope-columns.model';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import { ScenarioCategory } from '@app/features/scope-overview/model/scenario-category.model';
import { ScenarioQuestion } from '@app/features/scope-overview/model/scenario-question.model';
import { cloneDeep } from 'lodash';

export const initialState: CompanyManagementState = {
  outputTemplates: [],
  loadingOutputTemplates: false,
  loadedOutputTemplates: false,
  groupId: undefined,
  tasks: new Page<Deliverable>(Deliverable),
  taskColumns: taskColumnBaseConfig,
  loadingTasks: false,
  loadedTasks: false,
  scenarios: [],
  loadingScenarios: false,
  loadedScenarios: false,
  scenario: undefined,
  loadingScenario: false,
  loadedScenario: false
};

function getOutputTemplatesHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingOutputTemplates: true,
    loadedOutputTemplates: false,
  };
}

function getOutputTemplatesSuccessHandler(state: CompanyManagementState, action: { outputTemplates: OutputTemplate[] }) {
  return {
    ...state,
    outputTemplates: action.outputTemplates,
    loadingOutputTemplates: false,
    loadedOutputTemplates: true,
  };
}

function getOutputTemplatesFailHandler(state: CompanyManagementState, action: { error: Error }) {
  return {
    ...state,
    loadingOutputTemplates: false,
    loadedOutputTemplates: true,
    error: action.error,
  };
}

function getTasksHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingTasks: true,
    loadedTasks: false,
  };
}

function getTasksSuccessHandler(state: CompanyManagementState, action: { tasks: Page<Deliverable> }) {
  return {
    ...state,
    tasks: action.tasks,
    loadingTasks: false,
    loadedTasks: true,
  };
}

function getTasksFailHandler(state: CompanyManagementState, action: { error: Error }) {
  return {
    ...state,
    loadingTasks: false,
    loadedTasks: true,
    error: action.error,
  };
}

function setGroupIdHandler(state: CompanyManagementState, { groupId }) {
  return {
    ...state,
    groupId
  };
}

function loadTaskPreferencesSuccessHandler(state: CompanyManagementState, action: any) {
  return {
    ...state,
    taskColumns: {
      ...state.taskColumns,
      ...action.preferences
    }
  }
}

function updateTaskColumnsHandler(state: CompanyManagementState, action: any) {
  const { preference } = action

  return {
    ...state,
    taskColumns: {
      ...state.taskColumns,
      [preference.key]: preference,
    },
  }
}

function getScenariosHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarios: true,
    loadedScenarios: false,
  };
}

function getScenariosSuccessHandler(state: CompanyManagementState, action: { scenarios: Scenario[] }) {
  return {
    ...state,
    scenarios: action.scenarios,
    loadingScenarios: false,
    loadedScenarios: true,
  };
}

function getScenariosFailHandler(state: CompanyManagementState, action: { error: Error }) {
  return {
    ...state,
    loadingScenarios: false,
    loadedScenarios: true,
    error: action.error,
  };
}

function createScenarioHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarios: true,
  };
}

function createScenarioSuccessHandler(state: CompanyManagementState, action: any) {
  return {
    ...state,
    scenarios: [...state.scenarios, action.scenario],
    loadingScenarios: false,
  };
}

function createScenarioFailHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarios: false,
  };
}

function deleteScenarioHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarios: true,
  };
}

function deleteScenarioSuccessHandler(state: CompanyManagementState, action: any) {
  return {
    ...state,
    scenarios: state.scenarios.filter((s) => s.id !== action.id),
    loadingScenarios: false,
  };
}

function deleteScenarioFailHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarios: false,
  };
}

function updateScenarioHandler(state: CompanyManagementState, action: { scenario: Scenario }) {
  return {
    ...state,
    scenario: action.scenario,
    loadingScenarios: true,
  };
}

function updateScenarioSuccessHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarios: false,
  };
}

function updateScenarioFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingScenarios: false,
    error: action.error,
  };
}

function getScenarioHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenario: true,
    loadedScenario: false,
  };
}

function getScenarioSuccessHandler(state: CompanyManagementState, action: { scenario: Scenario }) {
  return {
    ...state,
    scenario: action.scenario,
    loadingScenario: false,
    loadedScenario: true,
  };
}

function getScenarioFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingScenario: false,
    loadedScenario: true,
    error: action.error,
  };
}

function createScenarioCategoryHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenario: true,
  };
}

function createScenarioCategorySuccessHandler(state: CompanyManagementState, action: { category: ScenarioCategory }) {
  return {
    ...state,
    scenario: {...state.scenario, categories: [...state.scenario.categories, action.category]},
    loadingScenario: false,
  };
}

function createScenarioCategoryFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingScenario: false,
    error: action.error,
  };
}

function deleteScenarioCategoryHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenario: true,
  };
}

function deleteScenarioCategorySuccessHandler(state: CompanyManagementState, action: { categoryId: number }) {
  return {
    ...state,
    scenario: {...state.scenario, categories: state.scenario.categories.filter(category => category.id !== action.categoryId)},
    loadingScenario: false,
  };
}

function deleteScenarioCategoryFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingScenario: false,
    error: action.error,
  };
}

function reorderScenarioCategoriesHandler(state: CompanyManagementState, action: { scenarioId, orderedCategoryIds }) {
  let categories = cloneDeep(state.scenario.categories)
  action.orderedCategoryIds.forEach((id, index) => {
    categories.find((q) => q.id == id).orderIndex = index
  })
  return {
    ...state,
    scenario: { ...state.scenario, categories: categories }
  };
}

function updateScenarioCategoryHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenario: true,
  };
}

function updateScenarioCategorySuccessHandler(state: CompanyManagementState, action: { category: ScenarioCategory }) {
  return {
    ...state,
    scenario: {...state.scenario, categories: state.scenario.categories.map(category =>
        category.id === action.category.id ? action.category : category)},
    loadingScenario: false,
  };
}

function updateScenarioCategoryFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingScenario: false,
    error: action.error,
  };
}

function getScenarioQuestionsHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarioQuestions: true,
    loadedScenarioQuestions: false,
  };
}

function getScenarioQuestionsSuccessHandler(state: CompanyManagementState, action: { categoryId: number, scenarioQuestions: ScenarioQuestion[] }) {
  return {
    ...state,
    scenario: { ...state.scenario, categories: state.scenario.categories
        .map((c) => c.id === action.categoryId ? { ...c, questions: action.scenarioQuestions} : c) },
    loadingScenarioQuestions: false,
    loadedScenarioQuestions: true,
  };
}

function getScenarioQuestionsFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingScenarioQuestions: false,
    loadedScenarioQuestions: true,
    error: action.error,
  };
}

function createScenarioQuestionHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarioQuestions: true,
  };
}

function createScenarioQuestionSuccessHandler(state: CompanyManagementState, action: { categoryId: number, question: ScenarioQuestion }) {
  return {
    ...state,
    scenario: { ...state.scenario, categories: state.scenario.categories
        .map((c) => c.id === action.categoryId ? { ...c, questions: [...c.questions, action.question]} : c) },
    loadingScenarioQuestions: false,
  };
}

function createScenarioQuestionFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingScenarioQuestions: false,
    error: action.error,
  };
}

function deleteScenarioQuestionHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarioQuestions: true,
  };
}

function deleteScenarioQuestionSuccessHandler(state: CompanyManagementState, action: { categoryId: number, questionId: number }) {
  return {
    ...state,
    scenario: { ...state.scenario, categories: state.scenario.categories
        .map((c) => c.id === action.categoryId ? { ...c, questions: c.questions.filter((q) => q.id !== action.questionId)} : c) },
    loadingScenarioQuestions: false,
  };
}

function deleteScenarioQuestionFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingScenarioQuestions: false,
    error: action.error,
  };
}

function reorderScenarioQuestionsHandler(state: CompanyManagementState, action: { scenarioId, categoryId, orderedQuestionIds }) {
  let questions = cloneDeep(state.scenario.categories.find((c) => c.id == action.categoryId).questions)
  action.orderedQuestionIds.forEach((id, index) => {
    questions.find((q) => q.id == id).orderIndex = index
  })
  return {
    ...state,scenario: { ...state.scenario, categories: state.scenario.categories
        .map((c) => c.id === action.categoryId ? { ...c, questions: questions} : c) },
  };
}

function updateScenarioQuestionHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarioQuestions: true,
  };
}

function updateScenarioQuestionSuccessHandler(state: CompanyManagementState, action: { categoryId: number, question: ScenarioQuestion }) {
  return {
    ...state,
    scenario: { ...state.scenario, categories: state.scenario.categories
        .map((c) => c.id === action.categoryId ?
          { ...c, questions: c.questions.map((q) => q.id === action.question.id ? action.question : q)} :
          c) },
    loadingScenarioQuestions: false,
  };
}

function updateScenarioQuestionFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingScenarioQuestions: false,
    error: action.error,
  };
}

export const CompanyManagementReducer = createReducer(
  initialState,
  on(CompanyManagementActions.getOutputTemplates, getOutputTemplatesHandler),
  on(CompanyManagementActions.getOutputTemplatesSuccess, getOutputTemplatesSuccessHandler),
  on(CompanyManagementActions.getOutputTemplatesFail, getOutputTemplatesFailHandler),
  on(CompanyManagementActions.getTasks, getTasksHandler),
  on(CompanyManagementActions.getTasksSuccess, getTasksSuccessHandler),
  on(CompanyManagementActions.getTasksFail, getTasksFailHandler),
  on(CompanyManagementActions.setGroupId, setGroupIdHandler),
  on(CompanyManagementActions.getScenarios, getScenariosHandler),
  on(CompanyManagementActions.getScenariosSuccess, getScenariosSuccessHandler),
  on(CompanyManagementActions.getScenariosFail, getScenariosFailHandler),
  on(CompanyManagementActions.createScenario, createScenarioHandler),
  on(CompanyManagementActions.createScenarioSuccess, createScenarioSuccessHandler),
  on(CompanyManagementActions.createScenarioFail, createScenarioFailHandler),
  on(CompanyManagementActions.deleteScenario, deleteScenarioHandler),
  on(CompanyManagementActions.deleteScenarioSuccess, deleteScenarioSuccessHandler),
  on(CompanyManagementActions.deleteScenarioFail, deleteScenarioFailHandler),
  on(CompanyManagementActions.updateScenario, updateScenarioHandler),
  on(CompanyManagementActions.updateScenarioSuccess, updateScenarioSuccessHandler),
  on(CompanyManagementActions.updateScenarioFail, updateScenarioFailHandler),
  on(CompanyManagementActions.getScenario, getScenarioHandler),
  on(CompanyManagementActions.getScenarioSuccess, getScenarioSuccessHandler),
  on(CompanyManagementActions.getScenarioFail, getScenarioFailHandler),
  on(CompanyManagementActions.createScenarioCategory, createScenarioCategoryHandler),
  on(CompanyManagementActions.createScenarioCategorySuccess, createScenarioCategorySuccessHandler),
  on(CompanyManagementActions.createScenarioCategoryFail, createScenarioCategoryFailHandler),
  on(CompanyManagementActions.deleteScenarioCategory, deleteScenarioCategoryHandler),
  on(CompanyManagementActions.deleteScenarioCategorySuccess, deleteScenarioCategorySuccessHandler),
  on(CompanyManagementActions.deleteScenarioCategoryFail, deleteScenarioCategoryFailHandler),
  on(CompanyManagementActions.reorderScenarioCategories, reorderScenarioCategoriesHandler),
  on(CompanyManagementActions.updateScenarioCategory, updateScenarioCategoryHandler),
  on(CompanyManagementActions.updateScenarioCategorySuccess, updateScenarioCategorySuccessHandler),
  on(CompanyManagementActions.updateScenarioCategoryFail, updateScenarioCategoryFailHandler),
  on(CompanyManagementActions.getScenarioQuestions, getScenarioQuestionsHandler),
  on(CompanyManagementActions.getScenarioQuestionsSuccess, getScenarioQuestionsSuccessHandler),
  on(CompanyManagementActions.getScenarioQuestionsFail, getScenarioQuestionsFailHandler),
  on(CompanyManagementActions.createScenarioQuestion, createScenarioQuestionHandler),
  on(CompanyManagementActions.createScenarioQuestionSuccess, createScenarioQuestionSuccessHandler),
  on(CompanyManagementActions.createScenarioQuestionFail, createScenarioQuestionFailHandler),
  on(CompanyManagementActions.deleteScenarioQuestion, deleteScenarioQuestionHandler),
  on(CompanyManagementActions.deleteScenarioQuestionSuccess, deleteScenarioQuestionSuccessHandler),
  on(CompanyManagementActions.deleteScenarioQuestionFail, deleteScenarioQuestionFailHandler),
  on(CompanyManagementActions.reorderScenarioQuestions, reorderScenarioQuestionsHandler),
  on(CompanyManagementActions.updateScenarioQuestion, updateScenarioQuestionHandler),
  on(CompanyManagementActions.updateScenarioQuestionSuccess, updateScenarioQuestionSuccessHandler),
  on(CompanyManagementActions.updateScenarioQuestionFail, updateScenarioQuestionFailHandler),
  on(BootstrapActions.loadTaskPreferencesSuccess, loadTaskPreferencesSuccessHandler),
  on(BootstrapActions.updateTaskColumnPreferences, updateTaskColumnsHandler),
);

export function reducer(state: CompanyManagementState | undefined, action: Action): CompanyManagementState {
  return CompanyManagementReducer(state, action);
}
