import { Injectable } from '@angular/core';
import { MappedEntity } from "@app/features/scoping/models/mapped-entity.model";
import { FolderVersion } from "@core/model/folder-version.model";
import { ScopeVersion } from "@core/model/scope-version";
import { Preference } from "@core/model/user-preferences.interface";
import { AuthService } from '@core/service/auth.service'
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { of } from 'rxjs';
import { TableColumnPreferences } from '@app/shared/components/ui-components/scope-ui-table/table-column-preferences.type';
import { TableColumnKey } from '@app/shared/components/ui-components/scope-ui-table/table-column-key.enum';
import { plainToInstance } from 'class-transformer';

@Injectable({
  providedIn: 'root',
})
export class DataMappingService {
  constructor(private authService: AuthService) {}

  getValueByPath(obj: any, path: string): any {
    let value = path.split('.').reduce((acc, part) => acc && acc[part], obj);
    return value != null ? value : '-';
  }

  private mapScope(data: ScopeVersion, columnMapping: Preference[], children?: Deliverable[]): MappedEntity<ScopeVersion> {
    const scopeNumber = data?.identity?.localId;
    const scopeString = scopeNumber ? `S-${scopeNumber}` : undefined;

    return Object.values(columnMapping).reduce((newObj, config) => {
      if (config.selected) {
        if (config.field === 'identity.localId' && scopeString) {
          newObj[config.key] = scopeString;
        } else {
          if ((!config.requiredPrivilege || data.hasPrivilege(config.requiredPrivilege, this.authService.loggedInUser!)) && (!config.isVisible || config.isVisible(data))) {
            newObj[config.key] = config.value ? config.value(data) : this.getValueByPath(data, config.field || '');
          } else {
            newObj[config.key] = config.default;
          }
        }
      }
      return newObj;
    }, {
      entity: data,
      children$: children?.length ? of(children.map(d => plainToInstance(Deliverable, {
        ...d,
        scope: {
          ...d.scope,
          identity: data.identity,
          isEditable: data.isEditable,
        }
      }))) : undefined
    } as MappedEntity<ScopeVersion>);
  }

  private mapFolder(data: FolderVersion, columnMapping: Preference[], children: ScopeVersion[] | MappedEntity<ScopeVersion>[]): MappedEntity<FolderVersion> {
    const folderNumber = data?.identity?.localId;
    const folderString = folderNumber ? `F-${folderNumber}` : undefined;

    return Object.values(columnMapping).reduce((newObj, config) => {
      if (config.selected) {
        if (config.field === 'identity.localId' && folderString) {
          newObj[config.key] = folderString;
        } else if (config.key === 'BUDGET') {
          newObj[config.key] = this.getValueByPath(data, 'budgets');
        } else if (config.key === 'VALUE'){
          newObj[config.key] = this.getValueByPath(data, 'totalValues');
        } else {
          newObj[config.key] = config.value ? config.value(data) : this.getValueByPath(data, config.field || '');
        }
      }
      return newObj;
    }, {
      entity: data,
      children$: children?.length ? of(children) : undefined
    } as MappedEntity<FolderVersion>);
  }

  private mapObject<T>(data: T, columnMapping: Preference[], childrenToMap?: string): MappedEntity<T> {
    return Object.values(columnMapping).reduce((newObj, config) => {
      if (config.selected) {
        newObj[config.key] = config.value ? config.value(data) : this.getValueByPath(data, config.field || '');
      }
      return newObj;
    }, {
      entity: data,
      id: data["id"],
      children: childrenToMap ? this.transformArray(data[childrenToMap], columnMapping) : null
    } as MappedEntity<T>);
  }

  transformScopeArray(
    data: ScopeVersion[] = [],
    columnMapping: Preference[],
    deliverablesByScope?: { [key: number]: Deliverable[] }
  ): MappedEntity<ScopeVersion>[] {
    return data.map((obj) => this.mapScope(obj, columnMapping, deliverablesByScope?.[obj.identity.id]))
  }

  transformFolderArray(
    data: FolderVersion[] = [],
    columnMapping: Preference[],
    foldersByScope: { [key: number]: ScopeVersion[] },
    scopeColumns: Preference[]
  ): MappedEntity<FolderVersion>[] {
    return data.map((obj) => this.mapFolder(
      obj,
      columnMapping,
      this.transformScopeArray(foldersByScope[obj.identity.id], scopeColumns)
    ))
  }

  transformArray<T>(data: T[] = [], columnMapping: Preference[], childrenToMap?: string): MappedEntity<T>[] {
    return data.map((obj) => this.mapObject<T>(obj, columnMapping, childrenToMap))
  }

  mergePreferencesWithTableConfig(baseConfig: TableColumnPreferences, userPreferences: TableColumnPreferences): TableColumnPreferences {
    let mergedConfig: TableColumnPreferences = {...baseConfig}

    Object.keys(baseConfig).forEach((key) => {
      const scopedKey = key as TableColumnKey
      if (userPreferences?.[scopedKey]) {
        mergedConfig[scopedKey] = { ...baseConfig[scopedKey], selected: userPreferences[scopedKey].selected }
      }
    })

    return mergedConfig
  }

  mergeDeliverablePreferencesWithTableConfig(baseConfig: TableColumnPreferences, userPreferences: TableColumnPreferences): TableColumnPreferences {
    let mergedConfig: TableColumnPreferences = {...baseConfig}

    Object.keys(baseConfig).forEach((key) => {
      const scopedKey = key as TableColumnKey
      if (userPreferences?.[scopedKey]) {
        mergedConfig[scopedKey] = { ...baseConfig[scopedKey], selected: userPreferences[scopedKey].selected }
      }
    })

    return mergedConfig
  }

  mergeTaskPreferencesWithTableConfig(baseConfig: TableColumnPreferences, userPreferences: TableColumnPreferences): TableColumnPreferences {
    let mergedConfig: TableColumnPreferences = {...baseConfig}

    Object.keys(baseConfig).forEach((key) => {
      if (userPreferences?.[key]) {
        mergedConfig[key] = { ...baseConfig[key], selected: userPreferences[key].selected }
      }
    })

    return mergedConfig
  }
}
