<div class='maximum-height'>
  <div mat-dialog-title>
    <div class='modal__header'>
      <div class='modal__title title-width'>
        <h1>{{ data.title }}</h1>
      </div>
      <div class='modal-text mt-2'>
      </div>
      <button class='close-button' mat-dialog-close>
        <i class='material-symbols-rounded'>close</i>
      </button>
    </div>
  </div>
  <mat-dialog-content aria-label='Generic modal' class='modal-container  md-dialog-container'>
    <div class='pt-6'>
      <scope-ui-input
        class='resize-none roles-search'
        [initialValue]='data.scopeDynamicFieldSetting ? data.scopeDynamicFieldSetting.displayName: null'
        [inputLabel]="'Field name'"
        [inputClass]="'mx-9 icon-field'"
        [control]='fieldNameControl'
        [required]='true'
      />
      <scope-ui-input
        class='resize-none roles-search'
        [initialValue]='data.scopeDynamicFieldSetting ? data.scopeDynamicFieldSetting.name: null'
        [inputLabel]="'Field Json Key'"
        [inputClass]="'mx-8'"
        [control]='fieldJsonKeyControl'
        [isDisabled]='true'
      />
      <div class='mx-8 mb-5 mt-1'>
        <scope-ui-dropdown
          class='roles-search'
          [selectedValue]='data.scopeDynamicFieldSetting? {name: data.scopeDynamicFieldSetting.type, id: data.scopeDynamicFieldSetting.type} : null'
          [controlLabel]="'Field Type'"
          [dropdownOptions]='dynamicFieldTypeDropdownOptions'
          [isStandardDropdown]='true'
          (onSelectionChange)='onSelectionDynamicFieldType($event)'
        >
        </scope-ui-dropdown>
        <div class='flex gap-9 price-menu'>
          <div class='desc-menu'>
            <scope-ui-dropdown
              class='roles-search'
              [selectedValue]='data.scopeDynamicFieldSetting ? selectedCompanyApiIntegration : null'
              [controlLabel]="'Company Integration'"
              [dropdownOptions]='companyApiIntegrationsDropdownOptions'
              [isStandardDropdown]='true'
              (onSelectionChange)='onSelectionCompanyApiIntegration($event)'
              *ngIf='isDropdownExternal()'
            ></scope-ui-dropdown>
            <scope-ui-input
              [initialValue]='data.scopeDynamicFieldSetting ? data.scopeDynamicFieldSetting.externalSystemResourceUri: null'
              [inputLabel]="'Resource Uri'"
              [required]='true'
              [control]='resourceControl'
              [fetchIcon]="'arrow_circle_down '"
              (onFetch)='fetchExternalData()'
              *ngIf='data.scopeDynamicFieldSetting?.companyApiIntegrationId && isDropdownExternal()'
            />
            <scope-ui-input
              [initialValue]='data.scopeDynamicFieldSetting ? data.scopeDynamicFieldSetting.jsonValueName: null'
              [inputLabel]="'Json Value Name'"
              [required]='true'
              [control]='jsonValueNameControl'
              *ngIf='data.scopeDynamicFieldSetting?.companyApiIntegrationId && jsonNode'
            />
            <div *ngIf='jsonValueNameControl.errors?.invalidValue'>
              <mat-error>{{jsonValueNameControl.errors?.message}}</mat-error>
            </div>
            <scope-ui-input
              [initialValue]='data.scopeDynamicFieldSetting ? data.scopeDynamicFieldSetting.value : null'
              [inputLabel]="'Value'"
              [isTextarea]='true'
              [control]='valueControl'
              [textareaRows]='5'
              *ngIf="data.scopeDynamicFieldSetting?.type &&
                      data.scopeDynamicFieldSetting?.type === 'DROPDOWN'"
            />
            <scope-ui-input
              [initialValue]='data.scopeDynamicFieldSetting ? data.scopeDynamicFieldSetting.value : null'
              [inputLabel]="'Value'"
              [control]='valueControl'
              *ngIf="data.scopeDynamicFieldSetting?.type &&
                      data.scopeDynamicFieldSetting?.type === 'TEXT'"
            />
            <loader-modal *ngIf='isFetchingExternalData'></loader-modal>
            <div class='json-container' *ngIf='jsonNode' (click)='onJsonKeyClick($event)'>
              <ngx-json-viewer [json]='jsonNode'></ngx-json-viewer>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='flex justify-between p-4'>
      <div class='role-actions'>
        <button mat-button mat-dialog-close>Cancel</button>
        <button class='main-action-button ml-4' [ngClass]="{'disabled': disableSubmit()}" mat-button
                [disabled]='disableSubmit()' (click)='onSubmit()'>
          {{ data.submitButtonName }}
        </button>
      </div>
    </div>
  </mat-dialog-content>
</div>
