<scope-ui-table
  *ngIf="mappedDepartmentsDataSource"
  class="departments-table-wrapper"
  scroll-siblings
  [target-class]="'departments-table-wrapper'"
  [tableClass]="'departments-table overview-table'"
  [rowGaps]="true"
  [includeEmptyMessage]="false"
  [loggedInUser]='currentUser'
  [data]="mappedDepartmentsDataSource"
  [displayedColumns]="departmentColumns"
  [noPagination]="true"
  [noPreferences]="true"
  [showToggleMenu]="false"
  [expandable]="true"
  [condensed]="true"
  [showExpandAll]="true"
  [tableExpanded]="tableExpanded"
  [expandedElements]="scopeTabService.departmentSelectedStates$ | async"
  [useSourceId]="true"
  [stickyHeader]="true"
  [optimise]="true"
  (expandedElementsChange)="scopeTabService.updateDepartmentSelectedStates($event)"
  (tableExpandedChange)="tableExpandedChange.emit($event)"
  (onSort)="departmentSortChange.emit($event)">
  <ng-template let-element let-sort="sort">
    <scope-ui-table
      [tableClass]="'roles-table nested'"
      [loggedInUser]='currentUser'
      [data]="element.children"
      [displayedColumns]="departmentColumns"
      [noPagination]="true"
      [noPreferences]="true"
      [showToggleMenu]="false"
      [expandable]="false"
      [hideHeader]="true"
      [sort]="sort"
      [condensed]="true"
      [optimise]="true"/>
  </ng-template>
</scope-ui-table>
