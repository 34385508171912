import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation, forwardRef } from '@angular/core'
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms'

import { CommonModule } from '@angular/common'
import { SharedModule } from '@app/shared/shared.module'
import { noop } from 'rxjs'

@Component({
  selector: 'scope-ui-checkbox',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './scope-ui-checkbox.component.html',
  styleUrls: ['./scope-ui-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ScopeUiCheckboxComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScopeUiCheckboxComponent implements ControlValueAccessor {
  @Input() title: string
  @Input() control: FormControl = new FormControl()
  @Input() disableUncheck = false

  @Input() set isDisabled(value: boolean) {
    value ? this.control.disable() : this.control.enable()
  }

  private _value: boolean
  get value(): boolean {
    return this._value
  }
  set value(v: boolean) {
    if (v !== this._value) {
      this._value = v
      this.change(v)
    }
  }

  writeValue(value: boolean) {
    if (value !== this._value) this._value = value
  }

  onChange: Function = noop
  onTouched: Function = noop
  registerOnChange = (fn: Function) => (this.onChange = fn)
  registerOnTouched = (fn: Function) => (this.onTouched = fn)

  change(value: boolean) {
    this._value = value
    this.onChange(value)
    this.onTouched(value)
  }

  updateValue(event: boolean) {
    if (this.disableUncheck && !event) {
      event = true
      this.control.setValue(true)
    }
    this.value = event
  }
}
