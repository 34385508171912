import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '@envs/environment';
import { plainToInstance } from 'class-transformer';
import { ScenarioCategory } from '@app/features/scope-overview/model/scenario-category.model';

@Injectable({
  providedIn: 'root',
})
export class ScenarioCategoryService {
  private readonly BASE_URL: string = environment.scopeApiUrl + 'scenarios';

  constructor(private http: HttpClient) {
  }

  create(scenarioId: number, categoryRequest: any): Observable<ScenarioCategory> {
    return this.http.post<ScenarioCategory>(`${this.BASE_URL}/${scenarioId}/categories`, categoryRequest)
      .pipe(map((category) => plainToInstance(ScenarioCategory, category)));
  }

  getAll(scenarioId: number): Observable<ScenarioCategory[]> {
    return this.http.get<ScenarioCategory[]>(`${this.BASE_URL}/${scenarioId}/categories`)
      .pipe(map((categories) => plainToInstance(ScenarioCategory, categories)));
  }

  get(scenarioId: number, categoryId: number): Observable<ScenarioCategory> {
    return this.http.get<ScenarioCategory>(`${this.BASE_URL}/${scenarioId}/categories/${categoryId}`)
      .pipe(map((category) => plainToInstance(ScenarioCategory, category)));
  }

  update(scenarioId: number, categoryId: number, categoryRequest: any): Observable<ScenarioCategory> {
    return this.http.put<void>(`${this.BASE_URL}/${scenarioId}/categories/${categoryId}`, categoryRequest)
      .pipe(map((category) => plainToInstance(ScenarioCategory, category)));
  }

  delete(scenarioId: number, categoryId: number): Observable<void> {
    return this.http.delete<void>(`${this.BASE_URL}/${scenarioId}/categories/${categoryId}`);
  }

  reorder(scenarioId: number, orderedCategoryIds: number[]): Observable<void> {
    return this.http.put<void>(`${this.BASE_URL}/${scenarioId}/categories/reorder`, orderedCategoryIds);
  }
}
