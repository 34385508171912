
export enum Privilege {
  /* company Privileges */
  COMPANY_DETAILS__VIEW = 'COMPANY_DETAILS__VIEW',
  COMPANY_DETAILS__EDIT = 'COMPANY_DETAILS__EDIT',
  COMPANY_CHILD_ACCOUNT_EDIT = 'COMPANY_CHILD_ACCOUNT_EDIT',

  /* GROUP */
  COMPANY_GROUP__VIEW = 'COMPANY_GROUP__VIEW',
  COMPANY_GROUP__CREATE = 'COMPANY_GROUP__CREATE',
  COMPANY_GROUP__EDIT = 'COMPANY_GROUP__EDIT',
  COMPANY_GROUP__SHARE = 'COMPANY_GROUP__SHARE',
  COMPANY_GROUP__DELETE = 'COMPANY_GROUP__DELETE',
  COMPANY_GROUP__MANAGE_PERMISSIONS = 'COMPANY_GROUP__MANAGE_PERMISSIONS',
  COMPANY_GROUP__MEMBER__INVITE = 'COMPANY_GROUP__MEMBER__INVITE',
  COMPANY_GROUP__MEMBER__DELETE = 'COMPANY_GROUP__MEMBER__DELETE',

  COMPANY_TRAFFIC_SYSTEM__VIEW = 'COMPANY_TRAFFIC_SYSTEM__VIEW',
  COMPANY_TRAFFIC_SYSTEM__EDIT = 'COMPANY_TRAFFIC_SYSTEM__EDIT',
  COMPANY_TRAFFIC_SYSTEM_CUSTOM__EDIT = 'COMPANY_TRAFFIC_SYSTEM_CUSTOM__EDIT',
  COMPANY_TRAFFIC_SYSTEM__IMPORT = 'COMPANY_TRAFFIC_SYSTEM__IMPORT',

  COMPANY_PREDICTION_DATA__VIEW = 'COMPANY_PREDICTION_DATA__VIEW',
  COMPANY_APPLICATION_SETTINGS__VIEW = 'COMPANY_APPLICATION_SETTINGS__VIEW',
  COMPANY_APPLICATION_SETTINGS__EDIT = 'COMPANY_APPLICATION_SETTINGS__EDIT',

  COMPANY_SAML_INTEGRATION__EDIT = 'COMPANY_SAML_INTEGRATION__EDIT',

  /* company MEMBERS Privileges */
  COMPANY_MEMBERS__VIEW = 'COMPANY_MEMBERS__VIEW',
  COMPANY_MEMBERS__EDIT = 'COMPANY_MEMBERS__EDIT',
  COMPANY_MEMBERS_PRIVILEGES__VIEW = 'COMPANY_MEMBERS_PRIVILEGES__VIEW',
  COMPANY_MEMBERS_PRIVILEGES__EDIT = 'COMPANY_MEMBERS_PRIVILEGES__EDIT',
  COMPANY_MEMBERS_ROLE_LEVEL__EDIT = 'COMPANY_MEMBERS_ROLE_LEVEL__EDIT',
  COMPANY_PRIVILEGES_GROUP__VIEW = 'COMPANY_PRIVILEGES_GROUP__VIEW',
  COMPANY_PRIVILEGES_GROUP__CREATE = 'COMPANY_PRIVILEGES_GROUP__CREATE',
  COMPANY_PRIVILEGES_GROUP__EDIT = 'COMPANY_PRIVILEGES_GROUP__EDIT',
  COMPANY_PRIVILEGES_GROUP__DELETE = 'COMPANY_PRIVILEGES_GROUP__DELETE',
  COMPANY_MEMBERS__DELETE = 'COMPANY_MEMBERS__DELETE',
  COMPANY_MEMBERS__INVITE = 'COMPANY_MEMBERS__INVITE',
  COMPANY_MEMBERS__SUSPEND = 'COMPANY_MEMBERS__SUSPEND',

  /* company Notification Privileges */
  COMPANY_NOTIFICATION_PROFILE__CREATE = 'COMPANY_NOTIFICATION_PROFILE__CREATE',
  COMPANY_NOTIFICATION_PROFILE__EDIT = 'COMPANY_NOTIFICATION_PROFILE__EDIT',
  COMPANY_NOTIFICATION_PROFILE__VIEW = 'COMPANY_NOTIFICATION_PROFILE__VIEW',

  /* SCOPE Type Privileges */
  SCOPE_TYPE__VIEW = 'SCOPE_TYPE__VIEW',
  SCOPE_TYPE__EDIT = 'SCOPE_TYPE__EDIT',
  SCOPE_TYPE__CREATE = 'SCOPE_TYPE__CREATE',
  SCOPE_TYPE__DELETE = 'SCOPE_TYPE__DELETE',

  /* SOW Privileges */
  SOW__VIEW = 'SOW__VIEW',
  SOW__ARCHIVE = 'SOW__ARCHIVE',
  SOW__EDIT = 'SOW__EDIT',
  SOW__CREATE = 'SOW__CREATE',
  SOW__DELETE = 'SOW__DELETE',
  SOW_COMMENT__CREATE = 'SOW_COMMENT__CREATE',
  SOW_TIMESHEET__UPLOAD = 'SOW_TIMESHEET__UPLOAD',

  /* SCOPE Privileges */
  SCOPE__VIEW = 'SCOPE__VIEW',
  SCOPE__CREATE = 'SCOPE__CREATE',
  SCOPE__EDIT = 'SCOPE__EDIT',
  SCOPE__DELETE = 'SCOPE__DELETE',
  SCOPE__ARCHIVE = 'SCOPE__ARCHIVE',
  SCOPE_COMMENT__CREATE = 'SCOPE_COMMENT__CREATE',
  SCOPE_COMPONENT_RESOURCES__DELETE = 'SCOPE_COMPONENT_RESOURCES__DELETE',
  SCOPE_COMPONENT_ROLES_HOURS_EDIT = 'SCOPE_COMPONENT_ROLES_HOURS_EDIT',
  SCOPE__VIEW_RATES = 'SCOPE__VIEW_RATES',
  SCOPE__EXPORT_CSV = 'SCOPE__EXPORT_CSV',
  SCOPE__EXPORT_DOCX = 'SCOPE__EXPORT_DOCX',
  SCOPE__EXPORT_PDF = 'SCOPE__EXPORT_PDF',
  SCOPE__EXPORT_DOCU_SIGN = 'SCOPE__EXPORT_DOCU_SIGN',
  SCOPE__EXPORT_ADOBE_SIGN = 'SCOPE__EXPORT_ADOBE_SIGN',
  DOCUSIGN_VIEW__ACTIVITIES = 'DOCUSIGN_VIEW__ACTIVITIES',
  SCOPE__EXPORT_XLSX = 'SCOPE__EXPORT_XLSX',
  SCOPE_DISCOUNT__VIEW = 'SCOPE_DISCOUNT__VIEW',
  SCOPE_DISCOUNT__EDIT = 'SCOPE_DISCOUNT__EDIT',
  SCOPE_MARKUP__VIEW = 'SCOPE_MARKUP__VIEW',
  SCOPE_MARGIN__VIEW = 'SCOPE_MARGIN__VIEW',
  SCOPE_MARKUP__EDIT = 'SCOPE_MARKUP__EDIT',
  SCOPE_ROLE__SUBSTITUTE_AND_UNMAP = 'SCOPE_ROLE__SUBSTITUTE_AND_UNMAP',
  SCOPE_DELIVERABLE__COMPLETE = 'SCOPE_DELIVERABLE__COMPLETE',
  SCOPE_DELIVERABLE__STOP = 'SCOPE_DELIVERABLE__STOP',
  SCOPE_DELIVERABLE__TRADE = 'SCOPE_DELIVERABLE__TRADE',
  SCOPE_FIXED_COST_DELIVERABLE__EDIT = 'SCOPE_FIXED_COST_DELIVERABLE__EDIT',
  SCOPE_FIXED_COST_COMPONENT__EDIT = 'SCOPE_FIXED_COST_COMPONENT__EDIT',
  SCOPE_REVIEW__EDIT_ROLE_HOURS = 'SCOPE_REVIEW__EDIT_ROLE_HOURS',
  SCOPE_REVIEW__EDIT_COMPONENT_QUANTITY = 'SCOPE_REVIEW__EDIT_COMPONENT_QUANTITY',

  SCOPE_DELIVERABLE_COMPONENT__VIEW_ROLES = 'SCOPE_DELIVERABLE_COMPONENT__VIEW_ROLES',
  SCOPE_DELIVERABLE_COMPONENT__ADD_EDIT_ROLES = 'SCOPE_DELIVERABLE_COMPONENT__ADD_EDIT_ROLES',
  SCOPE_TEMPLATE__CREATE = 'SCOPE_TEMPLATE__CREATE',
  SCOPE_TEMPLATE__EDIT = 'SCOPE_TEMPLATE__EDIT',
  SCOPE_TEMPLATE__DELETE = 'SCOPE_TEMPLATE__DELETE',

  SCOPE_ACTUALS__VIEW = 'SCOPE_ACTUALS__VIEW',

  SCOPE__SUBMIT = 'SCOPE__SUBMIT',
  SCOPE__SUBMIT_OVERRIDE = 'SCOPE__SUBMIT_OVERRIDE',
  SCOPE__REVIEW = 'SCOPE__REVIEW',
  SCOPE__REVIEW_OVERRIDE = 'SCOPE__REVIEW_OVERRIDE',
  SCOPE__APPROVE = 'SCOPE__APPROVE',
  SCOPE__APPROVE_OVERRIDE = 'SCOPE__APPROVE_OVERRIDE',
  SCOPE__TRAFFIC = 'SCOPE__TRAFFIC',
  SCOPE__CLOSE = 'SCOPE__CLOSE',

  SCOPE__CREATE_FEES = 'SCOPE__CREATE_FEES',
  SCOPE__VIEW_FEES = 'SCOPE__VIEW_FEES',
  SCOPE__UPDATE_FEES = 'SCOPE__UPDATE_FEES',
  SCOPE__DELETE_FEES = 'SCOPE__DELETE_FEES',

  /* SECOND PARTY Privileges */
  SECOND_PARTY__VIEW = 'SECOND_PARTY__VIEW',
  SECOND_PARTY__CREATE = 'SECOND_PARTY__CREATE',
  SECOND_PARTY__EDIT = 'SECOND_PARTY__EDIT',
  SECOND_PARTY__DELETE = 'SECOND_PARTY__DELETE',

  /* RATECARD Privileges */
  RATECARD__VIEW = 'RATECARD__VIEW',
  RATECARD__CREATE = 'RATECARD__CREATE',
  RATECARD__EDIT = 'RATECARD__EDIT',
  RATECARD_ROLE_COST__VIEW = 'RATECARD_ROLE_COST__VIEW',
  RATECARD__DELETE = 'RATECARD__DELETE',
  RATECARD_LOCATION__EDIT = 'RATECARD_LOCATION__EDIT',

  /* RATECARD STRUCTURE Privileges */
  RATECARD_STRUCTURE__VIEW = 'RATECARD_STRUCTURE__VIEW',
  RATECARD_STRUCTURE__CREATE = 'RATECARD_STRUCTURE__CREATE',
  RATECARD_STRUCTURE__EDIT = 'RATECARD_STRUCTURE__EDIT',
  RATECARD_STRUCTURE__DELETE = 'RATECARD_STRUCTURE__DELETE',

  /* LIBRARY Privileges */
  LIBRARY__VIEW_RATES = 'LIBRARY__VIEW_RATES',
  LIBRARY_COMPONENT__VIEW = 'LIBRARY_COMPONENT__VIEW',
  LIBRARY_COMPONENT__CREATE = 'LIBRARY_COMPONENT__CREATE',
  LIBRARY_COMPONENT__EDIT = 'LIBRARY_COMPONENT__EDIT',
  LIBRARY_COMPONENT__EDIT_CASCADE = 'LIBRARY_COMPONENT__EDIT_CASCADE',
  LIBRARY_COMPONENT__DELETE = 'LIBRARY_COMPONENT__DELETE',
  LIBRARY_COMPONENT__MOVE = 'LIBRARY_COMPONENT__MOVE',
  LIBRARY_COMPONENT__UPLOAD = 'LIBRARY_COMPONENT__UPLOAD',

  LIBRARY_DELIVERABLE__VIEW = 'LIBRARY_DELIVERABLE__VIEW',
  LIBRARY_DELIVERABLE__CREATE = 'LIBRARY_DELIVERABLE__CREATE',
  LIBRARY_DELIVERABLE__EDIT = 'LIBRARY_DELIVERABLE__EDIT',
  LIBRARY_DELIVERABLE__DELETE = 'LIBRARY_DELIVERABLE__DELETE',
  LIBRARY_DELIVERABLE__MOVE = 'LIBRARY_DELIVERABLE__MOVE',
  LIBRARY_DELIVERABLE__UPLOAD = 'LIBRARY_DELIVERABLE__UPLOAD',

  LIBRARY_FEE_CREATE = 'LIBRARY_FEE_CREATE',
  LIBRARY_FEE_EDIT = 'LIBRARY_FEE_EDIT',
  LIBRARY_FEE_DELETE = 'LIBRARY_FEE_DELETE',
  LIBRARY_FEE_VIEW = 'LIBRARY_FEE_VIEW',

  LIBRARY_FOLDER__VIEW = 'LIBRARY_FOLDER__VIEW',
  LIBRARY_FOLDER__CREATE = 'LIBRARY_FOLDER__CREATE',
  LIBRARY_FOLDER__EDIT = 'LIBRARY_FOLDER__EDIT',
  LIBRARY_FOLDER__DELETE = 'LIBRARY_FOLDER__DELETE',
  LIBRARY_FOLDER__MOVE = 'LIBRARY_FOLDER__MOVE',
  LIBRARY_FOLDER__ADD_ITEM = 'LIBRARY_FOLDER__ADD_ITEM',

  LIBRARY_THIRD_PARTY_COST_GROUP__VIEW = 'LIBRARY_THIRD_PARTY_COST_GROUP__VIEW',
  LIBRARY_THIRD_PARTY_COST_GROUP__CREATE = 'LIBRARY_THIRD_PARTY_COST_GROUP__CREATE',
  LIBRARY_THIRD_PARTY_COST_GROUP__EDIT = 'LIBRARY_THIRD_PARTY_COST_GROUP__EDIT',
  LIBRARY_THIRD_PARTY_COST_GROUP__DELETE = 'LIBRARY_THIRD_PARTY_COST_GROUP__DELETE',

  LIBRARY_THIRD_PARTY_COST__CREATE = 'LIBRARY_THIRD_PARTY_COST__CREATE',
  LIBRARY_THIRD_PARTY_COST__EDIT = 'LIBRARY_THIRD_PARTY_COST__EDIT',
  LIBRARY_THIRD_PARTY_COST__DELETE = 'LIBRARY_THIRD_PARTY_COST__DELETE',
  LIBRARY_THIRD_PARTY_COST__VIEW = 'LIBRARY_THIRD_PARTY_COST__VIEW',
  LIBRARY_THIRD_PARTY_COST__MOVE = 'LIBRARY_THIRD_PARTY_COST__MOVE',
  LIBRARY_THIRD_PARTY_COST__EDIT_CASCADE = 'LIBRARY_THIRD_PARTY_COST__EDIT_CASCADE',

  /* OFFICE LOCATION privileges */
  OFFICE_LOCATION__VIEW = 'OFFICE_LOCATION__VIEW',
  OFFICE_LOCATION__CREATE = 'OFFICE_LOCATION__CREATE',
  OFFICE_LOCATION__EDIT = 'OFFICE_LOCATION__EDIT',
  OFFICE_LOCATION__DELETE = 'OFFICE_LOCATION__DELETE',

  /* DASHBOARD privileges */
  DASHBOARD__VIEW = 'DASHBOARD__VIEW',
  DASHBOARD_FINANCE_REPORTING__VIEW = 'DASHBOARD_FINANCE_REPORTING__VIEW',
  DASHBOARD_REPORT_DOWNLOADS__VIEW = 'DASHBOARD_REPORT_DOWNLOADS__VIEW',
  DASHBOARD_REPORT_DOWNLOADS_PREVIEW = 'DASHBOARD_REPORT_DOWNLOADS_PREVIEW',
  DASHBOARD_REPORT_DOWNLOADS_DOWNLOAD = 'DASHBOARD_REPORT_DOWNLOADS_DOWNLOAD',

  /* OUTPUT TEMPLATE privileges */
  OUTPUT_TEMPLATE__CREATE = 'OUTPUT_TEMPLATE__CREATE',
  OUTPUT_TEMPLATE__VIEW = 'OUTPUT_TEMPLATE__VIEW',
  OUTPUT_TEMPLATE_MANAGE__EDIT = 'OUTPUT_TEMPLATE_MANAGE__EDIT',
  OUTPUT_TEMPLATE__EDIT = 'OUTPUT_TEMPLATE__EDIT',
  OUTPUT_TEMPLATE__DELETE = 'OUTPUT_TEMPLATE__DELETE',

  /*SCOPE MARK DATA privileges*/
  SCOPEMARK_TPC_GROUP_INFO__EDIT = 'SCOPEMARK_TPC_GROUP_INFO__EDIT',
  SCOPEMARK_TPC_GROUP_INFO__VIEW = 'SCOPEMARK_TPC_GROUP_INFO__VIEW',
  SCOPEMARK_TPC_TYPE_INFO__EDIT = 'SCOPEMARK_TPC_TYPE_INFO__EDIT',
  SCOPEMARK_TPC_TYPE_INFO__VIEW = 'SCOPEMARK_TPC_TYPE_INFO__VIEW',

  SCOPEMARK_COMPONENT_RESOURCE__EDIT = 'SCOPEMARK_COMPONENT_RESOURCE__EDIT',
  SCOPEMARK_COMPONENT_INFO__EDIT = 'SCOPEMARK_COMPONENT_INFO__EDIT',
  SCOPEMARK_DELIVERABLE_INFO__EDIT = 'SCOPEMARK_DELIVERABLE_INFO__EDIT',

  /* PREDICTION Privileges */
  PREDICTION_COMPONENT_COPY__EDIT = 'PREDICTION_COMPONENT_COPY__EDIT',
  PREDICTION_COMPONENT_COPY__DELETE = 'PREDICTION_COMPONENT_COPY__DELETE',
  PREDICTION_COMPONENT_RECOMMENDED__EDIT = 'PREDICTION_COMPONENT_RECOMMENDED__EDIT',
  PREDICTION_COMPONENT_ORDER__EDIT = 'PREDICTION_COMPONENT_ORDER__EDIT',
  PREDICTION_COMPONENT_ACTIVE__EDIT = 'PREDICTION_COMPONENT_ACTIVE__EDIT',

  /* MSA Line Item privileges */
  MSA_LINE_ITEM__VIEW = 'MSA_LINE_ITEM__VIEW',
  MSA_LINE_ITEM__CREATE = 'MSA_LINE_ITEM__CREATE',
  MSA_LINE_ITEM__EDIT = 'MSA_LINE_ITEM__EDIT',
  MSA_LINE_ITEM__DELETE = 'MSA_LINE_ITEM__DELETE',

  /* DATA Page privileges */
  FEE_ITEM__CREATE = 'FEE_ITEM__CREATE',
  FEE_ITEM__UPDATE = 'FEE_ITEM__UPDATE',
  FEE_ITEM__DELETE = 'FEE_ITEM__DELETE',
  FEE_ITEM__VIEW = 'FEE_ITEM__VIEW'
}
