import { QuestionType, QuestionTypeIcon, QuestionTypeImage } from '@app/core/model/enums/question-type.enum'
import { Preference } from '@app/core/model/user-preferences.interface'
import { ScenarioQuestionConditionTitle } from '@app/features/company-management/enums'
import { ScenarioCategory } from '@app/features/scope-overview/model/scenario-category.model'
import { ScenarioQuestion } from '@app/features/scope-overview/model/scenario-question.model'
import { TableColumnKey } from '@shared/components/ui-components/scope-ui-table/table-column-key.enum';

export const scenarioCategoryColumnsBaseConfig: Preference[] = [
  {
    key: TableColumnKey.NAME,
    name: 'NAME',
    field: 'name',
    valueIconFunction: () => 'category',
    selected: true,
    noSort: true
  },
  {
    key: TableColumnKey.QUESTIONS,
    name: 'No. of Questions',
    value: (category: ScenarioCategory) => category.questions?.length,
    selected: true,
    noSort: true
  }
]

export const scenarioQuestionsColumnsBaseConfig: Preference[] = [
  {
    key: TableColumnKey.FIELD_NAME,
    name: 'Question',
    field: 'displayText',
    imageMaskFunction: (question: ScenarioQuestion) => QuestionTypeImage[question.type],
    valueIconFunction: (question: ScenarioQuestion) => QuestionTypeIcon[question.type],
    valueIconClassFunction: (question: ScenarioQuestion) =>
      question.type === QuestionType.NUMBER ? 'material-icons item-icon size-18' : 'size-18',
    selected: true,
    noSort: true
  },
  {
    key: TableColumnKey.FIELD_TYPE,
    name: 'Data Type',
    value: (question: ScenarioQuestion) => question.type.charAt(0) + question.type.substring(1).toLowerCase(),
    selected: true,
    noSort: true
  },
  {
    key: TableColumnKey.FIELD_LABEL,
    name: 'Field Label',
    field: 'fieldLabel',
    selected: true,
    noSort: true
  },
  {
    key: TableColumnKey.FIELD_ID,
    name: 'Field ID',
    field: 'fieldId',
    selected: true,
    noSort: true
  },
  {
    key: TableColumnKey.REQUIRED,
    name: 'Required',
    value: (question: ScenarioQuestion) => question.mandatory ? 'Yes' : 'No',
    selected: true,
    noSort: true
  },
  {
    key: TableColumnKey.APPEAR_RULE,
    name: 'Appear Rule',
    value: (question: ScenarioQuestion) =>
      question.displayCondition ? ScenarioQuestionConditionTitle.ON_CONDITION :
        question.hidden ? ScenarioQuestionConditionTitle.NEVER : ScenarioQuestionConditionTitle.ALWAYS,
    selected: true,
    noSort: true
  }
]
