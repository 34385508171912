<div class="modal-container">
  <div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>{{ data.title }}</h1>
      </div>
      <div class="modal-text mt-2">
        Please select all the roles or individuals needed <br> for this project.
      </div>
      <button class="close-button" mat-dialog-close>
        <i class="material-symbols-rounded">close</i>
      </button>
    </div>
  </div>

  <mat-dialog-content aria-label="Generic modal" class="roles-search">
    <div class="modal__body">
      <scope-ui-input class="resize-none"
                      [icon]="'search'"
                      [inputLabel]="'Find role'"
                      [inputClass]="'icon-field'"
                      [resultsCount]="0"
                      (onInputChange)="onSearch($event)" />
      <div class="mb-2 mt-6">
        <div class="tr table-header flex">
          <div class="column nine--columns flex">

            <span class="info-row-title">
              <mat-checkbox class="role-name"
                            [checked]='roles.length === ratecard.getTotalRolesCount()'
                            [indeterminate]="roles.length > 0 && (roles.length !== ratecard.getTotalRolesCount())"
                            (change)='checkAll($event);'
                            aria-label='Select all'
              ></mat-checkbox>
              <mat-icon matTooltip="Expand all" class="material-symbols-rounded orange rotate cursor-pointer" (click)="expandAll()" [ngClass]="{'active' : openCloseAll}">top_panel_open</mat-icon>
              Name
            </span>
          </div>
          <div
            class="column three--columns">
            <span>Rate</span>
          </div>
        </div>
      </div>
      <div class="row my-2" *ngFor="let department of ratecard.departments" [hidden]="hideDepartment(department)" [ngClass]="{'not-selected' : !(openCloseAll || department.selected)}">
        <div>
          <div class="rt-department-row flex items-center cursor-pointer">
            <div class="flex">
              <mat-checkbox class="role-name"
                            [checked]='departmentChecked(department)'
                            [indeterminate]="depPartChecked(department)"
                            (change)='checkDepartment(department);'
                            aria-label='Select role'
              ></mat-checkbox>
              <span class="info-row-title">
              <mat-icon class="material-symbols-rounded orange cursor-pointer rotate" (click)="department.selected = !department.selected" [ngClass]="{'active' : openCloseAll || department.selected}">keyboard_arrow_down</mat-icon>
                {{department.name}}
                <div class="dep-count flex justify-center items-center" *ngIf="departmentRolesCount(department) as included">{{department.roles.length === included ? 'All' : included}}</div>
            </span>
            </div>
          </div>
        </div>
        <div class="row px-1 rt-role-row" *ngFor="let role of department.roles" [hidden]="hideRole(role) || !department.selected">
          <div class="column nine--columns">
            <div class="flex ml-7">
              <mat-checkbox class="role-name"
                            [checked]='roleChecked(role)'
                            (change)='checkRole(role)'
                            aria-label='Use Retained Hours'
              ></mat-checkbox>
              <span class="info-row-title">
              {{role.name}}
            </span>
            </div>

          </div>
          <div
            class="column three--columns items-center text-center">
              <span>{{role.rate.amount | currency: role.rate.currency}}</span>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between">
    <div class="text-xs">{{roles.length}} of {{ratecard.getTotalRolesCount()}} Roles Selected</div>
    <div class="role-actions">
      <button mat-button mat-dialog-close>Cancel</button>
      <button class="main-action-button ml-4" mat-button (click)="submit()">
        Add/Update
      </button>
    </div>
  </mat-dialog-actions>
</div>
