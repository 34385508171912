<div class="flex flex-col configuration-wrapper">
  <div class="flex flex-row flex-1 overflow-hidden">
    <div class="datapoint-nav-container">
      <div class="title">
        <mat-icon class="material-symbols-rounded">edit_document</mat-icon><h3 class="product-heading-bold">Categories</h3>
      </div>
      <ul>
        <li class="data-tab-nodes" *ngFor="let category of scenario?.categories"
            [ngClass]="{'active': category.id === selectedCategoryId, 'disabled': editable && !categoryStates[category.id]?.enabled}"
            (click)="selectCategory(category)">
          <span class="flex-1">{{category.name}}</span>
          <ng-container *ngIf="categoryStates[category.id]?.completed">
            <mat-icon class="material-symbols-rounded completed" *ngIf="!categoryStates[category.id]?.errorCount">check</mat-icon>
            <span class="error" *ngIf="categoryStates[category.id]?.errorCount">
              {{categoryStates[category.id]?.errorCount}}</span>
          </ng-container>
          <mat-icon class="material-symbols-rounded orange" *ngIf="!editable || categoryStates[category.id]?.enabled">chevron_right</mat-icon>
        </li>
      </ul>
    </div>

    <ng-container *ngFor="let category of scenario?.categories; trackBy: trackById">
      <div class="datapoint-content-container" *ngIf="category.id === selectedCategoryId">
        <div class="datapoint-content-container-inner">
          <h2 class="product-heading-bold">{{category.name}}</h2>
          <div class="wrapper">
            <ng-container *ngFor="let question of category.questions; trackBy: trackById">
              <ng-container *ngIf="!questionStates[question.id].excluded && !question.hidden">
                <div class="question">
                  <span>{{question.displayText}}</span><span class="orange" *ngIf="question.mandatory"> *</span>
                </div>
                <scope-ui-input id="{{question.fieldId}}" *ngIf="question.type === QuestionType.TEXT" [type]="'text'"
                                class="form-group pb-8 w-full" [initialValue]="question.value" [isTextarea]="true"
                                [isDisabled]="!editable" [control]="questionStates[question.id].control" [textareaRows]="1"
                                [inputPlaceholder]="question.fieldLabel" [formSubmitted]="formSubmitted"
                                [autoExpand]="true" (onInputBlurChange)="setAnswer(question, $event)" />
                <scope-ui-input id="{{question.fieldId}}" *ngIf="question.type === QuestionType.NUMBER" [type]="'number'"
                                class="form-group pb-8 w-full" [inputPlaceholder]="question.fieldLabel"
                                [isDisabled]="!editable" [initialValue]="question.value" [noArrows]="true"
                                [control]="questionStates[question.id].control" [formSubmitted]="formSubmitted"
                                (onInputBlurChange)="setAnswer(question, $event)"/>
                <scope-ui-datepicker id="{{question.fieldId}}" *ngIf="question.type === QuestionType.DATE"
                                     [singleDatePicker]="true" [inputPlaceholder]="question.fieldLabel"
                                     [initialValue]="question.value" [disabled]="!editable"
                                     [formSubmitted]="formSubmitted" [dateControl]="questionStates[question.id].control"
                                     (onSelect)="setAnswer(question, $event)" />
                <scope-ui-dropdown id="{{question.fieldId}}" *ngIf="question.type === QuestionType.DROPDOWN"
                                   [selectedValue]="question.value" [isStandardDropdown]="true"
                                   [disabled]="!editable" [formSubmitted]="formSubmitted"
                                   [selectControl]="questionStates[question.id].control" [inputPlaceholder]="question.fieldLabel"
                                   [dropdownOptions]="question.optionsList.split(',')"
                                   (onSelectionChange)="setAnswer(question, $event)" />
                <div class="formula-container" *ngIf="question.type === QuestionType.FORMULA">
                  <span *ngIf="questionStates[question.id]?.calcError" class="result text-error-600">
                    Value cannot be calculated. Please contact your administrator.
                  </span>
                  <span class="result">{{configuration[category.id]?.[question.id]}}</span>
                  <button class="orange text-link small" (click)="showFormulaMap[question.id] = !showFormulaMap[question.id]">
                    {{showFormulaMap[question.id] ? 'Hide Formula' : 'View Formula'}}
                  </button>
                </div>
                <formula-builder id="{{question.fieldId}}" class="w-full col-span-2"
                                 *ngIf="question.type === QuestionType.FORMULA" [hidden]="!showFormulaMap[question.id]"
                                 [scenario]="scenario" [(value)]="question.formula" [showInput]="false"
                                 [showFormula]="showFormulaMap[question.id]" />
                <div class="w-full col-span-2" *ngIf="question.type !== QuestionType.FORMULA" [hidden]="true"></div>
              </ng-container>
            </ng-container>
          </div>
          <ng-container *ngIf="!(updateLoading$ | async) && editable">
            <button *ngIf="(categoryStates[category.id]?.updated) && !(totalErrorCount > categoryStates[category.id]?.errorCount)"
                    mat-flat-button class="action-button" [disabled]="!editable" (click)="save(scenario, category)">
              Save answers
            </button>
            <ng-container *ngIf="!categoryStates[category.id]?.updated && status === StatusType.CONFIG_DRAFT">
              <ng-template #nextButton let-nextCategory="nextCategory">
                <button *ngIf="nextCategory && !categoryStates[nextCategory.id]?.enabled" mat-flat-button
                        class="action-button" (click)="save(scenario, category, true)" [disabled]="!editable">
                  Next Category
                </button>
                <button *ngIf="nextCategory && categoryStates[nextCategory.id]?.enabled" mat-flat-button
                        class="action-button" (click)="selectCategory(nextCategory)" [disabled]="!editable">
                  Next Category
                </button>
                <button *ngIf="!nextCategory" mat-flat-button class="action-button" (click)="submit()"
                        [disabled]="!editable">
                  Submit Form
                </button>
              </ng-template>
              <ng-container *ngTemplateOutlet="nextButton; context: {nextCategory: scenario.categories[scenario.categories.indexOf(category) + 1]}"></ng-container>
            </ng-container>
          </ng-container>
          <button mat-flat-button class="action-button active" *ngIf="(updateLoading$ | async)">
            <img ngSrc="/assets/icons/loading.svg" alt="loading" class="icon-spinner" height="25" width="24">
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
