import { Component, Input } from '@angular/core'

import { CommonModule } from '@angular/common'

@Component({
  selector: 'scope-ui-tab',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './scope-ui-tab.component.html',
  styleUrls: ['./scope-ui-tab.component.scss'],
})
export class ScopeUiTabComponent {
  @Input() tabTitle!: string
  @Input() subTitle!: string
  @Input() requiredSetting: string = 'SKIP_RETURN_TRUE'
  @Input() requiredPrivilege: string
  @Input() active = false
  @Input() hidden = false
  @Input() routeLink!: string
  @Input() icon!: string
  @Input() disabled: boolean = false
  @Input() default: boolean = false
}
