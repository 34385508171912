import { createAction, props } from '@ngrx/store';
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import { Tab } from '@shared/utils/tabs-util.service';
import { ScopeUiTabComponent } from '@shared/components/ui-components/scope-ui-tab/scope-ui-tab.component';
import { Page } from '@app/features/scoping/models/page.model';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { Sort } from '@angular/material/sort';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import { ScenarioCategory } from '@app/features/scope-overview/model/scenario-category.model';
import { ScenarioQuestion } from '@app/features/scope-overview/model/scenario-question.model';

export enum CompanyManagementActionTypes {
  GET_OUTPUT_TEMPLATES = '[COMPANY MANAGEMENT CONFIG] Get Output Templates',
  GET_OUTPUT_TEMPLATES_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Get Output Templates Success',
  GET_OUTPUT_TEMPLATES_FAILURE = '[COMPANY MANAGEMENT CONFIG] Get Output Templates Failure',

  SET_GROUP_ID = '[COMPANY MANAGEMENT CONFIG] Set Group Id',
  TAB_CHANGE_START = '[COMPANY MANAGEMENT CONFIG] Tab Change Start',

  GET_TASKS = '[COMPANY MANAGEMENT CONFIG] Get Tasks',
  GET_TASKS_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Get Tasks Success',
  GET_TASKS_FAIL = '[COMPANY MANAGEMENT CONFIG] Get Tasks Fail',

  GET_ALL_SCENARIOS = '[COMPANY MANAGEMENT CONFIG] Load Scenarios',
  GET_ALL_SCENARIOS_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Load Scenarios Success',
  GET_ALL_SCENARIOS_FAIL = '[COMPANY MANAGEMENT CONFIG] Load Scenarios Fail',
  CREATE_SCENARIO = '[COMPANY MANAGEMENT CONFIG] Create Scenario',
  CREATE_SCENARIO_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Create Scenario Success',
  CREATE_SCENARIO_FAIL = '[COMPANY MANAGEMENT CONFIG] Create Scenario Fail',
  DELETE_SCENARIO = '[COMPANY MANAGEMENT CONFIG] Delete Scenario',
  DELETE_SCENARIO_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Delete Scenario Success',
  DELETE_SCENARIO_FAIL = '[COMPANY MANAGEMENT CONFIG] Delete Scenario Fail',
  UPDATE_SCENARIO = '[COMPANY MANAGEMENT CONFIG] Update Scenario',
  UPDATE_SCENARIO_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Update Scenario Success',
  UPDATE_SCENARIO_FAIL = '[COMPANY MANAGEMENT CONFIG] Update Scenario Fail',
  GET_SCENARIO = '[COMPANY MANAGEMENT CONFIG] Load Scenario',
  GET_SCENARIO_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Load Scenario Success',
  GET_SCENARIO_FAIL = '[COMPANY MANAGEMENT CONFIG] Load Scenario Fail',

  CREATE_SCENARIO_CATEGORY = '[COMPANY MANAGEMENT CONFIG] Create Scenario Category',
  CREATE_SCENARIO_CATEGORY_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Create Scenario Category Success',
  CREATE_SCENARIO_CATEGORY_FAIL = '[COMPANY MANAGEMENT CONFIG] Create Scenario Category Fail',
  DELETE_SCENARIO_CATEGORY = '[COMPANY MANAGEMENT CONFIG] Delete Scenario Category',
  DELETE_SCENARIO_CATEGORY_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Delete Scenario Category Success',
  DELETE_SCENARIO_CATEGORY_FAIL = '[COMPANY MANAGEMENT CONFIG] Delete Scenario Category Fail',
  REORDER_SCENARIO_CATEGORIES = '[COMPANY MANAGEMENT CONFIG] Reorder Scenario Categories',
  REORDER_SCENARIO_CATEGORIES_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Reorder Scenario Categories Success',
  REORDER_SCENARIO_CATEGORIES_FAIL = '[COMPANY MANAGEMENT CONFIG] Reorder Scenario Categories Fail',
  UPDATE_SCENARIO_CATEGORY = '[COMPANY MANAGEMENT CONFIG] Update Scenario Category',
  UPDATE_SCENARIO_CATEGORY_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Update Scenario Category Success',
  UPDATE_SCENARIO_CATEGORY_FAIL = '[COMPANY MANAGEMENT CONFIG] Update Scenario Category Fail',

  GET_ALL_SCENARIO_QUESTIONS = '[COMPANY MANAGEMENT CONFIG] Load Scenario Questions',
  GET_ALL_SCENARIO_QUESTIONS_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Load Scenario Questions Success',
  GET_ALL_SCENARIO_QUESTIONS_FAIL = '[COMPANY MANAGEMENT CONFIG] Load Scenario Questions Fail',
  CREATE_SCENARIO_QUESTION = '[COMPANY MANAGEMENT CONFIG] Create Scenario Question',
  CREATE_SCENARIO_QUESTION_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Create Scenario Question Success',
  CREATE_SCENARIO_QUESTION_FAIL = '[COMPANY MANAGEMENT CONFIG] Create Scenario Question Fail',
  DELETE_SCENARIO_QUESTION = '[COMPANY MANAGEMENT CONFIG] Delete Scenario Question',
  DELETE_SCENARIO_QUESTION_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Delete Scenario Question Success',
  DELETE_SCENARIO_QUESTION_FAIL = '[COMPANY MANAGEMENT CONFIG] Delete Scenario Question Fail',
  REORDER_SCENARIO_QUESTIONS = '[COMPANY MANAGEMENT CONFIG] Reorder Scenario Questions',
  REORDER_SCENARIO_QUESTIONS_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Reorder Scenario Questions Success',
  REORDER_SCENARIO_QUESTIONS_FAIL = '[COMPANY MANAGEMENT CONFIG] Reorder Scenario Questions Fail',
  UPDATE_SCENARIO_QUESTION = '[COMPANY MANAGEMENT CONFIG] Update Scenario Question',
  UPDATE_SCENARIO_QUESTION_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Update Scenario Question Success',
  UPDATE_SCENARIO_QUESTION_FAIL = '[COMPANY MANAGEMENT CONFIG] Update Scenario Question Fail',
}

const getOutputTemplates = createAction(CompanyManagementActionTypes.GET_OUTPUT_TEMPLATES);
const getOutputTemplatesSuccess = createAction(
  CompanyManagementActionTypes.GET_OUTPUT_TEMPLATES_SUCCESS,
  props<{ outputTemplates: OutputTemplate[] }>()
);
const getOutputTemplatesFail = createAction(CompanyManagementActionTypes.GET_OUTPUT_TEMPLATES_FAILURE, props<{ error: Error }>());

const setGroupId = createAction(CompanyManagementActionTypes.SET_GROUP_ID, props<{ groupId: number }>());

const tabChangeStart = createAction(CompanyManagementActionTypes.TAB_CHANGE_START, props<{ additionalCtx: any, tabs: Tab | ScopeUiTabComponent }>());

const getTasks = createAction(CompanyManagementActionTypes.GET_TASKS, props<{ page: number, searchText?: string, sort: Sort }>());
const getTasksSuccess = createAction(CompanyManagementActionTypes.GET_TASKS_SUCCESS, props<{ tasks: Page<Deliverable> }>());
const getTasksFail = createAction(CompanyManagementActionTypes.GET_TASKS_FAIL, props<{ error: Error }>());

const getScenarios = createAction(CompanyManagementActionTypes.GET_ALL_SCENARIOS);
const getScenariosSuccess = createAction(CompanyManagementActionTypes.GET_ALL_SCENARIOS_SUCCESS, props<{ scenarios: Scenario[] }>());
const getScenariosFail = createAction(CompanyManagementActionTypes.GET_ALL_SCENARIOS_FAIL , props<{ error: any }>());
const createScenario = createAction(CompanyManagementActionTypes.CREATE_SCENARIO, props<{ scenario: Scenario }>());
const createScenarioSuccess = createAction(CompanyManagementActionTypes.CREATE_SCENARIO_SUCCESS, props<{ scenario: Scenario }>());
const createScenarioFail = createAction(CompanyManagementActionTypes.CREATE_SCENARIO_FAIL, props<{ error: any }>());
const deleteScenario = createAction(CompanyManagementActionTypes.DELETE_SCENARIO, props<{ id: number }>());
const deleteScenarioSuccess = createAction(CompanyManagementActionTypes.DELETE_SCENARIO_SUCCESS, props<{ id: number }>());
const deleteScenarioFail = createAction(CompanyManagementActionTypes.DELETE_SCENARIO_FAIL, props<{ error: any }>());
const updateScenario = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO, props<{ id: number , scenario: Scenario}>());
const updateScenarioSuccess = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO_SUCCESS);
const updateScenarioFail = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO_FAIL, props<{ error: any }>());
const getScenario = createAction(CompanyManagementActionTypes.GET_SCENARIO, props<{ scenarioId: number }>());
const getScenarioSuccess = createAction(CompanyManagementActionTypes.GET_SCENARIO_SUCCESS, props<{ scenario: Scenario }>());
const getScenarioFail = createAction(CompanyManagementActionTypes.GET_SCENARIO_FAIL, props<{ error: any }>());

const createScenarioCategory = createAction(CompanyManagementActionTypes.CREATE_SCENARIO_CATEGORY, props<{ scenarioId: number, category: ScenarioCategory }>());
const createScenarioCategorySuccess = createAction(CompanyManagementActionTypes.CREATE_SCENARIO_CATEGORY_SUCCESS, props<{ category: ScenarioCategory }>());
const createScenarioCategoryFail = createAction(CompanyManagementActionTypes.CREATE_SCENARIO_CATEGORY_FAIL, props<{ error: any }>());
const deleteScenarioCategory = createAction(CompanyManagementActionTypes.DELETE_SCENARIO_CATEGORY, props<{ scenarioId: number, categoryId: number }>());
const deleteScenarioCategorySuccess = createAction(CompanyManagementActionTypes.DELETE_SCENARIO_CATEGORY_SUCCESS, props<{ categoryId: number }>());
const deleteScenarioCategoryFail = createAction(CompanyManagementActionTypes.DELETE_SCENARIO_CATEGORY_FAIL, props<{ error: any }>());
const reorderScenarioCategories = createAction(CompanyManagementActionTypes.REORDER_SCENARIO_CATEGORIES, props<{ scenarioId: number, orderedCategoryIds: number[] }>());
const reorderScenarioCategoriesSuccess = createAction(CompanyManagementActionTypes.REORDER_SCENARIO_CATEGORIES_SUCCESS);
const reorderScenarioCategoriesFail = createAction(CompanyManagementActionTypes.REORDER_SCENARIO_CATEGORIES_FAIL, props<{ error: any }>());
const updateScenarioCategory = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO_CATEGORY, props<{ scenarioId: number ,categoryId: number, category: ScenarioCategory}>());
const updateScenarioCategorySuccess = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO_CATEGORY_SUCCESS, props<{ category: ScenarioCategory }>());
const updateScenarioCategoryFail = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO_CATEGORY_FAIL, props<{ error: any }>());

const getScenarioQuestions = createAction(CompanyManagementActionTypes.GET_ALL_SCENARIO_QUESTIONS, props<{ scenarioId: number, categoryId: number }>());
const getScenarioQuestionsSuccess = createAction(CompanyManagementActionTypes.GET_ALL_SCENARIO_QUESTIONS_SUCCESS, props<{ categoryId: number, scenarioQuestions: ScenarioQuestion[] }>());
const getScenarioQuestionsFail = createAction(CompanyManagementActionTypes.GET_ALL_SCENARIO_QUESTIONS_FAIL, props<{ error: any }>());
const createScenarioQuestion = createAction(CompanyManagementActionTypes.CREATE_SCENARIO_QUESTION, props<{ scenarioId: number, categoryId: number, question: ScenarioQuestion }>());
const createScenarioQuestionSuccess = createAction(CompanyManagementActionTypes.CREATE_SCENARIO_QUESTION_SUCCESS, props<{ categoryId: number, question: ScenarioQuestion }>());
const createScenarioQuestionFail = createAction(CompanyManagementActionTypes.CREATE_SCENARIO_QUESTION_FAIL, props<{ error: any }>());
const deleteScenarioQuestion = createAction(CompanyManagementActionTypes.DELETE_SCENARIO_QUESTION, props<{ scenarioId: number, categoryId: number, questionId: number }>());
const deleteScenarioQuestionSuccess = createAction(CompanyManagementActionTypes.DELETE_SCENARIO_QUESTION_SUCCESS, props<{ categoryId: number, questionId: number }>());
const deleteScenarioQuestionFail = createAction(CompanyManagementActionTypes.DELETE_SCENARIO_QUESTION_FAIL, props<{ error: any }>());
const reorderScenarioQuestions = createAction(CompanyManagementActionTypes.REORDER_SCENARIO_QUESTIONS, props<{ scenarioId: number, categoryId: number, orderedQuestionIds: number[] }>());
const reorderScenarioQuestionsSuccess = createAction(CompanyManagementActionTypes.REORDER_SCENARIO_QUESTIONS_SUCCESS);
const reorderScenarioQuestionsFail = createAction(CompanyManagementActionTypes.REORDER_SCENARIO_QUESTIONS_FAIL, props<{ error: any }>());
const updateScenarioQuestion = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO_QUESTION, props<{ scenarioId: number ,categoryId: number, questionId: number, question: ScenarioQuestion}>());
const updateScenarioQuestionSuccess = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO_QUESTION_SUCCESS, props<{ categoryId: number, question: ScenarioQuestion }>());
const updateScenarioQuestionFail = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO_QUESTION_FAIL, props<{ error: any }>());

export const CompanyManagementActions = {
  getOutputTemplates,
  getOutputTemplatesSuccess,
  getOutputTemplatesFail,
  setGroupId,
  tabChangeStart,
  getTasks,
  getTasksSuccess,
  getTasksFail,
  getScenarios,
  getScenariosSuccess,
  getScenariosFail,
  createScenario,
  createScenarioSuccess,
  createScenarioFail,
  deleteScenario,
  deleteScenarioSuccess,
  deleteScenarioFail,
  updateScenario,
  updateScenarioSuccess,
  updateScenarioFail,
  getScenario: getScenario,
  getScenarioSuccess: getScenarioSuccess,
  getScenarioFail: getScenarioFail,
  createScenarioCategory,
  createScenarioCategorySuccess,
  createScenarioCategoryFail,
  deleteScenarioCategory,
  deleteScenarioCategorySuccess,
  deleteScenarioCategoryFail,
  reorderScenarioCategories,
  reorderScenarioCategoriesSuccess,
  reorderScenarioCategoriesFail,
  updateScenarioCategory,
  updateScenarioCategorySuccess,
  updateScenarioCategoryFail,
  getScenarioQuestions,
  getScenarioQuestionsSuccess,
  getScenarioQuestionsFail,
  createScenarioQuestion,
  createScenarioQuestionSuccess,
  createScenarioQuestionFail,
  deleteScenarioQuestion,
  deleteScenarioQuestionSuccess,
  deleteScenarioQuestionFail,
  reorderScenarioQuestions,
  reorderScenarioQuestionsSuccess,
  reorderScenarioQuestionsFail,
  updateScenarioQuestion,
  updateScenarioQuestionSuccess,
  updateScenarioQuestionFail
}
