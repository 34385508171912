<div mat-dialog-title>
  <div class="modal__header">
    <div class="modal__title">
      <h1>{{title}}</h1>
    </div>

    <div class="mt-2">
      <span>
          {{subtitle}}
      </span>
    </div>

    <button class="close-button" mat-dialog-close>
      <mat-icon class="material-symbols-rounded">close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content aria-label="Generic modal" *ngIf="page === 'new'">
  <div class="w-full">
    <div class="flex gap-6">
      <scope-ui-input
        class="{{newTaskTemplate ? 'w-full' : 'task-name'}}"
        [inputLabel]="'Task name'"
        [required]="true"
        [maximumLength]="100"
        [control]="nameFormControl"
        aria-label="Task name"
        (onInputChange)="newTask.name = $event" />
      <scope-ui-input
        *ngIf="!newTaskTemplate"
        class="flex-1"
        [inputLabel]="'Quantity'"
        [type]="'number'"
        [required]="true"
        [minimum]="1"
        [control]="quantityFormControl"
        [isInteger]="true"
        aria-label="Quantity"
        (onInputChange)="newTask.quantity = $event" />
    </div>
    <div class="w-full pt-6" *ngIf="newTaskTemplate">
      <scope-ui-autocomplete
        class="flex-1"
        [options]="rateCards$ | async"
        orderBy="name"
        [label]="'Rate Card'"
        [required]="true"
        (onSelectionChange)="newTask.rateCardVersion = $event.event"/>
    </div>
    <div class="w-full pt-6">
      <description-editor
        [model]='{}'
        [(visible)]="editDescription"
        [hideNote]='true'
        [descriptionRequired]="true"
        [descriptionDirty]="descriptionDirty"
        (onSaveDescription)="newTask.description = $event" >
      </description-editor>
    </div>
    <scope-ui-duration
      *ngIf="!newTaskTemplate"
      class="pt-6 block"
      [has-setting]="'SCOPE__START_END_DATE'"
      [(startDate)]='newTask.startDate'
      [(endDate)]='newTask.endDate'
      [(selectedPeriod)]='selectedPeriod' >
    </scope-ui-duration>
    <!--<button class='text-link small mt-8' (click)="nameFormControl.markAsTouched();quantityFormControl.markAsTouched();descriptionDirty=true">
      <mat-icon class="material-symbols-rounded orange f-22">person_add</mat-icon>Select Roles for this task
    </button>-->
  </div>
</mat-dialog-content>
<mat-dialog-content aria-label="Generic modal" *ngIf="page === 'existing'">
  <div class="flex flex-col existing-tasks-container">
    <div class="flex flex-row">
      <scope-ui-input class="resize-none w-full"
                      [icon]="'search'"
                      [inputLabel]="'Find task'"
                      [inputClass]="'icon-field'"
                      [resultsCount]="0"
                      (onInputChange)="onSearch($event)" />
    </div>
    <ng-container *ngIf="getFilteredTemplates() as filteredTemplates">
      <div class="flex flex-row justify-between mt-4">
        <button class='text-link' [disabled]="!filteredTemplates.length" (click)='checkAll()'>Select all</button>
        <button class='text-link' (click)='clear()'>Clear</button>
      </div>
      <div class='templates-table mt-2 flex-1'>
        <div class="no-results" *ngIf="!filteredTemplates.length">0 results found</div>
        <div class="row" *ngFor="let template of filteredTemplates">
          <mat-checkbox class="role-name"
                        [checked]='template.selected'
                        (change)='template.selected = !template.selected'
                        aria-label='Select role'
          ></mat-checkbox>
          <span class="info-row-title{{template.selected ? ' bold' : ''}}" [innerHTML]='getTaskName(template)'></span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="getSelectedTemplates() as selectedTemplates">
      <div class="flex flex-wrap mt-4" *ngIf="selectedTemplates.length">
        <div class="filter-tag" *ngFor="let template of selectedTemplates; index as index">
          <span class="tag-value">{{ template.name }}</span>
          <mat-icon class="material-symbols-rounded" (click)="template.selected = !template.selected">close</mat-icon>
        </div>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="flex-col">
  <div *ngIf="page === 'new'" class="mt-8 flex w-full justify-end">
    <button mat-button mat-dialog-close>
      <span class='tracking-normal'>Cancel</span>
    </button>
    <button mat-flat-button class="ml-8" (click)="confirm()" [disabled]="!isNewTasksFormValid()">
      <span class='tracking-normal'>Create Task</span>
    </button>
  </div>
  <div *ngIf="page === 'existing'" class="mt-8 flex justify-between w-full">
    <div class="tasks-count">{{tasksCount()}} of {{taskTemplates.length}}</div>
    <div>
      <button mat-button mat-dialog-close>
        <span class='tracking-normal'>Back</span>
      </button>
      <button mat-flat-button class="ml-8" (click)="confirmSavedTasks()" [disabled]="!isSavedTasksFormValid()">
        <span class='tracking-normal'>Add</span>
      </button>
    </div>
  </div>
  <div class="custom-del-button mt-8 flex justify-center" *ngIf="page === 'existing' || taskTemplates.length">
    <button *ngIf="page === 'new'" class='text-link small' (click)="addSavedTasks()">
      <mat-icon class="material-symbols-rounded orange">add</mat-icon>Add Saved Task(s)
    </button>
    <button *ngIf="page === 'existing'" class='text-link small' (click)="createNewTasks()">
      <mat-icon class="material-symbols-rounded orange">add</mat-icon>Create Task(s)
    </button>
  </div>
</mat-dialog-actions>
