import { Type } from 'class-transformer'
import { SecondParty } from '@core/model/second-party.model'
import { Brand } from '@app/features/scope-overview/model/brand.model'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { SecondPartyContact } from '@app/features/scope-overview/model/second-party-contact.model'
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model'
import { ScopeTeam } from '@core/model/scope-team.model'
import { OfficeLocation } from "@core/model/office-location";
import { CostPlus } from '@app/features/scope-overview/model/cost-plus.model'

export class ScopeIdentity {
  id!: number
  localId!: number
  scopeType!: any
  language!: string
  currentVersionNumber!: number
  isTemplate!: boolean
  identificationType!: string
  financialsOrder: string;
  @Type(() => ScopeTeam) team!: ScopeTeam
  @Type(() => SecondParty) secondParty!: SecondParty
  @Type(() => Brand) brand!: Brand
  @Type(() => RatecardVersion) rateCard!: RatecardVersion
  @Type(() => RatecardVersion) benchmarkRateCard!: RatecardVersion
  @Type(() => SecondPartyContact) contact!: SecondPartyContact
  @Type(() => OutputTemplate) scopeTemplate!: OutputTemplate
  officeLocation: any
  @Type(() => OfficeLocation) location: OfficeLocation
  outputTemplate: any
  xlsxTemplate: any
  fteValue: any
  reference: string
  commentsCount: number
  @Type(() => CostPlus) costPlus?: CostPlus;
  scenario?: { id: number }

  constructor(cxt: {
    id: number
    localId: number
    reference: string
    rateCard: RatecardVersion
    benchmarkRateCard: any
    officeLocation: any
    secondParty: SecondParty
    brand: Brand
    currentVersionNumber: number
    commentsCount: number
    outputTemplate: any
    contact: any
    location: any
    isTemplate: boolean
    language: string
    team: any
    scopeType: any
    deleted: boolean
    xlsxTemplate: any
    identificationType: any
    fteValue: number
  }) {
    Object.assign(this, cxt)
  }

  update(update: Partial<typeof ScopeIdentity.prototype>): void {
    Object.assign(this, update)
  }

  getFinancialsOrder(): any[] {
    return this.financialsOrder ? JSON.parse(this.financialsOrder) : []
  }

  getScopeType(): any {
    return this.scopeType
  }

  getReference(): string {
    return this.reference
  }

  getId(): number {
    return this.id
  }

  getSecondParty(): any {
    return this.secondParty
  }

  getRateCard(): RatecardVersion {
    return this.rateCard
  }

  getBenchmarkRateCard(): any {
    return this.benchmarkRateCard
  }

  getCurrentVersionNumber(): number {
    return this.currentVersionNumber
  }

  setCurrentVersionNumber(number: number): void {
    this.currentVersionNumber = number
  }

  getCommentsCount(): number {
    return this.commentsCount
  }

  setRatecard(rc: any): void {
    this.rateCard = rc
  }

  getContact(): any {
    return this.contact
  }

  getLocation(): any {
    return this.location
  }

  getOutputTemplate(): any {
    return this.outputTemplate
  }

  getXlsxTemplate(): any {
    return this.xlsxTemplate
  }

  getFteValue(): number {
    return this.fteValue
  }
}
