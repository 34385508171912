import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  CompanyManagementActions,
  CompanyManagementActionTypes,
} from '@app/features/company-management/store/actions/company-management.actions';
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';
import { ScenarioService } from '@app/features/company-management/service/scenario.service';
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from '@shared/utils/snackbar.service';
import { ScenarioCategoryService } from '@app/features/company-management/service/scenario-category.service';
import { ScenarioQuestionService } from '@app/features/company-management/service/scenario-question.service';

@Injectable()
export class CompanyManagementEffects {
  constructor(private actions$: Actions,
              private manageService: CompanyManagementService,
              private scenarioService: ScenarioService,
              private scenarioCategoryService: ScenarioCategoryService,
              private scenarioQuestionService: ScenarioQuestionService,
              private snackbarService: SnackbarService) {}

  getOutputTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyManagementActionTypes.GET_OUTPUT_TEMPLATES),
      switchMap(() => {
        return this.manageService.getAllWordTemplates().pipe(
          map((outputTemplates) => CompanyManagementActions.getOutputTemplatesSuccess({ outputTemplates: outputTemplates })),
          catchError((error) => {
            console.error(error)
            return of(CompanyManagementActions.getOutputTemplatesFail({ error }))
          })
        );
      })
    );
  })

  getTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyManagementActionTypes.GET_TASKS),
      switchMap((action: any) => {
        return this.manageService.getLibraryTaskTemplates(action.page, action.searchText, action.sort).pipe(
          map((tasks) => CompanyManagementActions.getTasksSuccess({ tasks })),
          catchError((error) => {
            console.error(error)
            return of(CompanyManagementActions.getTasksFail({ error }))
          })
        );
      })
    );
  })

  getScenarios$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyManagementActionTypes.GET_ALL_SCENARIOS),
      switchMap(() => {
        return this.scenarioService.getAll().pipe(
          map((scenarios) => CompanyManagementActions.getScenariosSuccess({ scenarios })),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during loading scenarios',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR,
            );
            return of(CompanyManagementActions.getScenariosFail({ error }));
          }),
        );
      }),
    );
  });

  createScenario$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyManagementActionTypes.CREATE_SCENARIO),
      switchMap(({ scenario }) => {
        return this.scenarioService.create(scenario).pipe(
          map((newScenario) => CompanyManagementActions.createScenarioSuccess({ scenario: newScenario })),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during creating scenario',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            );
            return of(CompanyManagementActions.createScenarioFail({ error }));
          })
        );
      })
    );
  });

  deleteScenario$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyManagementActionTypes.DELETE_SCENARIO),
      switchMap(({ id }) => {
        return this.scenarioService.delete(id).pipe(
          map(() => CompanyManagementActions.deleteScenarioSuccess({ id })),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during deleting scenario',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            );
            return of(CompanyManagementActions.deleteScenarioFail({ error }));
          })
        );
      })
    );
  });

  updateScenario$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyManagementActionTypes.UPDATE_SCENARIO),
      switchMap(({ id, scenario }) =>
        this.scenarioService.update(id, scenario).pipe(
          map(() => CompanyManagementActions.updateScenarioSuccess()),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during updating scenario',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            );
            return of(CompanyManagementActions.updateScenarioFail({ error }));
          })
        )
      )
    );
  });

  getScenario$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyManagementActionTypes.GET_SCENARIO),
      switchMap(({ scenarioId }) => {
        return this.scenarioService.get(scenarioId).pipe(
          map((scenario) =>
            CompanyManagementActions.getScenarioSuccess({ scenario })
          ),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during loading scenario',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR,
            );
            return of(CompanyManagementActions.getScenarioFail({ error }));
          })
        );
      })
    );
  });

  createScenarioCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyManagementActionTypes.CREATE_SCENARIO_CATEGORY),
      switchMap(({ scenarioId, category }) => {
        return this.scenarioCategoryService.create(scenarioId, category).pipe(
          map((newCategory) =>
            CompanyManagementActions.createScenarioCategorySuccess({ category: newCategory })
          ),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during creating scenario category',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            );
            return of(CompanyManagementActions.createScenarioCategoryFail({ error }));
          })
        );
      })
    );
  });

  deleteScenarioCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyManagementActionTypes.DELETE_SCENARIO_CATEGORY),
      switchMap(({ scenarioId, categoryId }) => {
        return this.scenarioCategoryService.delete(scenarioId, categoryId).pipe(
          map(() =>
            CompanyManagementActions.deleteScenarioCategorySuccess({ categoryId })
          ),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during deleting scenario category',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            );
            return of(CompanyManagementActions.deleteScenarioCategoryFail({ error }));
          })
        );
      })
    );
  });

  reorderScenarioCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyManagementActionTypes.REORDER_SCENARIO_CATEGORIES),
      switchMap(({ scenarioId, orderedCategoryIds }) => {
        return this.scenarioCategoryService.reorder(scenarioId, orderedCategoryIds).pipe(
          map(() =>
            CompanyManagementActions.reorderScenarioCategoriesSuccess()
          ),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during reordering scenario categories',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            );
            return of(CompanyManagementActions.reorderScenarioCategoriesFail({ error }));
          })
        );
      })
    );
  });

  updateScenarioCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyManagementActionTypes.UPDATE_SCENARIO_CATEGORY),
      switchMap(({ scenarioId, categoryId, category }) =>
        this.scenarioCategoryService.update(scenarioId, categoryId, category).pipe(
          map((result) => CompanyManagementActions.updateScenarioCategorySuccess({ category: result })),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during updating scenario category',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            );
            return of(CompanyManagementActions.updateScenarioCategoryFail({ error }));
          })
        )
      )
    );
  });

  getScenarioQuestions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyManagementActionTypes.GET_ALL_SCENARIO_QUESTIONS),
      switchMap(({ scenarioId, categoryId }) => {
        return this.scenarioQuestionService.getAll(scenarioId, categoryId).pipe(
          map((scenarioQuestions) =>
            CompanyManagementActions.getScenarioQuestionsSuccess({ categoryId, scenarioQuestions })
          ),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during loading scenario questions',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR,
            );
            return of(CompanyManagementActions.getScenarioQuestionsFail({ error }));
          })
        );
      })
    );
  });

  createScenarioQuestion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyManagementActionTypes.CREATE_SCENARIO_QUESTION),
      switchMap(({ scenarioId, categoryId,  question }) => {
        return this.scenarioQuestionService.create(scenarioId, categoryId, question).pipe(
          map((newQuestion) =>
            CompanyManagementActions.createScenarioQuestionSuccess({ categoryId, question: newQuestion })
          ),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during creating scenario question',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            );
            return of(CompanyManagementActions.createScenarioQuestionFail({ error }));
          })
        );
      })
    );
  });

  deleteScenarioQuestion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyManagementActionTypes.DELETE_SCENARIO_QUESTION),
      switchMap(({ scenarioId, categoryId, questionId }) => {
        return this.scenarioQuestionService.delete(scenarioId, categoryId, questionId).pipe(
          map(() =>
            CompanyManagementActions.deleteScenarioQuestionSuccess({ categoryId, questionId })
          ),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during deleting scenario question',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            );
            return of(CompanyManagementActions.deleteScenarioQuestionFail({ error }));
          })
        );
      })
    );
  });

  reorderScenarioQuestions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyManagementActionTypes.REORDER_SCENARIO_QUESTIONS),
      switchMap(({ scenarioId, categoryId, orderedQuestionIds }) => {
        return this.scenarioQuestionService.reorder(scenarioId, categoryId, orderedQuestionIds).pipe(
          map(() =>
            CompanyManagementActions.reorderScenarioQuestionsSuccess()
          ),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during reordering scenario questions',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            );
            return of(CompanyManagementActions.reorderScenarioQuestionsFail({ error }));
          })
        );
      })
    );
  });

  updateScenarioQuestion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyManagementActionTypes.UPDATE_SCENARIO_QUESTION),
      switchMap(({ scenarioId, categoryId, questionId, question }) =>
        this.scenarioQuestionService.update(scenarioId, categoryId, questionId, question).pipe(
          map((result) => CompanyManagementActions.updateScenarioQuestionSuccess({ categoryId, question: result })),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during updating scenario question',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            );
            return of(CompanyManagementActions.updateScenarioQuestionFail({ error }));
          })
        )
      )
    );
  });
}
