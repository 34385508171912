<div class="flex d-flex-justify-between pt-6 px-10">
  <h3 class="product-heading-bold">Rate cards</h3>
  <div class="flex gap-1">
    <scope-ui-input
      class="mr-2"
      [icon]="'search'"
      [cancelIcon]="'cancel'"
      [inputLabel]="'Search'"
      [inputClass]="'search-field'"
      (onCancel)="searchText = ''; filterRateCards()"
      (onEnter)="searchText = $event; filterRateCards()" />
    <button [has-privilege]="'RATECARD__CREATE'" (click)="showAddRateCardDialog()"
            mat-flat-button>
      <mat-icon class="material-symbols-rounded">add</mat-icon> Add rate card
    </button>
    <button
      class="scope-actions-button"
      (click)="$event.stopPropagation()"
      [matMenuTriggerFor]="optionsMenu">
      <mat-icon class="material-symbols-rounded">more_vert</mat-icon>
    </button>
    <mat-menu
      #optionsMenu="matMenu"
      backdropClass="scope-options-menu">
      <ng-container *ngFor="let option of menuOptions">
        <button
          (click)="option.click()"
          *ngIf="option.isVisible ? option.isVisible() : true"
          mat-menu-item
          class="scope-option">
          <mat-icon class="material-symbols-rounded orange">{{ option.icon }}</mat-icon>
          <span>{{ option.name }}</span>
        </button>
      </ng-container>
    </mat-menu>
  </div>
</div>
<div class="card-wrapper p-10" [hidden]="!loadingRateCards">
  <div class="card-skeleton" *ngFor=" let x of [].constructor(16)"></div>
</div>
<div class="card-wrapper p-10">
  <scope-ui-card-master
    *ngFor="let rateCard of filteredRateCards | orderBy: 'name' : 'asc' : false; trackBy: trackById"
    (click)="selectRateCard(rateCard.rateCardIdentity.id)"
    [actionButtons]="[{
            icon: rateCard.rateCardIdentity.defaultCard ? 'check_circle' : 'delete',
            name: rateCard.rateCardIdentity.defaultCard ? 'Default' : 'Delete', disabled: rateCard.rateCardIdentity.defaultCard,
            eventId: 'Delete', hasPrivilege: 'RATECARD__DELETE', hasPrivilegeEntity: rateCard },
            { icon: 'content_copy', name: 'Duplicate', eventId: 'Duplicate', hasPrivilege: 'RATECARD__CREATE' }]"
    [hasStatus]="rateCard.rateCardIdentity.trafficImported"
    [status]="'TRAFFICKED'"
    [hasIcon]="rateCard.rateCardIdentity.trafficImported"
    [statusIcon]="'swap_vert'"
    [statusIconClass]="'trafficked'"
    [hasSubtitle]="isParentCard(rateCard, loggedInUser.company) || rateCard.rateCardIdentity.costPlus"
    [subTitle]="isParentCard(rateCard, loggedInUser.company) ? 'Inherited from parent' : 'Cost Plus'"
    [hasButtons]="true"
    [cardTitle]="rateCard.name"
    (buttonClicked)="$event === 'Delete' ? showDeleteRateCardModal(rateCard) : showDuplicateRateCardModal(rateCard)" />
</div>
