import { Type } from 'class-transformer';
import { User } from '@core/model/user.model';
import { ScenarioQuestion } from '@app/features/scope-overview/model/scenario-question.model';

export class ScenarioCategory {
  id!: number
  name!: string
  orderIndex!: number
  @Type(() => ScenarioQuestion) questions: ScenarioQuestion[] = []
  @Type(() => User) createdBy!: User
  @Type(() => Date) createdOn!: Date
  @Type(() => Date) updatedOn?: Date
}
