import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import {MenuOptions} from "@core/model/definitions/menu-options.interface";

@Component({
  selector: 'scope-ui-options-menu',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './scope-ui-options-menu.component.html',
  styleUrls: ['./scope-ui-options-menu.component.scss'],
})
export class ScopeUiOptionsMenuComponent {
  @Input() menuOptions!: MenuOptions[];
  @Input() buttonText?: string;
  @Input() toggleButton?: boolean;
  @Input() separatedMenu?: boolean;
  @Input() element?: any;
  @Input() parentElement?: any;
  @Output() onClickEvent = new EventEmitter<any>();

  onClick() {
    this.onClickEvent.emit();
  }
}
