export enum QuestionType {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  DATE = "DATE",
  DROPDOWN = "DROPDOWN",
  FORMULA = "FORMULA",
  DELIVERABLE = "DELIVERABLE"
}

export const QuestionTypeIcon = {
  [QuestionType.TEXT]: "insert_text",
  [QuestionType.DATE]: "calendar_today",
  [QuestionType.DROPDOWN]: "checklist",
  [QuestionType.FORMULA]: "calculate",
  [QuestionType.DELIVERABLE]: "description",
}

export const QuestionTypeImage = {
  [QuestionType.NUMBER]: "/assets/icons/insert-number.svg",
}