import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { ScopeActions, ScopesActionTypes } from '../actions/scoping.actions'
import { catchError, concatMap, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs'
import { MyScopesService } from '../../service/scoping.service'
import { ScopeParams } from '../model/scope-params.interface'
import { Store } from '@ngrx/store'
import { ScopingSelectors } from '../selectors/scoping.selector'
import { ScopeFilterDefaults } from '@core/model/scope-filter.model'
import { FolderVersion } from '@core/model/folder-version.model'
import { LanguageService } from '@core/service/language.service'
import {
  SnackbarEventType,
  SNACKBAR_LENGTH_LONG,
  SnackbarService,
  SNACKBAR_LENGTH_SHORT,
} from '@shared/utils/snackbar.service';
import { FindRolesSearchParams } from '@core/model/definitions/findRoleSearchParams.interface'
import { AuthSelectors } from '@core/store/selectors/auth.selector'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ScopeDynamicFieldSettingService } from '@app/features/scoping/service/scope-dynamic-field-setting.service';
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';
import { authorisedAsChildCompany } from '@app/shared/utils/utils'
dayjs.extend(utc)

@Injectable()
export class ScopingEffects {
  constructor(
    private actions$: Actions,
    private myScopesService: MyScopesService,
    private snackbarService: SnackbarService,
    private store: Store,
    private lang: LanguageService,
    private companyManagementService: CompanyManagementService,
    private dynamicFieldSettingService: ScopeDynamicFieldSettingService
  ) {}

  getMyScopes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ScopesActionTypes.GET_MY_SCOPES,
        ScopesActionTypes.FILTER_SCOPES_PAGE_INFO,
        ScopesActionTypes.FILTER_SCOPES_SORT,
        ScopesActionTypes.FILTER_SCOPES
      ),
      withLatestFrom(
        this.store.select(ScopingSelectors.selectPagination),
        this.store.select(ScopingSelectors.selectSort),
        this.store.select(ScopingSelectors.selectFilters)
      ),
      switchMap(([action, tableData, sort, filters]) => {
        const sortingParams = {
          ...tableData,
          orderByField: sort?.active,
          order: sort?.direction.toUpperCase(),
        }

        const { params } = action as { params: ScopeParams }
        const mergedParams = {
          ...params,
          ...sortingParams,
        }

        const scopeFilters = {
          ...filters,
          toReviewAndSignSelected: filters.toReviewAndSignSelected && !filters.isTemplate,
          approvedSelected: filters.approvedSelected && !filters.isTemplate,
          newScopeCreatedSelected: filters.newScopeCreatedSelected && !filters.isTemplate,
          newCreatedSince: filters.isTemplate ? null : filters.newCreatedSince,
          toReviewAndSignSince: filters.isTemplate ? null : filters.toReviewAndSignSince,
          approvedSince: filters.isTemplate ? null : filters.approvedSince,
          createdBy: authorisedAsChildCompany() ? [authorisedAsChildCompany()] : filters.createdBy,
        }

        return this.myScopesService.getMyScopes(scopeFilters, mergedParams).pipe(
          map((scopes) => {
            return ScopeActions.getMyScopesSuccess({ scopes })
          }),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading Scopes', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getMyScopesFail({ error }))
          })
        )
      })
    )
  })

  getArchivedScopes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ScopesActionTypes.GET_ARCHIVED_SCOPES,
        ScopesActionTypes.FILTER_ARCHIVED_SCOPES_PAGE_INFO,
        ScopesActionTypes.FILTER_ARCHIVED_SCOPES_SORT,
        ScopesActionTypes.FILTER_SCOPES
      ),
      withLatestFrom(
        this.store.select(ScopingSelectors.selectPaginationArchived),
        this.store.select(ScopingSelectors.selectSortArchived),
        this.store.select(ScopingSelectors.selectFilters)
      ),
      switchMap(([action, tableData, sort, filters]) => {
        const sortingParams = {
          ...tableData,
          orderByField: sort?.active,
          order: sort?.direction.toUpperCase(),
        }

        const { params } = action as { params: ScopeParams }
        const mergedParams = {
          ...params,
          ...sortingParams,
        }

        const scopeFilters = {
          ...filters,
          toReviewAndSignSelected: filters.toReviewAndSignSelected && !filters.isTemplate,
          approvedSelected: filters.approvedSelected && !filters.isTemplate,
          newScopeCreatedSelected: filters.newScopeCreatedSelected && !filters.isTemplate,
          newCreatedSince: filters.isTemplate ? null : filters.newCreatedSince,
          toReviewAndSignSince: filters.isTemplate ? null : filters.toReviewAndSignSince,
          approvedSince: filters.isTemplate ? null : filters.approvedSince,
          createdBy: authorisedAsChildCompany() ? [authorisedAsChildCompany()] : filters.createdBy,
          isArchived: true
        }

        return this.myScopesService.getMyScopes(scopeFilters, mergedParams).pipe(
          map((scopes) => {
            return ScopeActions.getArchivedScopesSuccess({ scopes })
          }),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading archived Scopes', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getArchivedScopesFail({ error }))
          })
        )
      })
    )
  })

  getFolders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ScopesActionTypes.GET_FOLDERS,
        ScopesActionTypes.FILTER_FOLDERS_PAGE_INFO,
        ScopesActionTypes.FILTER_FOLDERS_SORT,
        ScopesActionTypes.FILTER_FOLDERS
      ),
      withLatestFrom(
        this.store.select(ScopingSelectors.selectPagination),
        this.store.select(ScopingSelectors.selectSort),
        this.store.select(ScopingSelectors.selectFilters)
      ),
      switchMap(([action, tableData, sort, filters]) => {
        const sortingParams = {
          ...tableData,
          orderByField: sort?.active,
          order: sort?.direction.toUpperCase(),
        }

        const { params } = action as { params: ScopeParams }
        const mergedParams = {
          ...params,
          ...sortingParams,
        }

        return this.myScopesService.getFolders(filters, mergedParams).pipe(
          map((folders) => ScopeActions.getFoldersSuccess({ folders })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading Folders', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getFoldersFail({ error }))
          })
        )
      })
    )
  })

  getArchivedFolders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ScopesActionTypes.GET_ARCHIVED_FOLDERS,
        ScopesActionTypes.FILTER_ARCHIVED_FOLDERS_PAGE_INFO,
        ScopesActionTypes.FILTER_ARCHIVED_FOLDERS_SORT,
        ScopesActionTypes.FILTER_FOLDERS
      ),
      withLatestFrom(
        this.store.select(ScopingSelectors.selectPaginationArchived),
        this.store.select(ScopingSelectors.selectSortArchived),
        this.store.select(ScopingSelectors.selectFilters)
      ),
      switchMap(([action, tableData, sort, filters]) => {
        const sortingParams = {
          ...tableData,
          orderByField: sort?.active,
          order: sort?.direction.toUpperCase(),
        }

        const { params } = action as { params: ScopeParams }
        const mergedParams = {
          ...params,
          ...sortingParams,
        }

        return this.myScopesService.getFolders({ ...filters, isArchived: true }, mergedParams).pipe(
          map((folders) => ScopeActions.getArchivedFoldersSuccess({ folders })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading archived Folders', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getArchivedFoldersFail({ error }))
          })
        )
      })
    )
  })

  getAllFolders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.GET_ALL_FOLDERS),
      withLatestFrom(this.store.select(ScopingSelectors.selectSort)),
      switchMap(([action, sort]) => {
        const sortingParams = {
          page: 0,
          orderByField: sort?.active,
          order: sort?.direction.toUpperCase(),
        }

        const mergedParams = {
          page: 0,
          pageSize: 1000,
          ...sortingParams
        }

        return this.myScopesService.getFolders(ScopeFilterDefaults, mergedParams).pipe(
          map((page) => ScopeActions.getAllFoldersSuccess({ folders: page.content })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading Folders', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getAllFoldersFail({ error }))
          })
        )
      })
    )
  })

  getHighlights$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.GET_HIGHLIGHTS),
      withLatestFrom(this.store.select(AuthSelectors.selectUser)),
      switchMap(([action, user]) => {
        let lastLoginDate = dayjs.utc(new Date(1)).format()
        if (user && user.lastLoginDate) {
          lastLoginDate = dayjs.utc(user.lastLoginDate).format()
        }

        return this.myScopesService.getScopesHighlights(lastLoginDate).pipe(
          map((highlights) => ScopeActions.getHighlightsSuccess({ highlights })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading Highlights', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getHighlightsFail({ error }))
          })
        )
      })
    )
  })

  searchDeliverables$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.SEARCH_DELIVERABLES),
      switchMap((action: any) => {
        if (action.searchText === '') {
          return of(ScopeActions.searchDeliverablesSuccess({ deliverables: [] }));
        }
        return this.myScopesService.searchDeliverables(action.searchText).pipe(
          map((deliverables) => ScopeActions.searchDeliverablesSuccess({deliverables})),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during searching Deliverables', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.searchDeliverablesFail({error}))
          })
        );
      })
    );
  });

  searchComponents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.SEARCH_COMPONENTS),
      switchMap((action: any) => {
        if (action.searchText === '') {
          return of(ScopeActions.searchComponentsSuccess({ components: [] }));
        }
        return this.myScopesService.searchComponents(action.searchText).pipe(
          map((components) => ScopeActions.searchComponentsSuccess({components})),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during searching Components', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.searchComponentsFail({error}))
          })
        );
      })
    );
  });

  getDeliverableSummaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.GET_DELIVERABLE_SUMMARIES),
      mergeMap((action: any) => {
        return this.myScopesService.getDeliverableSummaries(action.scopeId).pipe(
          map((deliverables) => ScopeActions.getDeliverableSummariesSuccess({ scopeId: action.scopeId, deliverables })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading Deliverable Summaries', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getDeliverableSummariesFail({ error }))
          })
        )
      })
    )
  })

  getDeliverables$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.GET_DELIVERABLES),
      switchMap((action: any) => {
        return this.myScopesService.getDeliverables(action.scopeId).pipe(
          map((deliverables) => ScopeActions.getDeliverablesSuccess({ scopeId: action.scopeId, deliverables })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading Deliverable', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getDeliverablesFail({ error }))
          })
        )
      })
    )
  })

  getScopesByFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.GET_SCOPES_BY_FOLDER),
      switchMap((action: any) => {
        return this.myScopesService.getFolder(action.folderId).pipe(
          map((folder: FolderVersion) => {
            return ScopeActions.getScopesByFolderSuccess({ folderId: action.folderId, scopes: folder.scopeVersions })
          }),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(`An error occurred during loading Scopes in Folder (ID: ${action.folderId})`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getScopesByFolderFail({ error }))
          })
        )
      })
    )
  })

  archiveScope$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.ARCHIVE_SCOPE),
      switchMap((action: any) => {
        return this.myScopesService.archiveScope(action.scopeId).pipe(
          switchMap(() => {
            return [ScopeActions.getMyScopes({}), ScopeActions.getArchivedScopes({}), ScopeActions.archiveScopeSuccess({ scopeId: action.scopeId })]
          }),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(`An error occurred during archiving the Scope (ID: ${action.scopeId})`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.archiveScopeFail({ error }))
          })
        )
      })
    )
  })

  unarchiveScope$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.UNARCHIVE_SCOPE),
      switchMap((action: any) => {
        return this.myScopesService.unarchiveScope(action.scopeId).pipe(
          switchMap(() => {
            return [ScopeActions.getMyScopes({}), ScopeActions.getArchivedScopes({}), ScopeActions.unarchiveScopeSuccess({ scopeId: action.scopeId })]
          }),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(`An error occurred during un-archiving the Scope (ID: ${action.scopeId})`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.unarchiveScopeFail({ error }))
          })
        )
      })
    )
  })

  archiveSow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.ARCHIVE_SOW),
      switchMap((action: any) => {
        return this.myScopesService.archiveSow(action.sowId).pipe(
          switchMap(() => {
            return [ScopeActions.getFolders({}), ScopeActions.getArchivedFolders({}), ScopeActions.archiveSowSuccess({ sowId: action.sowId })]
          }),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(`An error occurred during archiving the Folder (ID: ${action.sowId})`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.archiveSowFail({ error }))
          })
        )
      })
    )
  })

  unarchiveSow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.UNARCHIVE_SOW),
      switchMap((action: any) => {
        return this.myScopesService.unarchiveSow(action.sowId).pipe(
          switchMap(() => {
            return [ScopeActions.getFolders({}), ScopeActions.getArchivedFolders({}), ScopeActions.unarchiveSowSuccess({ sowId: action.sowId })]
          }),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(`An error occurred during un-archiving the Folder (ID: ${action.sowId})`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.unarchiveSowFail({ error }))
          })
        )
      })
    )
  })

  closeScope$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.CLOSE_SCOPE),
      switchMap((action: any) => {
        return this.myScopesService.closeScope(action.scopeId, action.comment).pipe(
          switchMap(() => {
            return [ScopeActions.closeScopeSuccess({ scopeId: action.scopeId })]
          }),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(`An error occurred during closing the Scope (ID: ${action.scopeId})`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.closeScopeFail({ error }))
          })
        )
      })
    )
  })

  deleteScope$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.DELETE_SCOPE),
      switchMap((action: any) => {
        return this.myScopesService.deleteScope(action.scopeId).pipe(
          switchMap(() => {
            return [ScopeActions.getMyScopes({}), ScopeActions.getArchivedScopes({}), ScopeActions.deleteScopeSuccess({ scopeId: action.scopeId })]
          }),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(`An error occurred during deleting the Scope (ID: ${action.scopeId})`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.deleteScopeFail({ error }))
          })
        )
      })
    )
  })

  deleteFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.DELETE_FOLDER),
      switchMap((action: any) => {
        return this.myScopesService.deleteFolder(action.folderId).pipe(
          switchMap(() => {
            return [ScopeActions.getFolders({}), ScopeActions.getArchivedFolders({}), ScopeActions.deleteFolderSuccess({ folderId: action.folderId })]
          }),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(`An error occurred during deleting the Folder (ID: ${action.sowId})`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.deleteFolderFail({ error }))
          })
        )
      })
    )
  })

  getSecondParties$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.GET_SECOND_PARTIES),
      switchMap(() => {
        return this.myScopesService.getSecondPartiesForScopeCreation().pipe(
          map((secondParties) => ScopeActions.getSecondPartiesSuccess({ secondParties })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading Clients', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getSecondPartiesFail({ error }))
          })
        )
      })
    )
  })

  assignScopeToFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.ASSIGN_SCOPE_TO_FOLDER),
      switchMap((action: any) => {
        return this.myScopesService.assignScopeToFolder(action.scope.identity.id, action.folder.identity.id).pipe(
          switchMap(() => {
            return [ScopeActions.assignScopeToFolderSuccess({ scopeId: action.scope.identity.id, folder: action.folder })]
          }),
          catchError((error: any) => {
            if (error.error?.errorCode == 'RATECARD_MISMATCH') {
              this.snackbarService.showSnackbar(
                `Unable to move ${this.lang.get('scope|l')}: '${action.scope.name}' to ${this.lang.get('sow')}: '${action.folder.name}' as they do not share the same ratecard and ratecard version.` +
                ` Please add ${action.scope.identity.rateCard.name} ratecard to client to enable moving.`, SNACKBAR_LENGTH_LONG, SnackbarEventType.WARNING)
            } else {
              this.snackbarService.showSnackbar(`An error occurred during assigning the Scope (ID: ${action.scope.identity.id})`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            }
            return of(
              ScopeActions.assignScopeToFolderFail({
                error: new Error(`Failed to move single ${this.lang.get('scope|l')} to ${this.lang.get('sow')}`),
              })
            )
          })
        )
      })
    )
  })

  unassignScopeFromFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.UNASSIGN_SCOPE_FROM_FOLDER),
      switchMap((action: any) => {
        return this.myScopesService.unassignScopeFromFolder(action.scopeId).pipe(
          switchMap(() => {
            this.snackbarService.showSnackbar(`Action completed - ${this.lang.get('scope|l')} was moved out.`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
            return [
              ScopeActions.unassignScopeFromFolderSuccess({
                scopeId: action.scopeId
              }),
            ]
          }),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(`An error occurred during un-assigning the Scope (ID: ${action.scopeId})`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(
              ScopeActions.unassignScopeFromFolderFail({
                error: new Error(`Failed to move single ${this.lang.get('scope|l')} from ${this.lang.get('sow')}`),
              })
            )
          })
        )
      })
    )
  })

  findRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.FIND_ROLES),
      switchMap((action: { scopeId: string, searchParams?: FindRolesSearchParams }) => {
        return this.myScopesService.findRoles(action.scopeId, action.searchParams).pipe(
          map((roles) => ScopeActions.findRolesSuccess({ roles })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during searching Roles', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.findRolesFail({ error }))
          })
        )
      })
    )
  })

  getRateCardForScope$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.GET_RATECARD_BY_SCOPE),
      switchMap((action: { scopeId: string }) => {
        return this.myScopesService.getRateCardByScope(action.scopeId).pipe(
          map((ratecard) => ScopeActions.getRateCardForScopeSuccess({ ratecard })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading Ratecard for Scope', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getRateCardForScopeFail({ error }))
          })
        )
      })
    )
  })

  getRateCard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.GET_RATECARD),
      switchMap((action: { rateCardId: number }) => {
        return this.companyManagementService.getRateCard(action.rateCardId).pipe(
          map((ratecard) => ScopeActions.getRateCardSuccess({ ratecard })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading Ratecard', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getRateCardFail({ error }))
          })
        )
      })
    )
  })

  getOfficeLocations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.GET_OFFICE_LOCATIONS),
      switchMap(() => {
        return this.myScopesService.getOfficeLocations().pipe(
          map((locations) => ScopeActions.getOfficeLocationsSuccess({ locations })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading Locations', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getOfficeLocationsFail({ error }))
          })
        )
      })
    )
  })

  getScopeTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.GET_SCOPE_TEMPLATES),
      switchMap(() => {
        return this.myScopesService.getScopeTemplates().pipe(
          map((scopeTemplates) => ScopeActions.getScopeTemplatesSuccess({ scopeTemplates })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading Scope Templates', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getScopeTemplatesFail({ error }))
          })
        )
      })
    )
  })

  getWordTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.GET_WORD_TEMPLATES),
      switchMap(() => {
        return this.companyManagementService.getScopeWordTemplates().pipe(
          map((wordTemplates) => ScopeActions.getWordOutputTemplatesSuccess({ wordTemplates })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading Word Templates', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getWordOutputTemplatesFail({ error }))
          })
        )
      })
    )
  })

  getXlsxTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.GET_XLSX_TEMPLATES),
      switchMap(() => {
        return this.companyManagementService.getAllXlsxTemplates().pipe(
          map((xlsxTemplates) => ScopeActions.getXlsxOutputTemplatesSuccess({ xlsxTemplates })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading Excel Templates', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getXlsxOutputTemplatesFail({ error }))
          })
        )
      })
    )
  })

  getScopeTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.GET_SCOPE_TYPES),
      switchMap(() => {
        return this.myScopesService.getScopeTypes().pipe(
          map((scopeTypes) => ScopeActions.getScopeTypesSuccess({ scopeTypes })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading Scope Types', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getScopeTypesFail({ error }))
          })
        )
      })
    )
  })

  getCustomFields$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.GET_CUSTOM_FIELDS),
      switchMap(() => {
        return this.myScopesService.initiateScopeVersionCustomFields().pipe(
          map((customFields) => ScopeActions.getCustomFieldsSuccess({ customFields })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading Custom Fields', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getCustomFieldsFail({ error }))
          })
        )
      })
    )
  })

  getTrafficSystem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.GET_TRAFFIC_SYSTEM),
      switchMap(() => {
        return this.myScopesService.initiateTrafficSystem().pipe(
          map((trafficSystem) => ScopeActions.getTrafficSystemSuccess({ trafficSystem })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during loading Traffic System', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.getTrafficSystemFail({ error }))
          })
        )
      })
    )
  })

  replaceRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.REPLACE_ROLES),
      concatMap(({ scopeId, roles, selectedRoleId, selectedRoleReplaceRoleId }) => {
        return this.myScopesService.replaceRoles(scopeId, roles, selectedRoleId, selectedRoleReplaceRoleId).pipe(
          map((response) => ScopeActions.replaceRolesSuccess({ response })),
          catchError((error) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during replacing the Roles', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.replaceRolesFail({ error }))
          })
        )
      })
    )
  })

  revertRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.REVERT_REPLACEMENTS),
      concatMap(({ scopeId, revertId}) => {
        return this.myScopesService.revertRoles(scopeId, revertId).pipe(
          map((revertResponse) => ScopeActions.revertReplacementRolesSuccess({ revertResponse })),
          catchError((error) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred during revert action', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeActions.revertReplacementRolesFail({ error }))
          })
        )
      })
    )
  })

  getAllFeeItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopesActionTypes.GET_ALL_FEE_ITEMS),
      withLatestFrom(this.store.select(AuthSelectors.selectUser)),
      switchMap(([action, user]) => {
        if (user.hasPrivilege('FEE_ITEM__VIEW')) {
          return this.myScopesService.getAllFeeItems().pipe(
            map((feeItems: any[]) => ScopeActions.getAllFeeItemsSuccess({ feeItems })),
            catchError((error: any) => {
              console.error(error)
              this.snackbarService.showSnackbar('An error occurred during loading Company Fees', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
              return of(ScopeActions.getAllFeeItemsFail({ error }))
            })
          )
        } else {
          return of([]).pipe(
            map((feeItems: any[]) => ScopeActions.getAllFeeItemsSuccess({ feeItems }))
          )
        }
      })
    )
  })

  getDynamicFieldSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeActions.getDynamicFieldSettings),
      switchMap(() => {
        return this.dynamicFieldSettingService.getAll().pipe(
          map((settings) => ScopeActions.getDynamicFieldSettingsSuccess({ settings })),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during loading dynamic field settings',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            );
            return of(ScopeActions.getDynamicFieldSettingsFail({ error }));
          })
        );
      })
    );
  });

  createDynamicFieldSetting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeActions.createDynamicFieldSetting),
      switchMap(({ setting }) => {
        return this.dynamicFieldSettingService.create(setting).pipe(
          map((newSetting) => ScopeActions.createDynamicFieldSettingSuccess({ setting: newSetting })),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during creating dynamic field setting',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            );
            return of(ScopeActions.createDynamicFieldSettingFail({ error }));
          })
        );
      })
    );
  });

  updateDynamicFieldSetting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeActions.updateDynamicFieldSetting),
      switchMap(({ setting }) => {
        return this.dynamicFieldSettingService.update(setting.id, setting).pipe(
          map((updatedSetting) => ScopeActions.updateDynamicFieldSettingSuccess({ setting: updatedSetting })),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during updating dynamic field setting',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            );
            return of(ScopeActions.updateDynamicFieldSettingFail({ error }));
          })
        );
      })
    );
  });

  deleteDynamicFieldSetting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeActions.deleteDynamicFieldSetting),
      switchMap(({ id }) => {
        return this.dynamicFieldSettingService.delete(id).pipe(
          map(() => ScopeActions.deleteDynamicFieldSettingSuccess({ id })),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during deleting dynamic field setting',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            );
            return of(ScopeActions.deleteDynamicFieldSettingFail({ error }));
          })
        );
      })
    );
  });

}
