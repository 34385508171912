<loader-modal *ngIf="(isLoading$ | async) || (isLoadingArchive$ | async) || (isLoadingColumns$ | async)"></loader-modal>
<div class="top-filters-container" [class.collapsed]='!showMainFilters'>
  <div>
    <h4 class="product-heading-bold">Top filters</h4>
    <button class="right text-link" (click)="toggleMainFilters()">
      <mat-icon class="material-symbols-rounded orange">{{ showMainFilters ? 'visibility' : 'visibility_off' }}</mat-icon
      >{{ showMainFilters ? 'Hide top filters' : 'Show Panel' }}
    </button>
  </div>
  <div>
    <div class="inner-container w-full" *ngIf="showMainFilters">
      <scope-ui-filter-card
        [icon_name]="'close'"
        [card_data]="(highlights$ | async)?.toReviewAndSign"
        [heading]="('scope.p' | lang) + ' to review and approve'"
        [sub_heading]="loggedInUser.lastLoginDate | dateFormat : 'shortDate'"
        [card_type]="'toReviewAndSign'"
        [cardActive]="cardActiveStates['toReviewAndSign']"
        (cardClicked)="handleCardClick($event)" />
      <scope-ui-filter-card
        [icon_name]="'close'"
        [card_data]="(highlights$ | async)?.created"
        [heading]="'New ' + ('scope.p' | lang) + ' have been created'"
        [sub_heading]="loggedInUser.lastLoginDate | dateFormat : 'shortDate'"
        [card_type]="'created'"
        [cardActive]="cardActiveStates['created']"
        (cardClicked)="handleCardClick($event)" />
      <scope-ui-filter-card
        [icon_name]="'close'"
        [card_data]="(highlights$ | async)?.approved"
        [heading]="('scope.p' | lang) + ' have been approved'"
        [sub_heading]="loggedInUser.lastLoginDate | dateFormat : 'shortDate'"
        [card_type]="'approved'"
        [cardActive]="cardActiveStates['approved']"
        (cardClicked)="handleCardClick($event)" />
    </div>
  </div>
</div>
<div class="scope-container">
  <div class="flex flex-row justify-end scope-sub-container items-center">
    <span *ngIf="getDisplayFilters().length > 0 || filters.name" class="filtered-count flex-1">{{(scopeTableConfig$ | async)?.totalCount}} results{{ filters.name ? ' for "' + filters.name + '"' : '' }}</span>
    <scope-ui-input
      [initialValue]='filters.name'
      [icon]="'search'"
      [cancelIcon]="'cancel'"
      [inputLabel]="'Search'"
      [inputClass]="'search-field'"
      (onCancel)="onCancelPressed()"
      (onEnter)="onSearchKeyPressed($event)" />
    <button class="ml-4 mr-4 text-link" aria-label="Show filters" (click)="onSelectFilters()">
      Show filters
      <mat-icon class="material-symbols-rounded orange">arrow_forward</mat-icon>
    </button>
    <mat-divider class="top-0 inline-block" vertical></mat-divider>
    <scope-ui-options-menu
      class="ml-4"
      [menuOptions]="menuOptions"
      [buttonText]="'Create New'" />
  </div>
  <div class="scope-sub-container">
    <mat-divider/>
  </div>
  <div class="flex flex-wrap scope-sub-container">
    <ng-container *ngIf="getDisplayFilters() as displayFilters">
      <ng-container *ngFor="let filter of displayFilters">
        <div class="filter-tag">
          <span>{{scopesFilterConfig[filter[0]]}} </span>
          <span class="tag-value">{{Array.isArray(filter[1]) ? filter[1]?.join(', ') : filter[1]?.toString()}}</span><mat-icon class="material-symbols-rounded" (click)="removeFilter(filter[0])">close</mat-icon></div>
      </ng-container>
      <button *ngIf="displayFilters.length > 0" mat-button class="clear-all-tag" aria-label="Clear all filters" (click)="resetFilters()">
        Clear all
        <mat-icon iconPositionEnd class="material-symbols-rounded orange">close</mat-icon>
      </button>
    </ng-container>
  </div>
  <scope-ui-tabs (onSelect)="selectTab($event, true)" (onInit)="selectTab($event)" [fontSize]="'24px'" class="pt-4 scope-tabs">
    <scope-ui-tab tabTitle="{{('scope.p' | lang)}}">
      <div class="mt-6 mb-6 scope-sub-container" [hidden]="!(isLoaded$ | async) || (isLoadingColumns$ | async)">
        <ng-container class="py-3" *ngIf="scopeTableConfig$ | async as tableData">
          <scope-ui-table
            (pageChange)="onChangePage($event)"
            (onSort)="onSort($event)"
            (onToggle)="onToggleScopePreference($event)"
            (onExpand)="onExpand($event)"
            (onSelect)="onSelect($event)"
            [loggedInUser]="loggedInUser"
            [data]="mappedScopesDataSource$ | async"
            [displayedColumns]="columns$ | async"
            [addToggleListColumn]="true"
            [totalCount]="tableData?.totalCount"
            [page]="tableData?.page"
            [tableClass]="'row-gaps'"
            [rowGaps]="true"
            [expandable]="true"
            [selectMode]="ScopeUiTableSelectMode.NAME"
            [menuOptions]="scopeMenuOptions">
            <ng-template let-element>
              <scope-ui-table
                *ngIf="deliverablesByScopeLoading$ | async as loadingMap"
                class="w-full"
                tableClass="nested"
                [isSubTable]="true"
                [condensed]="true"
                [loggedInUser]="loggedInUser"
                [loading]="loadingMap[element.entity.identity.id]"
                [unmappedData]="element.children$ | async"
                [displayedColumns]="deliverableColumns"
                [noPreferences]="true"
                [noPagination]="true"
                [noSort]="true"
                [loaderRowsAmount]="0"
                [showToggleMenu]="false"
                [addToggleListColumn]="true"
                [editAction]="editDeliverableAction"
                [selectMode]="element.entity.isEditable ? ScopeUiTableSelectMode.ROW : ScopeUiTableSelectMode.NONE"
                (onSelect)="onDeliverableClick($event.entity)">
              </scope-ui-table>
            </ng-template>
          </scope-ui-table>
        </ng-container>
        <button
          *ngIf="!showArchive && (archivedScopeTableConfig$ | async)?.totalCount"
          class='text-link' [class.archive-btn]="(scopeTableConfig$ | async)?.totalCount"
          aria-label="View Archive" (click)="showArchive = true">
          View Archive ({{(archivedScopeTableConfig$ | async)?.totalCount}})
          <mat-icon class="material-symbols-rounded orange">expand_more</mat-icon>
        </button>
        <button
          *ngIf="showArchive"
           class='text-link' [class.archive-btn]="(scopeTableConfig$ | async)?.totalCount"
          aria-label="View Archive" (click)="showArchive = false">
          Hide Archive ({{(archivedScopeTableConfig$ | async)?.totalCount}})
          <mat-icon class="material-symbols-rounded orange">expand_less</mat-icon>
        </button>
      </div>
      <div *ngIf="showArchive" class="archive-container" [hidden]="!(isLoaded$ | async) || !(isLoadedArchive$ | async) || (isLoadingColumns$ | async)">
        <div class="scope-sub-container">
          <ng-container class="py-3" *ngIf="archivedScopeTableConfig$ | async as tableData">
            <scope-ui-table
              (pageChange)="onChangePageArchived($event)"
              (onSort)="onSortArchived($event)"
              (onToggle)="onToggleScopePreference($event)"
              (onExpand)="onExpand($event)"
              (onSelect)="onSelect($event)"
              [loggedInUser]='loggedInUser'
              [data]="mappedArchivedScopesDataSource$ | async"
              [displayedColumns]="columns$ | async"
              [addToggleListColumn]="true"
              [totalCount]="tableData?.totalCount"
              [page]="tableData?.page"
              [tableClass]="'row-gaps'"
              [rowGaps]="true"
              [expandable]="true"
              [selectMode]="ScopeUiTableSelectMode.NAME"
              [menuOptions]="scopeMenuOptions">
              <ng-template let-element>
                <scope-ui-table
                  class="w-full"
                  tableClass="nested"
                  [isSubTable]="true"
                  [condensed]="true"
                  [loggedInUser]="loggedInUser"
                  [unmappedData]="element.children$ | async"
                  [displayedColumns]="deliverableColumns"
                  [noPreferences]="true"
                  [noPagination]="true"
                  [noSort]="true">
                </scope-ui-table>
              </ng-template>
            </scope-ui-table>
          </ng-container>
          <button
            class='text-link' [class.archive-btn]="(archivedScopeTableConfig$ | async)?.totalCount"
            aria-label="View Archive" (click)="showArchive = false">
            Hide Archive
            <mat-icon class="material-symbols-rounded orange">expand_less</mat-icon>
          </button>
        </div>
      </div>
    </scope-ui-tab>
    <scope-ui-tab tabTitle="{{('scope_of_work.p' | lang)}}" [requiredSetting]="'SOW__GLOBAL'" [active]='route.snapshot.queryParamMap.get("tab") === "folders"'>
      <div class="mt-6 mb-6 scope-sub-container" [hidden]="!(isLoaded$ | async) || (isLoadingColumns$ | async)">
        <ng-container class="py-3" *ngIf="scopeTableConfig$ | async as tableData">
          <scope-ui-table
            (pageChange)="onChangePage($event)"
            (onSort)="onSort($event)"
            (onToggle)="onToggleScopePreference($event)"
            (onExpand)="onExpand($event)"
            (onSelect)="onSelect($event)"
            [loggedInUser]='loggedInUser'
            [data]="mappedFoldersDataSource$ | async"
            [displayedColumns]="folderColumns$ | async"
            [addToggleListColumn]="true"
            [totalCount]="tableData?.totalCount"
            [page]="tableData?.page"
            [rowGaps]="true"
            [expandable]="true"
            [selectMode]="ScopeUiTableSelectMode.NAME"
            [menuOptions]="folderMenuOptions"
            [trackById]="trackById">
            <ng-template let-element let-sort="sort">
              <scope-ui-table
                tableClass="folder-table nested"
                [isSubTable]="true"
                [condensed]="true"
                [data]="element.children$ | async"
                [displayedColumns]="folderColumns$ | async"
                [addToggleListColumn]="true"
                [loggedInUser]='loggedInUser'
                [noPagination]="true"
                [noPreferences]="true"
                [expandable]="false"
                [hideHeader]="true"
                [sort]="sort"
                [menuOptions]="folderScopeMenuOptions"></scope-ui-table>
            </ng-template>
          </scope-ui-table>
        </ng-container>
        <button
          *ngIf="!showArchive && (archivedScopeTableConfig$ | async)?.totalCount"
          class='text-link' [class.archive-btn]="(scopeTableConfig$ | async)?.totalCount"
          aria-label="View Archive" (click)="showArchive = true">
          View Archive ({{(archivedScopeTableConfig$ | async)?.totalCount}})
          <mat-icon class="material-symbols-rounded orange">expand_more</mat-icon>
        </button>
        <button
          *ngIf="showArchive"
          class='text-link' [class.archive-btn]="(scopeTableConfig$ | async)?.totalCount"
          aria-label="View Archive" (click)="showArchive = false">
          Hide Archive ({{(archivedScopeTableConfig$ | async)?.totalCount}})
          <mat-icon class="material-symbols-rounded orange">expand_less</mat-icon>
        </button>
      </div>
      <div *ngIf="showArchive" class="archive-container" [hidden]="!(isLoaded$ | async) || !(isLoadedArchive$ | async) || (isLoadingColumns$ | async)">
        <div class="scope-sub-container">
          <ng-container class="py-3" *ngIf="archivedScopeTableConfig$ | async as tableData">
            <scope-ui-table
              (pageChange)="onChangePageArchived($event)"
              (onSort)="onSortArchived($event)"
              (onToggle)="onToggleScopePreference($event)"
              (onExpand)="onExpand($event)"
              (onSelect)="onSelect($event)"
              [loggedInUser]='loggedInUser'
              [data]="mappedArchivedFoldersDataSource$ | async"
              [displayedColumns]="folderColumns$ | async"
              [addToggleListColumn]="true"
              [totalCount]="tableData?.totalCount"
              [page]="tableData?.page"
              [rowGaps]="true"
              [expandable]="true"
              [selectMode]="ScopeUiTableSelectMode.NAME"
              [menuOptions]="folderMenuOptions">
              <ng-template let-element let-sort="sort">
                <scope-ui-table
                  tableClass="folder-table nested"
                  [isSubTable]="true"
                  [condensed]="true"
                  [data]="element.children$ | async"
                  [displayedColumns]="folderColumns$ | async"
                  [loggedInUser]='loggedInUser'
                  [noPagination]="true"
                  [noPreferences]="true"
                  [showToggleMenu]="false"
                  [expandable]="false"
                  [hideHeader]="true"
                  [sort]="sort"></scope-ui-table>
              </ng-template>
            </scope-ui-table>
          </ng-container>
          <button
            class='text-link' [class.archive-btn]="(archivedScopeTableConfig$ | async)?.totalCount"
            aria-label="View Archive" (click)="showArchive = false">
            Hide Archive
            <mat-icon class="material-symbols-rounded orange">expand_less</mat-icon>
          </button>
        </div>
      </div>
    </scope-ui-tab>
    <scope-ui-tab tabTitle="Templates" [active]='route.snapshot.queryParamMap.get("tab") === "templates"'>
      <div class="mt-6 mb-6 scope-sub-container" [hidden]="!(isLoaded$ | async) || (isLoadingColumns$ | async)">
        <ng-container class="py-3" *ngIf="scopeTableConfig$ | async as tableData">
          <scope-ui-table
            (pageChange)="onChangePage($event)"
            (onSort)="onSort($event)"
            (onToggle)="onToggleScopePreference($event)"
            (onExpand)="onExpand($event)"
            (onSelect)="onSelect($event)"
            [loggedInUser]='loggedInUser'
            [data]="mappedScopesDataSource$ | async"
            [displayedColumns]="templateColumns$ | async"
            [addToggleListColumn]="true"
            [totalCount]="tableData?.totalCount"
            [page]="tableData?.page"
            [tableClass]="'row-gaps'"
            [rowGaps]="true"
            [expandable]="true"
            [selectMode]="ScopeUiTableSelectMode.NAME"
            [menuOptions]="scopeMenuOptions">
            <ng-template let-element>
              <scope-ui-table
                class="w-full"
                tableClass="nested"
                [isSubTable]="true"
                [condensed]="true"
                [loggedInUser]="loggedInUser"
                [unmappedData]="element.children$ | async"
                [displayedColumns]="deliverableColumns"
                [noPreferences]="true"
                [noPagination]="true"
                [noSort]="true"
                [showToggleMenu]="false"
                [addToggleListColumn]="true"
                [editAction]="editDeliverableAction"
                [selectMode]="element.entity.isEditable ? ScopeUiTableSelectMode.ROW : ScopeUiTableSelectMode.NONE"
                (onSelect)="onDeliverableClick($event.entity)">
              </scope-ui-table>
            </ng-template>
          </scope-ui-table>
        </ng-container>
        <button
          *ngIf="!showArchive && (archivedScopeTableConfig$ | async)?.totalCount"
          class='text-link' [class.archive-btn]="(scopeTableConfig$ | async)?.totalCount"
          aria-label="View Archive" (click)="showArchive = true">
          View Archive ({{(archivedScopeTableConfig$ | async)?.totalCount}})
          <mat-icon class="material-symbols-rounded orange">expand_more</mat-icon>
        </button>
        <button
          *ngIf="showArchive"
          class='text-link' [class.archive-btn]="(scopeTableConfig$ | async)?.totalCount"
          aria-label="View Archive" (click)="showArchive = false">
          Hide Archive ({{(archivedScopeTableConfig$ | async)?.totalCount}})
          <mat-icon class="material-symbols-rounded orange">expand_less</mat-icon>
        </button>
      </div>
      <div *ngIf="showArchive" class="archive-container" [hidden]="!(isLoaded$ | async) || !(isLoadedArchive$ | async) || (isLoadingColumns$ | async)">
        <div class="scope-sub-container">
          <ng-container class="py-3" *ngIf="archivedScopeTableConfig$ | async as tableData">
            <scope-ui-table
              (pageChange)="onChangePageArchived($event)"
              (onSort)="onSortArchived($event)"
              (onToggle)="onToggleScopePreference($event)"
              (onExpand)="onExpand($event)"
              (onSelect)="onSelect($event)"
              [loggedInUser]='loggedInUser'
              [data]="mappedArchivedScopesDataSource$ | async"
              [displayedColumns]="templateColumns$ | async"
              [addToggleListColumn]="true"
              [totalCount]="tableData?.totalCount"
              [page]="tableData?.page"
              [tableClass]="'row-gaps'"
              [rowGaps]="true"
              [expandable]="true"
              [selectMode]="ScopeUiTableSelectMode.NAME"
              [menuOptions]="scopeMenuOptions">
              <ng-template let-element>
                <scope-ui-table
                  class="w-full"
                  tableClass="nested"
                  [isSubTable]="true"
                  [condensed]="true"
                  [loggedInUser]="loggedInUser"
                  [unmappedData]="element.children$ | async"
                  [displayedColumns]="deliverableColumns"
                  [noPreferences]="true"
                  [noPagination]="true"
                  [noSort]="true">
                </scope-ui-table>
              </ng-template>
            </scope-ui-table>
          </ng-container>
          <button
            class='text-link' [class.archive-btn]="(archivedScopeTableConfig$ | async)?.totalCount"
            aria-label="View Archive" (click)="showArchive = false">
            Hide Archive
            <mat-icon class="material-symbols-rounded orange">expand_less</mat-icon>
          </button>
        </div>
      </div>
    </scope-ui-tab>
  </scope-ui-tabs>
</div>
