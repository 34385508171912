<div
  [class.trading-in-progress]="
    deliverable.deliveryStatus === 'TRADING_FROM_IN_PROGRESS' || deliverable.deliveryStatus === 'TRADING_TO_IN_PROGRESS'
  "
  class="deliverable-content items-center h-10"
  style="width: 100%"
  (click)="fetchDeliverable()">
  <div class="column level-{{level}}" [ngClass]="mainColumnClass">
    <div class="flex items-center">
      <mat-icon
        [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'"
        class="arrow orange material-symbols-rounded"
        [ngClass]="{ active: scopeTabService.deliverableSelectedStates[deliverable.id] }"
        >expand_more</mat-icon
      >
      <i [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'" class="description material-symbols-rounded"
        >{{ deliverable.fixedPricing ? 'book' : 'description'}}</i
      >

      <commentable [c-if]="scopeVersion.hasPrivilegeValueRestrictionByScopeValue(Privilege.SCOPE_COMMENT__CREATE, currentUser)"  [c-entity]="deliverable" [c-type]="'deliverable'" [c-key]="'name'" [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'">
        {{ deliverable.name }}
        <small class="text" *ngIf="deliverable.tradedFrom">(Traded for {{deliverable.tradedFrom.name}})</small>
      </commentable>
      <div class="comments-total-count-icon" title="Roll-up Comments Count" *ngIf="!scopeApprovalFlowService.isStateDraft(scopeVersion.status) && getCommentsCount() > 0">
        <div class="comments-count">{{getCommentsCount()}}</div>
      </div>
    </div>
  </div>
  <div class="column one--column" [hidden]="!userColumns.DELIVERY_STATUS?.isVisible()">
    <div class="text center scope-status" >
      <span class="ultra-grey" *ngIf="deliverable.deliveryStatus == null">N/A</span>
      <span class="red" *ngIf="deliverable.deliveryStatus == 'ON_HOLD'">ON HOLD</span>
      <span class="green" *ngIf="deliverable.deliveryStatus == 'IN_PROGRESS'">IN PROGRESS</span>
      <span class="yellow" *ngIf="deliverable.deliveryStatus == 'COMPLETE'">COMPLETE</span>
      <span class="grey" matTooltip="This {{'deliverable|l' | lang }} has been traded" *ngIf="deliverable.deliveryStatus == 'TRADED'">TRADED</span>
      <span class="red" matTooltip="This {{'deliverable|l' | lang }} is being traded; awaiting confirmation." *ngIf="deliverable.isBeingTraded()">TRADING FROM</span>
      <span class="red" matTooltip="This {{'deliverable|l' | lang }} is being traded for '{{deliverable.tradedFrom?.name}}'. Edit the {{'deliverable|l' | lang }} before confirming trade." *ngIf="deliverable.isPendingTrade()">TRADING TO</span>
    </div>
  </div>

  <div class="column one--column" [hidden]="!userColumns.CREATED_BY?.selected">
    <span [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'">{{
      deliverable.createdBy.getFullname()
    }}</span>
  </div>
  <div class="column one--column" [hidden]="!userColumns.OWNER?.selected">
    <span [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'">{{ deliverable.owner.getFullname() }}</span>
  </div>
  <div class="column one--column" [hidden]="!userColumns.DATE_CREATED?.selected">
    <span [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'">{{
      deliverable.createdTs | dateFormat: 'shortDate'
    }}</span>
  </div>
  <div class="column one--column" [hidden]="!userColumns.QUANTITY?.selected">
    <scope-ui-counter [hidden]="!scopeApprovalFlowService.isScopeEditable(scopeVersion)"
      [initialValue]="deliverable.quantity"
      [min]="1"
      (onUpdateValue)="onUpdateQuantity($event)"></scope-ui-counter>
    <span [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'" [hidden]="scopeApprovalFlowService.isScopeEditable(scopeVersion)">
      {{ deliverable.quantity }}
    </span>
  </div>
  <div class="column one--column" [hidden]="!(userColumns.START_DATE?.selected && userColumns.START_DATE.isVisibleInList())">
    <span [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'">{{
      deliverable.startDate | dateFormat: 'shortDate'
    }}</span>
  </div>
  <div class="column one--column" [hidden]="!userColumns.LAST_EDITED?.selected">
    <span [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'">{{
      deliverable.updatedTs | dateFormat: 'shortDate'
    }}</span>
  </div>
  <div class="column one--column" [hidden]="!(userColumns.END_DATE?.selected && userColumns.END_DATE.isVisibleInList())">
    <span [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'">{{
      deliverable.endDate | dateFormat: 'shortDate'
    }}</span>
  </div>
  <div class="column one--column" [hidden]="!userColumns.MARK_UP?.selected">
    <span [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'">{{ deliverable.getMarkup() }}%</span>
  </div>
  <div class="column one--column" [hidden]="!userColumns.PROFIT?.selected">
    <span [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'">{{
      deliverable.getTotalProfit().amount | currency : deliverable.getTotalProfit().currency
    }}</span>
  </div>
  <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_HOURS?.selected">
    <commentable [c-if]="scopeVersion.hasPrivilegeValueRestrictionByScopeValue(Privilege.SCOPE_COMMENT__CREATE, currentUser)" [c-entity]="deliverable" [c-key]="'scopeMarkTotalHours'" [c-type]="'deliverable'" [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'">{{ deliverable.scopeMarkTotalHours | formatHours }}</commentable>
  </div>
  <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_PRICE?.selected">
    <commentable [c-if]="scopeVersion.hasPrivilegeValueRestrictionByScopeValue(Privilege.SCOPE_COMMENT__CREATE, currentUser)" [c-entity]="deliverable" [c-key]="'scopeMarkTotalSellingPrice'" [c-type]="'deliverable'" [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'">{{
      deliverable.scopeMarkTotalSellingPrice ? (deliverable.scopeMarkTotalSellingPrice.amount | currency : deliverable.scopeMarkTotalSellingPrice.currency) : '--'
      }}</commentable>
  </div>
  <div class="column {{isTimeline ? 'three--columns' : 'one--column'}}" [hidden]="!isTimeline && !userColumns.AGENCY_HOURS?.selected">
    <commentable [c-if]="scopeVersion.hasPrivilegeValueRestrictionByScopeValue(Privilege.SCOPE_COMMENT__CREATE, currentUser)" [c-entity]="deliverable" [c-key]="'rateCardTotalHours'" [c-type]="'deliverable'" [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'">{{ deliverable.totalRateCardHours | formatHours }}</commentable>
  </div>
  <div class="column one--column" [hidden]="!userColumns.BUDGET?.selected">
    <commentable [c-if]="scopeVersion.hasPrivilegeValueRestrictionByScopeValue(Privilege.SCOPE_COMMENT__CREATE, currentUser)" [c-entity]="deliverable" [c-key]="'budget'" [c-type]="'deliverable'" [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'">{{ deliverable.budget?.amount | currency : deliverable.budget?.currency }}</commentable>
  </div>
  <div class="column one--column" [hidden]="!userColumns.BALANCE?.selected">
    <commentable [c-if]="scopeVersion.hasPrivilegeValueRestrictionByScopeValue(Privilege.SCOPE_COMMENT__CREATE, currentUser)" [c-entity]="deliverable" [c-key]="'budgetBalance'" [c-type]="'deliverable'" [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'">{{
        deliverable.budgetBalance != null ? (deliverable.budgetBalance.amount | currency : deliverable.budgetBalance.currency) : '--'
    }}</commentable>
  </div>
  <div class="column {{isTimeline ? 'three--columns' : 'one--column'}}" [hidden]="!isTimeline && !userColumns.AGENCY_PRICE?.selected">
    <span *ngIf="deliverable.containsUnmappedScopeMarkRoles"
          class='inline-block'
          [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'">--</span>
    <commentable [c-entity]="deliverable" [c-key]="'agencyTotalPrice'" [c-type]="'deliverable'"
                 [has-privilege]="'SCOPE__VIEW_RATES'"
                 [has-privilege-mask]="'--'"
                 [c-if]="scopeVersion.hasPrivilegeValueRestrictionByScopeValue(Privilege.SCOPE_COMMENT__CREATE, currentUser)"
                 *ngIf="!deliverable.containsUnmappedScopeMarkRoles && deliverable.getTotalSellingPrice() as totalSellingPrice"
                 class='inline-block'
                 [class.trade-confirmed]="deliverable.deliveryStatus === 'TRADED'"
    >{{totalSellingPrice.amount != null ? (totalSellingPrice.amount | currency : totalSellingPrice.currency) : '--'}}
    </commentable>
    <mat-icon *ngIf='isTimeline && !deliverable.containsUnmappedScopeMarkRoles && deliverable.getTotalSellingPrice().amount == 0' class="error material-symbols-rounded"
      matTooltip='Empty deliverable. Edit to add components'>error</mat-icon>
    <mat-icon *ngIf='isTimeline && deliverable.containsUnmappedScopeMarkRoles' class="error material-symbols-rounded"
      matTooltip="This {{'deliverable|l' | lang}} has roles which do not exist in your ratecard">error</mat-icon>
  </div>

  <div class="column one--column">
    <div class="flex flex-row justify-end">
      <button class="toggle-button float-right ml-1"
              *ngIf="((scopeVersion.status === 'DRAFT' && scopeApprovalFlowService.isScopeEditable(scopeVersion)) ||
                (deliverable.isPendingTrade() &&
                currentUser.company.hasApplicationSetting('SCOPE__DELIVERABLE__TRADING') &&
                currentUser.hasPrivilege('SCOPE_DELIVERABLE__TRADE'))) && !isTimeline"
              (click)="editDeliverable(deliverable)" mat-mini-fab>
        <mat-icon class="material-symbols-rounded fill">mode_edit</mat-icon>
      </button>
      <button class="toggle-button float-right ml-1 {{isTimeline ? 'mr-1' : 'mr-2'}}"
              *ngIf="showToggleMenu()"
              (click)="$event.stopPropagation()"
              mat-mini-fab
              [matMenuTriggerFor]="deliverableMenu">
        <mat-icon class="material-symbols-rounded">more_horiz</mat-icon>
      </button>
    </div>
  </div>
  <mat-menu #deliverableMenu="matMenu" backdropClass="customize">
    <button *ngIf="((scopeVersion.status === 'DRAFT' && scopeApprovalFlowService.isScopeEditable(scopeVersion)) ||
                (deliverable.isPendingTrade() &&
                currentUser.company.hasApplicationSetting('SCOPE__DELIVERABLE__TRADING') &&
                currentUser.hasPrivilege('SCOPE_DELIVERABLE__TRADE'))) && isTimeline"
            mat-menu-item (click)="editDeliverable(deliverable)" >
      <mat-icon class="material-symbols-rounded orange">mode_edit</mat-icon>
      <span>Edit {{('deliverable' | lang)}}</span>
    </button>
    <button *ngIf="scopeApprovalFlowService.isScopeEditable(scopeVersion)" mat-menu-item (click)="duplicateDeliverable(deliverable)">
      <mat-icon class="material-symbols-rounded orange">content_copy</mat-icon>
      <span>Duplicate</span>
    </button>
    <button *ngIf="scopeApprovalFlowService.isScopeEditable(scopeVersion) || (deliverable.isPendingTrade() && scopeVersion.findDeliverableById(deliverable.tradedFrom.id).tradesCount > 1)"
            mat-menu-item (click)="deleteDeliverable(deliverable)">
      <mat-icon class="material-symbols-rounded orange">delete</mat-icon>
      <span>Delete</span>
    </button>
    <button *ngIf="scopeApprovalFlowService.isScopeEditable(scopeVersion) && hasLocationCards(currentRatecard) && currentUser.company.hasApplicationSetting('RATECARD_LOCATION__ENABLE')"
            mat-menu-item (click)="showDeliverableApplyLocationCardDialog(); $event.stopPropagation()">
      <mat-icon class="material-symbols-rounded orange rotate_90">sync_alt</mat-icon>
      <span>Apply location card</span>
    </button>
    <button *ngIf="scopeApprovalFlowService.isScopeEditable(scopeVersion) || (deliverable.isPendingTrade() && scopeVersion.findDeliverableById(deliverable.tradedFrom.id).tradesCount > 1)"
            mat-menu-item (click)="addDeliverableFee()"
            [has-setting]="'DELIVERABLE__ENABLE_FEES'" [has-privilege]="'SCOPE__CREATE_FEES'">
      <mat-icon class="material-symbols-rounded orange">attach_money</mat-icon>
      <span>Add fee</span>
    </button>
    <!-- Track and Trade Actions -->
    <button
      mat-menu-item
      *ngIf="deliverable.deliveryStatus == 'IN_PROGRESS' && scopeVersion.isStateApproved() &&
        scopeApprovalFlowService.stateChecks.deliverableStatus.isApprover &&
        scopeApprovalFlowService.stateChecks.deliverableStatus.isStateApproved"
      [has-privilege]="'SCOPE_DELIVERABLE__COMPLETE'"
      (click)="completeTrade(deliverable)">
      <mat-icon class="material-symbols-rounded orange">done</mat-icon>
      <span>Complete</span>
    </button>

    <button *ngIf="deliverable.deliveryStatus == 'IN_PROGRESS' && scopeVersion.isStateApproved() &&
        scopeApprovalFlowService.stateChecks.deliverableStatus.isApprover &&
        scopeApprovalFlowService.stateChecks.deliverableStatus.isStateApproved"
      mat-menu-item
      [has-setting]="'SCOPE__DELIVERABLE__TRADING'"
      [has-privilege]="'SCOPE_DELIVERABLE__TRADE'"
      (click)="tradeDeliverable(deliverable)">
      <mat-icon class="material-symbols-rounded orange">sync_alt</mat-icon>
      <span>Trade</span>
    </button>

    <button
      *ngIf="deliverable.isBeingTraded()"
      [has-setting]="'SCOPE__DELIVERABLE__TRADING'"
      [has-privilege]="'SCOPE_DELIVERABLE__TRADE'"
      (click)="addDeliverableToTrade(deliverable)"
      mat-menu-item>
      <mat-icon class="material-symbols-rounded orange">swap_horiz</mat-icon>
      <span>Add to this trade</span>
    </button>

    <button
      *ngIf="deliverable.isBeingTraded()"
      [has-setting]="'SCOPE__DELIVERABLE__TRADING'"
      [has-privilege]="'SCOPE_DELIVERABLE__TRADE'"
      (click)="confirmTrade(deliverable)"
      mat-menu-item>
      <mat-icon class="material-symbols-rounded orange">done_all</mat-icon>
      <span>Confirm Trade</span>
    </button>

    <button
      mat-menu-item
      *ngIf="deliverable.isBeingTraded()"
      [has-setting]="'SCOPE__DELIVERABLE__TRADING'"
      [has-privilege]="'SCOPE_DELIVERABLE__TRADE'"
      (click)="cancelTrade(deliverable)"
      >
      <mat-icon class="material-symbols-rounded orange">cancel</mat-icon>
      <span>Cancel Trade</span>
    </button>

    <button
      *ngIf="deliverable.deliveryStatus == 'IN_PROGRESS' && scopeVersion.isStateApproved() &&
        scopeApprovalFlowService.stateChecks.deliverableStatus.isApprover &&
        scopeApprovalFlowService.stateChecks.deliverableStatus.isStateApproved"
      [has-privilege]="'SCOPE_DELIVERABLE__STOP'"
      (click)="stopProgressTrade(deliverable)"
      mat-menu-item>
      <mat-icon class="material-symbols-rounded orange">crop_square</mat-icon>
      <span>Stop Progress</span>
    </button>

    <button
      mat-menu-item
      *ngIf="deliverable.deliveryStatus == 'ON_HOLD' &&
        scopeApprovalFlowService.stateChecks.deliverableStatus.isStateApproved &&
        scopeApprovalFlowService.stateChecks.deliverableStatus.isApprover"
      [has-privilege]="'SCOPE_DELIVERABLE__STOP'"
      (click)="reopenTrade(deliverable)">
      <mat-icon class="material-symbols-rounded orange">play_circle</mat-icon>
      <span>Reopen</span>
    </button>
  </mat-menu>
</div>

<div class="flex flex-col w-full description-container" *ngIf='!isTimeline'>
  <description-editor
    class="level-{{level}}"
    [(visible)]="scopeTabService.deliverableDescriptionStates[deliverable.id]"
    [model]="deliverable"
    [editable]='scopeApprovalFlowService.isScopeEditable(scopeVersion)'
    [(disableDragging)]="scopeTabService.disableDragging"
    (onSaveDescription)="saveDeliverableDescription($event)"
    (onSaveNote)="saveDeliverableInternalNote($event)">
  </description-editor>

  <!-- Accept/Reject Deliverables -->
  <div class="deliverable-review-container"
       *ngIf="scopeApprovalFlowService.stateChecks.deliverableStatus.deliverablesInReview &&
          scopeApprovalFlowService.stateChecks.isCurrentVersion &&
          scopeApprovalFlowService.stateChecks.inReviewState.currentMember">
    <div class="deliverable-review-collaborators">
      <div class="approval-collaborators-list flex flex-col gap-1">
        <div class="flex items-center gap-0.5">
          <mat-icon class="material-symbols-rounded">people</mat-icon>
          <h6 class="product-heading-bold">Collaborators</h6>
        </div>
        <div class="flex gap-2">
          <div class="approval-collaborators-list-item" *ngFor="let collaborator of scopeVersion.collaborators | orderBy: 'id'; trackBy: trackById">
            <ng-container *ngIf="{ showAlt: false } as memberScope">
              <div *ngIf='memberScope.showAlt' class="user-avatar-placeholder">
                <p>{{ collaborator.user.firstName.charAt(0) }}</p>
              </div>
              <img *ngIf='!memberScope.showAlt' class="user-avatar-placeholder" src="{{cdnConfig!.userProfileUrl + '/' + collaborator.user.photoUri}}" alt="" (error)="memberScope.showAlt = true">
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="scopeApprovalFlowService.stateChecks.deliverableStatus.deliverablesInReview" class="deliverable-review-collaborators">
      <div class="approval-collaborators-list flex flex-col gap-1">
        <div class="flex items-center gap-0.5">
          <mat-icon class="material-symbols-rounded">eyeglasses</mat-icon>
          <h6 class="product-heading-bold">Reviewers</h6>
        </div>
        <div class="flex gap-2">
          <div class="approval-collaborators-list-item" *ngFor="let reviewer of deliverable.reviewers | orderBy: 'id'; trackBy: trackById">
            <ng-container *ngIf="{ showAlt: false } as memberScope">
              <i *ngIf="reviewer.vote === 'ACCEPT'" class="material-symbols-rounded accepted">done</i>
              <i *ngIf="reviewer.vote === 'DECLINE'" class="material-symbols-rounded rejected">close</i>
              <div *ngIf='memberScope.showAlt' class="user-avatar-placeholder">
                <p>{{ reviewer.voter.firstName.charAt(0) }}</p>
              </div>
              <img *ngIf='!memberScope.showAlt' class="user-avatar-placeholder" src="{{cdnConfig!.userProfileUrl + '/' + reviewer.voter.photoUri}}" alt="" (error)="memberScope.showAlt = true">
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="scopeApprovalFlowService.stateChecks.deliverableStatus.deliverablesInReview && scopeVersion.status === 'SUBMITTED'"
      class="deliverable-review-actions">
      <ng-container *ngIf="this.scopeApprovalFlowService.findDeliverableReviewer(this.currentUser.id, this.deliverable) as deliverableReviewer">
        <button
          [class.deliverable-accepted]="deliverableReviewer?.vote === 'ACCEPT'"
          [class.disabled]="!(deliverableReviewer?.vote === 'ACCEPT' || deliverableReviewer?.vote !== null)"
          [disabled]="deliverableReviewer?.vote === 'ACCEPT'"
          (click)="acceptDeliverable(deliverable)">
          {{ deliverableReviewer?.vote === 'ACCEPT' ? 'Accepted' : 'Accept' }}
        </button>
        <button
          [class.deliverable-rejected]="deliverableReviewer?.vote === 'DECLINE'"
          [disabled]="deliverableReviewer?.vote === 'DECLINE' || deliverableReviewer?.vote === 'ACCEPT'"
          [class.disabled]="!(deliverableReviewer?.vote === 'DECLINE' || deliverableReviewer?.vote !== null)"
          (click)="rejectDeliverable(deliverable)">
          {{ deliverableReviewer?.vote === 'DECLINE' ? 'Rejected' : 'Reject' }}
        </button>
      </ng-container>
    </div>
  </div>
</div>

<div    cdkDropList
        id="deliverbale-{{ deliverable.id }}"
        [cdkDropListData]="deliverable.components"
        [cdkDropListConnectedTo]="deliverableDrops"
        [cdkDropListDisabled]="!scopeApprovalFlowService.isScopeEditable(scopeVersion)"
        (cdkDropListDropped)="dropComponent($event, deliverable)"
        [hidden]="!(scopeTabService.deliverableSelectedStates[deliverable.id] && deliverable.componentsInitialised && (deliverable.sections.length || deliverable.components.length))" class="flex flex-col row-content">
  <div class="component-header flex items-center align-middle" [hidden]='isTimeline || !deliverable.defaultSectionComponents'>
    <div class="column level-{{level + 1}} {{ componentMainColumnClass }}">
      <span isEllipsisActive>{{ 'component|u' | lang }} NAME</span>
    </div>
    <div class="column one--column" [hidden]="!userColumns.START_DATE?.selected && scopeTabService.userColumns.START_DATE.isVisibleInList()">
      <span>Start Date</span>
    </div>
    <div class="column one--column" [hidden]="!userColumns.END_DATE?.selected && scopeTabService.userColumns.END_DATE.isVisibleInList()">
      <span>End Date</span>
    </div>
    <div class="column one--column">
      <span>Quantity</span>
    </div>
    <div class="column one--column">
      <span>{{ 'complexity' | lang }}</span>
    </div>
    <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_HOURS?.selected">
      <span isEllipsisActive>{{ 'scope_mark' | lang }}<br />hours</span>
    </div>
    <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_PRICE?.selected">
      <span isEllipsisActive>{{ 'scope_mark' | lang }}<br />price</span>
    </div>
    <div class="column one--column" [hidden]="!userColumns.AGENCY_HOURS?.selected">
      <span isEllipsisActive>{{ 'agency|p' | lang }}<br />hours</span>
    </div>
    <div class='column one--column' [hidden]='!userColumns.MARK_UP?.selected'>
      <span>Mark-up</span>
    </div>
    <div class="column one--column" [hidden]="!userColumns.AGENCY_PRICE?.selected">
      <span isEllipsisActive *ngIf="!scopeVersion.identity.costPlus">{{ 'agency' | lang }} <br /> price</span>
      <span isEllipsisActive *ngIf="scopeVersion.identity.costPlus">Cost plus <br /> price</span>
    </div>
    <div class="column one--column"></div>
  </div>

  <scope-component-row
    *ngFor="let component of deliverable.defaultSectionComponents | orderBy: 'order'; trackBy: trackById"
    [component]='component'
    cdkDrag
    [cdkDragData]="component"
    (cdkDragStarted)="componentDragging = true"
    (cdkDragEnded)="componentDragging = false; dragTargetId = null;"
    [isTimeline]='isTimeline'
    [currentUser]="currentUser"
    [scopeVersion]="scopeVersion"
    [deliverable]="deliverable"
    [userColumns]="isTimeline ? scopeTabService.timelineColumns : scopeTabService.userColumns"
    [componentMainColumnClass]='componentMainColumnClass'
    [componentFeeColumns]="scopeTabService.componentFeeColumns"
    [feeMenuOptions]="feeMenuOptions"
    [level]="level+1"
    [componentSelectedStates]="scopeTabService.componentSelectedStates$ | async"
    [departmentSelectedStates]="scopeTabService.departmentSelectedStates$ | async"
    (onUpdateComponent)="updateComponent($event)"
    (onAddComponentFee)="addComponentFee($event)"
    (onUpdateFeeItem)="updateFeeItem($event.feeItem, $event.deliverable, $event.component)"
    (onOverrideQuantity)="overrideQuantity($event)"
    (onOverrideRateCardRoleHours)="overrideRateCardRoleHours($event)">
  </scope-component-row>

  <div class="component-header flex items-center align-middle" [hidden]='!deliverable.sections?.length || isTimeline'>
    <div class="column level-{{level+1}} five--columns">
      <span>SECTION</span>
    </div>
    <div class="column one--column">
      <span>Start date</span>
    </div>
    <div class="column one--column">
      <span>End date</span>
    </div>
    <div class="column one--column">
      <span isEllipsisActive>3rd party cost</span>
    </div>
    <div class="column one--column">
      <span isEllipsisActive>{{ 'scope_mark' | lang }}<br />total</span>
    </div>
    <div class="column one--column"></div>
    <div class="column one--column">
      <span isEllipsisActive>{{ 'agency' | lang }}<br />total</span>
    </div>
    <div class="column one--column"></div>
  </div>

  <deliverable-section-row
    *ngFor="let section of deliverable.sections; trackBy: trackById"
    [section]='section'
    [isTimeline]='isTimeline'
    [currentUser]="currentUser"
    [scopeVersion]="scopeVersion"
    [deliverable]="deliverable"
    [userColumns]="isTimeline ? scopeTabService.timelineColumns : scopeTabService.userColumns"
    [componentMainColumnClass]='componentMainColumnClass'
    [componentFeeColumns]="scopeTabService.componentFeeColumns"
    [feeMenuOptions]="feeMenuOptions"
    [level]="level+1"
    [deliverableSectionSelectedStates]="scopeTabService.deliverableSectionSelectedStates$ | async"
    (onUpdateComponent)="updateComponent($event)"
    (onAddComponentFee)="addComponentFee($event)"
    (onUpdateFeeItem)="updateFeeItem($event.feeItem, $event.deliverable, $event.component)"
    (onOverrideQuantity)="overrideQuantity($event)"
    (onOverrideRateCardRoleHours)="overrideRateCardRoleHours($event)">
  </deliverable-section-row>
  <div class="component-header flex items-center align-middle" [has-setting]="'SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS'"
       *ngIf="scopeTabService.userColumns.SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS.selected"
       [hidden]="!(scopeTabService.deliverableSelectedStates[deliverable.id] && deliverable.componentsInitialised && (deliverable.sections.length || deliverable.components.length))">
    <div class="column level-{{level+1}} {{ componentMainColumnClass }}">
      <span isEllipsisActive>{{ 'deliverable|u' | lang }} ACTUALS</span>
    </div>
    <div class="column four--columns">
      <span isEllipsisActive>Complete percentage: {{ deliverable.traffickedPercentageComplete }}%</span>
    </div>
    <div class="column two--columns" [hidden]="!userColumns.SCOPEMARK_PRICE?.selected"></div>
    <div class="column two--columns" [hidden]="!userColumns.AGENCY_PRICE?.selected">
      <span class="flex items-center">Actuals: {{ deliverable.getActualHours() }} hrs</span>
    </div>
    <div class="column two--columns"></div>
  </div>

</div>

<div *ngIf="deliverable.thirdPartyCosts?.length" [hidden]="!scopeTabService.deliverableSelectedStates[deliverable.id] || isTimeline">
  <scope-ui-table
    [loggedInUser]="currentUser"
    [tableClass]="'tpc-table nested'"
    [headerExpandable]="false"
    [unmappedData]="deliverable.thirdPartyCosts | orderBy: 'id'"
    [displayedColumns]="scopeTabService.thirdPartyCostColumns"
    [noSort]="true"
    [noPagination]="true"
    [noPreferences]="true"
    [showToggleMenu]="false"
    [expandable]="false"
    [showFooterRow]="false"
    [parentEntity]="deliverable"
    [level]="level+1"
    [(disableDragging)]="scopeTabService.disableDragging">
  </scope-ui-table>
</div>

<div *ngIf="deliverable.feeItems?.length" [hidden]="!(scopeTabService.deliverableSelectedStates[deliverable.id] && deliverable.componentsInitialised) || isTimeline">
  <scope-ui-table
    [has-setting]="'DELIVERABLE__ENABLE_FEES'"
    [has-privilege]="'SCOPE__VIEW_FEES'"
    [loggedInUser]='currentUser'
    [tableClass]="'fees-table nested'"
    [headerExpandable]="true"
    [unmappedData]="deliverable.feeItems | orderBy: 'entity.id'"
    [displayedColumns]="deliverableFeeColumns"
    [noSort]="true"
    [noPagination]="true"
    [noPreferences]="true"
    [expandable]="false"
    [showToggleMenu]="feeMenuOptions.length > 0"
    [menuOptions]="feeMenuOptions"
    (updateFeeItem)="updateFeeItem($event, deliverable)"
    [showFooterRow]="true"
    [parentEntity]="deliverable"
    [level]="level+1"
    [(tableExpanded)]="scopeTabService.deliverableFeeTableStates[deliverable.id]"
    [(disableDragging)]="scopeTabService.disableDragging">
  </scope-ui-table>
</div>
