<div class="top-panel">
  <div class="justify-start">
    <button class="text-link" aria-label="Back to Templates gallery" (click)="backToScenarios()">
      <mat-icon class="material-symbols-rounded orange">chevron_left</mat-icon>
      Back to Scenario Pricing Configurator
    </button>
  </div>
</div>
<div class='flex d-flex-justify-between scenario-header-container items-center'>
  <div class='scope-overview-header-title'>
    <div class='scope-name' *ngIf="(scenario$ | async) as scenario">
      <h3 class='product-heading-bold' *ngIf='!editScenarioName'>{{scenario?.name}}</h3>
      <mat-icon (click)='editScenarioName = true; $event.stopPropagation()' *ngIf='!editScenarioName'
                class='material-symbols-rounded fill orange text-base ml-1.5 pointer'>mode_edit
      </mat-icon>
      <scope-ui-input
        *ngIf='editScenarioName'
        class='edit-name'
        (click)='$event.stopPropagation()'
        [initialValue]='scenario.name'
        [autofocus]='true'
        (onInputBlurChange)='renameScenario($event, scenario)'
      />
    </div>
  </div>
  <div class='flex gap-1 items-center'>
    <scope-ui-input
      class='mr-2'
      [icon]="'search'"
      [cancelIcon]="'cancel'"
      [inputLabel]="'Search'"
      [inputClass]="'search-field'"
      (onCancel)="searchText = ''; filterCategories()"
      (onInputChange)='searchText = $event; filterCategories()' />
    <button (click)='openCreateModal()' mat-flat-button>
      <mat-icon class='material-symbols-rounded'>add</mat-icon>
      Create Category
    </button>
  </div>
</div>
<div class='scenario-container'>
  <ng-container class='py-3'>
    <scope-ui-table
      tableClass="shrunk-table"
      [loggedInUser]="loggedInUser"
      [data]="mappedCategoriesDataSource$ | async"
      [loading]="loading$ | async"
      [displayedColumns]="categoryPreferenceColumns"
      [menuOptions]="categoryMenuOptions"
      [noPagination]="true"
      [noPreferences]="true"
      [rowGaps]="true"
      [expandable]="true"
      [showExpandAll]="true"
      [showToggleMenu]="true"
      [addToggleListColumn]="true"
      [enableDragging]='true'
      [checkDropLocation]="checkCategoryDependencies"
      (orderChange)='reorderCategory($event)'>
      <ng-template let-element>
        <ng-container *ngIf="element.entity as category">
          <scope-ui-table
            tableClass="nested condensed"
            [isSubTable]="true"
            [loggedInUser]="loggedInUser"
            [loading]="loading$ | async"
            [unmappedData]="category.questions | orderBy: 'orderIndex'"
            [displayedColumns]="questionsPreferenceColumns"
            [menuOptions]="questionsMenuOptions"
            [noPagination]="true"
            [noPreferences]="true"
            [showToggleMenu]="true"
            [addToggleListColumn]="true"
            [condensed]="true"
            [parentEntity]="category"
            [selectMode]="ScopeUiTableSelectMode.ROW"
            [enableDragging]='true'
            [checkDropLocation]="checkQuestionDependencies"
            [lineClamp]="2"
            (orderChange)='reorderQuestion($event, category)'
            (onSelect)="editQuestion($event, category)">
          </scope-ui-table>
        </ng-container>
      </ng-template>
    </scope-ui-table>
  </ng-container>
</div>
