import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MenuOptions } from '@core/model/definitions/menu-options.interface'
import { ScopeVersion, StatusType } from '@core/model/scope-version'
import { ScopePlanTypeFeature } from '@core/model/enums/scope-plan-type.enum'
import { privilegeFilter, untilDestroyed } from '@shared/utils/utils'
import { User } from '@core/model/user.model'
import { MatDialogModule } from '@angular/material/dialog'
import { ScopeUiOptionsMenuComponent } from '@shared/components/scope-ui-options-menu/scope-ui-options-menu.component'
import { AsyncPipe, NgIf } from '@angular/common'
import { ScopeUiTableComponent } from '@shared/components/ui-components/scope-ui-table/scope-ui-table.component'
import { Preference } from '@core/model/user-preferences.interface'
import { map, Observable } from 'rxjs'
import { MappedEntity } from '@app/features/scoping/models/mapped-entity.model'
import { DataMappingService } from '@app/features/scoping/service/data-mapping.service'
import { ScopeTeamMember } from '@core/model/scope-team.model'
import { PipesModule } from '@shared/pipe/index.module'
import { CdnConfig } from '@core/model/cdn-config.model'
import { cloneDeep } from 'lodash'
import { TableColumnKey } from '@app/shared/components/ui-components/scope-ui-table/table-column-key.enum'

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
  imports: [ScopeUiOptionsMenuComponent, MatDialogModule, AsyncPipe, ScopeUiTableComponent, PipesModule, NgIf],
  standalone: true,
})
export class TeamComponent {
  private readonly destroy$

  @Input() loggedInUser!: User
  @Input() currentScope!: ScopeVersion
  @Input() currentScope$!: Observable<ScopeVersion>
  @Input() cdnConfig!: CdnConfig
  @Output() onShowAddCollaboratorModal: EventEmitter<any>
  @Output() onShowAddReviewerModal: EventEmitter<any>
  @Output() onShowAddApproverModal: EventEmitter<any>
  @Output() onShowAddTraffickerModal: EventEmitter<any>
  @Output() onUpdateTeamMember: EventEmitter<any>
  @Output() onDeleteCollaborator: EventEmitter<any>
  @Output() onDeleteReviewer: EventEmitter<any>
  @Output() onDeleteApprover: EventEmitter<any>
  @Output() onDeleteTrafficker: EventEmitter<any>

  menuOptions!: MenuOptions[]
  mappedCollaboratorsDataSource$!: Observable<MappedEntity<ScopeTeamMember>[]>
  mappedReviewersDataSource$!: Observable<MappedEntity<ScopeTeamMember>[]>
  mappedApproversDataSource$!: Observable<MappedEntity<ScopeTeamMember>[]>
  mappedTraffickersDataSource$!: Observable<MappedEntity<ScopeTeamMember>[]>
  collaboratorColumns!: Preference[]
  collaboratorColumnKeys!: string[]
  reviewerColumns!: Preference[]
  reviewerColumnKeys!: string[]
  approverColumns!: Preference[]
  approverColumnKeys!: string[]
  traffickerColumns!: Preference[]
  traffickerColumnKeys!: string[]
  collaboratorDeleteAction!: any
  reviewerDeleteAction!: any
  approverDeleteAction!: any
  traffickerDeleteAction!: any

  constructor(private mappingService: DataMappingService) {
    this.destroy$ = untilDestroyed()
    this.onShowAddCollaboratorModal = new EventEmitter<any>()
    this.onShowAddReviewerModal = new EventEmitter<any>()
    this.onShowAddApproverModal = new EventEmitter<any>()
    this.onShowAddTraffickerModal = new EventEmitter<any>()
    this.onUpdateTeamMember = new EventEmitter<any>()
    this.onDeleteCollaborator = new EventEmitter<any>()
    this.onDeleteReviewer = new EventEmitter<any>()
    this.onDeleteApprover = new EventEmitter<any>()
    this.onDeleteTrafficker = new EventEmitter<any>()
  }

  ngOnInit() {
    this.setMenu()
    this.collaboratorColumns = this.setColumns('Collaborator')
    this.reviewerColumns = this.setColumns('Reviewer')
    this.approverColumns = this.setColumns('Approver')
    this.traffickerColumns = this.setColumns('Trafficker')
    this.collaboratorColumnKeys = this.collaboratorColumns.map((column) => column.key)
    this.reviewerColumnKeys = this.reviewerColumns.map((column) => column.key)
    this.approverColumnKeys = this.approverColumns.map((column) => column.key)
    this.traffickerColumnKeys = this.traffickerColumns.map((column) => column.key)
    this.mapCollaboratorConfigToDataSource()
    this.mapReviewerConfigToDataSource()
    this.mapApproverConfigToDataSource()
    this.mapTraffickerConfigToDataSource()
    this.setDeleteActions()
  }

  setColumns(type: string) {
    let icon
    switch (type) {
      case 'Reviewer':
        icon = 'eyeglasses'
        break
      case 'Approver':
        icon = 'approval_delegation'
        break
      case 'Trafficker':
        icon = 'swap_vert'
        break
      default:
        icon = 'people'
    }
    let columns: Preference[] = [
      {
        key: TableColumnKey.NAME,
        name: `${type.toUpperCase()}S`,
        field: 'user.fullName',
        imageFunction: (member: ScopeTeamMember) => member.user.getPhotoUri(this.cdnConfig.userProfileUrl),
        icon: icon,
        selected: true,
      },
    ]
    if (type !== 'Trafficker') {
      columns.push({
        key: TableColumnKey.ROLE,
        name: 'Role',
        selected: true,
        value: (member: ScopeTeamMember) => {
          if (member.superVoter)
            return {
              name: 'Super ' + type,
              value: true,
            }
          return {
            name: type,
            value: false,
          }
        },
        isDisabled: () => {
          return !this.currentScope!.identity.team.hasUser(this.loggedInUser!.id)
        },
        options: [
          {
            name: type,
            value: false,
          },
          {
            name: 'Super ' + type,
            value: true,
          },
        ],
        onChange: (event: { value: any; element: ScopeTeamMember }) => {
          event.element.superVoter = event.value.value
          this.onUpdateTeamMember.emit(event.element)
        },
      })
    }

    // TODO - currently no data to display in this column
    // columns.push({
    //   key: 'ACTIVITY',
    //   name: 'Last seen (Activity)',
    //   field: 'lastSeenTs',
    //   selected: true
    // });
    return columns
  }

  setDeleteActions() {
    this.collaboratorDeleteAction = {
      showOptionFunc: () => {
        return (
          this.currentScope!.identity.team.hasUser(this.loggedInUser!.id) &&
          this.currentScope!.identity.team.collaborators.length > 1 &&
          !this.currentScope!.archived && this.currentScope!.isDraftOrConfigDraft()
        )
      },
      clickAction: (member: ScopeTeamMember) => {
        this.onDeleteCollaborator.emit(member);
      },
      buttonType: 'icon'
    }

    this.reviewerDeleteAction = {
      showOptionFunc: () => {
        return (
          this.currentScope!.identity.team.hasUser(this.loggedInUser!.id) &&
          this.currentScope!.identity.team.reviewers.length > 1 && !this.currentScope!.archived &&
          (this.currentScope!.isDraftOrConfigDraft() || this.currentScope!.status === StatusType.SUBMITTED)
        )
      },
      clickAction: (member: ScopeTeamMember) => {
        this.onDeleteReviewer.emit(member);
      },
      buttonType: 'icon'
    }

    this.approverDeleteAction = {
      showOptionFunc: () => {
        return (
          this.currentScope!.identity.team.hasUser(this.loggedInUser!.id) &&
          this.currentScope!.identity.team.approvers.length > 1 &&
          !this.currentScope!.archived &&
          (this.currentScope!.isDraftOrConfigDraft() || this.currentScope!.status === StatusType.SUBMITTED ||
            this.currentScope!.status === StatusType.REVIEWED || this.currentScope!.status === StatusType.AGENCY_APPROVED)
        )
      },
      clickAction: (member: ScopeTeamMember) => {
        this.onDeleteApprover.emit(member);
      },
      buttonType: 'icon'
    }

    this.traffickerDeleteAction = {
      showOptionFunc: () => {
        return (
          this.currentScope!.identity.team.hasUser(this.loggedInUser!.id) &&
          this.currentScope!.identity.team.traffickers.length > 1 &&
          !this.currentScope!.archived &&
          (this.currentScope!.isDraftOrConfigDraft() || this.currentScope!.status === StatusType.SUBMITTED ||
            this.currentScope!.status === StatusType.REVIEWED ||
            this.currentScope!.status === StatusType.CLIENT_APPROVED ||
            this.currentScope!.status === StatusType.AGENCY_APPROVED)
        )
      },
      clickAction: (member: ScopeTeamMember) => {
        this.onDeleteTrafficker.emit(member);
      },
      buttonType: 'icon'
    }
  }

  mapCollaboratorConfigToDataSource() {
    this.mappedCollaboratorsDataSource$ = this.currentScope$.pipe(
      map((scope: ScopeVersion) => {
        return this.mappingService.transformArray(cloneDeep(scope.identity.team.collaborators), this.collaboratorColumns);
      })
    )
  }

  mapReviewerConfigToDataSource() {
    this.mappedReviewersDataSource$ = this.currentScope$.pipe(
      map((scope: ScopeVersion) => {
        return this.mappingService.transformArray(cloneDeep(scope.identity.team.reviewers), this.collaboratorColumns);
      })
    )
  }

  mapApproverConfigToDataSource() {
    this.mappedApproversDataSource$ = this.currentScope$.pipe(
      map((scope: ScopeVersion) => {
        return this.mappingService.transformArray(cloneDeep(scope.identity.team.approvers), this.collaboratorColumns);
      })
    )
  }

  mapTraffickerConfigToDataSource() {
    this.mappedTraffickersDataSource$ = this.currentScope$.pipe(
      map((scope: ScopeVersion) => {
        return this.mappingService.transformArray(cloneDeep(scope.identity.team.traffickers), this.collaboratorColumns);
      })
    )
  }

  setMenu() {
    this.menuOptions = privilegeFilter(this.loggedInUser!, [
      {
        icon: () => 'people',
        name: () => 'Collaborator',
        isHidden: () => {
          return !this.currentScope!.isDraftOrConfigDraft() || this.currentScope!.archived
        },
        callback: () => {
          this.onShowAddCollaboratorModal.emit()
        },
      },
      {
        icon: () => 'eyeglasses',
        name: () => 'Reviewer',
        hasFeature: ScopePlanTypeFeature.SCOPE_APPROVALS,
        isHidden: () => {
          return !(this.currentScope!.isDraftOrConfigDraft() || this.currentScope!.status === StatusType.SUBMITTED) ||
            this.currentScope!.archived
        },
        callback: () => {
          this.onShowAddReviewerModal.emit()
        },
      },
      {
        icon: () => 'approval_delegation',
        name: () => 'Approver',
        hasFeature: ScopePlanTypeFeature.SCOPE_APPROVALS,
        isHidden: () => {
          return !(this.currentScope!.isDraftOrConfigDraft() || this.currentScope!.status === StatusType.SUBMITTED ||
            this.currentScope!.status === StatusType.REVIEWED ||
            this.currentScope!.status === StatusType.AGENCY_APPROVED) || this.currentScope!.archived
        },
        callback: () => {
          this.onShowAddApproverModal.emit()
        },
      },
      {
        icon: () => 'swap_vert',
        name: () => 'Trafficker',
        isHidden: () => {
          return (
            !(this.currentScope!.traffickable || this.currentScope!.traffickableThroughWorkato) ||
            !(this.currentScope!.isDraftOrConfigDraft() || this.currentScope!.status === StatusType.SUBMITTED ||
              this.currentScope!.status === StatusType.REVIEWED ||
              this.currentScope!.status === StatusType.CLIENT_APPROVED ||
              this.currentScope!.status === StatusType.AGENCY_APPROVED) || this.currentScope!.archived
          )
        },
        callback: () => {
          this.onShowAddTraffickerModal.emit()
        },
      },
    ])
  }

  isVisibleOptions(opts: MenuOptions[]){
    return opts.some(function(o){
      return !o.isHidden?.();
    });
  }

  isMenuVisible() {
    if (!this.currentScope || !this.loggedInUser){
      return false
    }
    if (!this.currentScope.identity.team.hasUser(this.loggedInUser.id)) {
      return false
    }
    return this.menuOptions ? this.menuOptions.length > 0 && this.isVisibleOptions(this.menuOptions) : true
  }

  protected readonly ScopePlanTypeFeature = ScopePlanTypeFeature
}
