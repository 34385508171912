<div class="details-container p-10">
  <div class="details-wrapper flex flex-col">
    <div class="flex flex-col">
      <h4 class="product-heading-bold flex-row">Update your {{'scope|l' | lang }} details</h4>
      <mat-divider></mat-divider>
      <div class="flex-row pt-8">
        <scope-ui-input
          [inputLabel]="('scope' | lang) + ' name'"
          [initialValue]="currentScope.name"
          [required]="true"
          [isDisabled]="!currentScope.isDraftOrConfigDraft()"
          (onInputChange)="updateScopeRequest.name = $event" />
        <p>The name of your {{'scope|l' | lang }} is the name you would usually give a {{'scope|l' | lang }} of work - it describes the set of campaign {{'deliverable.p|l' | lang }} you&#39;re preparing a cost for.</p>
      </div>
      <div class="flex-row pt-8" [has-privilege]="'OFFICE_LOCATION__VIEW'">
        <scope-ui-autocomplete
          [options]="officeLocations!"
          [initialValue]="updateScopeRequest.identity.officeLocation"
          [placeholder]="'Choose office location'"
          [optionFn]="officeLocationDisplayName"
          [noResultsMessage]="'No office locations found'"
          (onSelectionChange)="selectOfficeLocation($event)"
          />
        <p>Select an office location. This will determine the available {{'second_party.p|l' | lang }} and {{'brand.p|l' | lang }}.</p>
      </div>
      <div class="flex-row pt-8">
        <scope-ui-autocomplete
          [options]="getSecondParties()"
          [initialValue]="updateScopeRequest.identity.secondParty"
          [placeholder]="'Choose one ' + ('second_party|l' | lang)"
          [disabled]="!currentScope.isDraftOrConfigDraft()"
          [noResultsMessage]="'No ' + ('second_party.p|l' | lang) + ' found'"
          (onSelectionChange)="onSelectSecondParty($event)" />
        <p>Select from {{'second_party.p|l' | lang }} which can provide specific details of key contacts and locations.</p>
        <p>Please note, the rate-card of the {{'scope|l' | lang }} will remain unaffected and this {{'scope|l' | lang }} is using '{{currentScope.identity.rateCard.name}}' rate-card.</p>
      </div>
      <div class="flex-row pt-8" *ngIf="!currentScope.isScopeByRole()">
        <scope-ui-autocomplete
          [options]="selectedSecondParty?.brands || []"
          [initialValue]="updateScopeRequest.identity.brand"
          [placeholder]="'Choose ' + ('brand|l' | lang)"
          [disabled]="!(selectedSecondParty && selectedSecondParty.brands?.length)"
          [noResultsMessage]="'No ' + ('brand.p|l' | lang) + ' found'"
          (onSelectionChange)="onSelectBrand($event)" />
        <p *ngIf="!selectedSecondParty">{{'brand.p' | lang }} within {{'second_party.p|l' | lang }} can be selected once a {{'second_party|l' | lang }} has been selected.</p>
        <p *ngIf="selectedSecondParty?.brands?.length == 0">Your selected {{'second_party|l' | lang }} does not contain any {{'brand.p|l' | lang }}.</p>
        <p *ngIf="selectedSecondParty?.brands?.length">Optionally select from a {{'brand|l' | lang }} which can provide specific details of key contacts, locations and ratecard</p>
      </div>
      <div class="flex-row pt-8" *ngIf="!currentScope.isScopeByRole()">
        <scope-ui-autocomplete
          [options]="getContacts()"
          [initialValue]="updateScopeRequest.identity.contact"
          [placeholder]="'Choose a key contact'"
          [optionFn]="contactDisplayName"
          [displayFn]="contactDisplayName"
          [disabled]="!currentScope.isDraftOrConfigDraft()"
          [noResultsMessage]="'No key contacts found'"
          (onSelectionChange)="onSelectKeyContact($event)" />
        <p *ngIf="!selectedSecondParty">Key contacts within {{'second_party.p|l' | lang }} can be selected once a {{'second_party|l' | lang }} has been selected.</p>
        <p *ngIf="selectedSecondParty && !selectedBrand">All key contacts come from the contact list in {{'second_party' | lang }} card. If you&#39;re an admin member, you can set these up in the {{'second_party' | lang }} section</p>
        <p *ngIf="selectedSecondParty && selectedBrand">All key contacts come from the {{'brand|l' | lang }} location in {{'second_party' | lang }} card. If you&#39;re an admin member, you can set these up in the {{'second_party' | lang }} section</p>
      </div>
      <div class="flex-row pt-8" *ngIf="!currentScope.isScopeByRole()">
        <scope-ui-autocomplete
          [options]="getLocations()"
          [initialValue]="updateScopeRequest.identity.location"
          [placeholder]="'Choose a location'"
          [optionFn]="locationDisplayName"
          [displayFn]="locationSimpleName"
          [disabled]="!currentScope.isDraftOrConfigDraft() || selectedBrand != null"
          [noResultsMessage]="'No locations found'"
          (onSelectionChange)="onSelectLocation($event)" />
        <p *ngIf="!updateScopeRequest.identity.location">All {{'second_party' | lang }} locations come from the location list in {{'second_party' | lang }} card. If you&#39;re an admin member, you can set these up in the Client section</p>
        <ng-container *ngIf="updateScopeRequest.identity.location">
          <p *ngIf="findLocationByISO(updateScopeRequest.identity.location.country) as country"><span *ngIf="selectedBrand" >Provided by {{'brand|l' | lang }}. </span><span><b>{{updateScopeRequest.identity.location.location}}: </b><span>{{updateScopeRequest.identity.location.getAddress()}} ({{country?.Country_Name}}, {{country?.Continent}})</span></span></p>
        </ng-container>
      </div>
      <div class="flex-row pt-8">
        <scope-ui-autocomplete
          [options]="xlsxTemplates!"
          [initialValue]="updateScopeRequest.identity.xlsxTemplate"
          [placeholder]="'Choose an ' + ('output|l' | lang) + ' excel template'"
          [noResultsMessage]="'No templates found'"
          (onSelectionChange)="onSelectTemplate($event)" />
        <p>All excel {{'doc_template.p|l' | lang}}  come from the template list in {{'doc_template' | lang}} card. If you&#39;re an admin member, you can set these up in the {{'doc_template' | lang}} section</p>
      </div>
      <ng-container *ngIf="selectedSecondParty && selectedSecondParty.trafficImported && currentScope.identity.rateCard.rateCardIdentity.trafficImported && !currentScope.isScopeByRole()">
        <div class="flex-row pt-8" *ngFor="let field of trafficMetadataLookupFields">
          <ng-container *ngIf="!field.url">
            <textarea class="resize-none" matInput
                      placeholder="{{field.name}}"
                      (change)="updateTrafficSystemMetadataField(field.uuid)"
                      [(ngModel)]="trafficMetaDataFieldTemp[field.uuid]"
                      type="text" maxlength="122" required></textarea>
          </ng-container>
          <ng-container *ngIf="field.url">
            <scope-ui-autocomplete
              [options]="(trafficSystemMetadataSearch$[field.uuid] | async)!"
              [placeholder]="field.name"
              [filterByAction]="true"
              [initialValue]="updateScopeRequest.trafficSystemEntityMetadata[field.uuid]"
              [optionFn]="trafficFieldOption"
              (onSearch)="onSearchTrafficField($event, selectedSecondParty.id, currentScope.identity.rateCard.rateCardIdentity.id, field.uuid)"
              (onSelectionChange)="selectTrafficField($event, field.uuid)"/>
          </ng-container>
          <p>{{field.description}}</p>
        </div>
      </ng-container>
      <div class="flex-row pt-8" *ngIf="!currentScope.isScopeByRole()" [has-setting]="'SCOPE__BENCHMARK_RATE_CARD'">
        <scope-ui-autocomplete
          [options]="benchmarkRateCards!"
          [initialValue]="updateScopeRequest.identity.benchmarkRateCard"
          [placeholder]="'Select Benchmark Rate Card'"
          [disabled]="!selectedSecondParty"
          [noResultsMessage]="'No rate cards found'"
          (onSelectionChange)="onSelectBenchmarkRateCard($event)" />
      </div>
      <div class="flex flex-row pt-8" style="width: 500px;" [has-setting]="'SCOPE__START_END_DATE'">
        <scope-ui-datepicker class="flex flex-col flex-1 mr-4"
          inputLabel="Start Date"
          validationError="Some {{'deliverable.p|l' | lang}} have the dates outside of this range"
          [singleDatePicker]="true"
          [disabled]="!currentScope.isDraftOrConfigDraft()"
          [initialValue]="updateScopeRequest.startDate"
          [maxDate]="maximumStartDate"
          (onSelect)="onSelectStartDate($event)">
        </scope-ui-datepicker>
        <scope-ui-datepicker class="flex flex-col flex-1 ml-4"
          inputLabel="End Date"
          validationError="Some {{'deliverable.p|l' | lang}} have the dates outside of this range"
          [singleDatePicker]="true"
          [disabled]="!currentScope.isDraftOrConfigDraft()"
          [initialValue]="updateScopeRequest.endDate"
          [minDate]="getMinEndDate()"
          (onSelect)="onSelectEndDate($event)">
        </scope-ui-datepicker>
      </div>
      <div class="flex-row pt-8">
        <scope-ui-input
          [inputLabel]="getBudgetLabel()"
          [initialValue]="updateScopeRequest.budget?.amount | number:'1.2-2'"
          [required]="true"
          [minimum]="0"
          [type]="'text'"
          [parseNumber]="true"
          [isCurrency]="true"
          [currency]="currencyCode"
          [isDisabled]="!currentScope.isDraftOrConfigDraft()"
          (onInputBlurChange)="updateBudget($event)"
          aria-label="Budget"/>
        <p>An estimated budget of the {{'scope' | lang }}</p>
      </div>
      <div class="flex-row pt-8">
        <scope-ui-input
          [inputLabel]="getTPCBudgetLabel()"
          [initialValue]="updateScopeRequest.thirdPartyCostBudget?.amount | number:'1.2-2'"
          [required]="true"
          [minimum]="0"
          [type]="'text'"
          [parseNumber]="true"
          [isCurrency]="true"
          [currency]="currencyCode"
          [isDisabled]="!currentScope.isDraftOrConfigDraft()"
          (onInputBlurChange)="updateTPCBudget($event)"
          aria-label="Budget"/>
        <p>An estimated budget of the {{'scope' | lang }} third party cost</p>
      </div>
      <ng-container *ngIf="!currentScope.isScopeByRole() && (updateCustomFieldValueStructure?.fieldValues?.length || customFieldsDefinitionOutdated)">
        <div class="flex flex-row justify-between pt-8" style="width: 500px;">
          <p>Specific fields of the company</p>
          <button *ngIf="customFieldsDefinitionOutdated" mat-mini-fab [matMenuTriggerFor]="menu">
            <mat-icon class="material-symbols-rounded">more_horiz</mat-icon>
          </button>
        </div>
        <mat-menu #menu="matMenu" backdropClass="customize">
          <button (click)="confirmMetaStructureUpdate()" mat-menu-item class='scope-option'>
            <mat-icon class="material-symbols-rounded orange">warning</mat-icon>
            <span>Update custom fields</span>
          </button>
        </mat-menu>
        <div class="flex-row pt-8" *ngFor="let customField of updateCustomFieldValueStructure?.fieldValues | orderBy: 'order'">
          <label class="text-xs font-newJuneBold field-label">{{ currentScope.companyScopeCustomFieldsDefinition.getName(customField.id) }}</label>
          <scope-ui-input class="custom-field"
            [initialValue]="customField.value"
            (onInputChange)="customField.value = $event" />
        </div>
      </ng-container>
      <div class="flex-row pt-8" *ngIf="!currentScope.isScopeByRole() && currentScope.scopeOfWorkVersion && showUseRetainedTeamToggle">
        <mat-checkbox
          [disabled]="!currentScope.isDraftOrConfigDraft()"
          value="{{ currentScope.useRetainedHours }}"
          [checked]="currentScope.useRetainedHours"
          (change)="updateUseRetainedHours($event)"
          aria-label="Use Retained Hours"
        >Use Retained hours {{ isUseRetainedHours() ? ' (On)' : ' (Off)' }}</mat-checkbox>
      </div>
      <button mat-flat-button class="w-40 mt-10" [disabled]="!isFormValid() || !currentScope.isDraftOrConfigDraft()" (click)="saveDetails()">Save Details</button>
    </div>
  </div>
</div>
