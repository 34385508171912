<loader-modal *ngIf="loading"></loader-modal>
<div class="create-scope-container">
  <div class="create-scope-bg"></div>
  <div class="create-scope-wrapper flex flex-col">
    <div class="justify-start">
      <button class="text-link small ml-8 mt-4" aria-label="Back to My Scopes" (click)="backToScopes()">
        <mat-icon class="material-symbols-rounded orange">chevron_left</mat-icon>
        Back to My {{'scope.p' | lang }}
      </button>
    </div>
    <div class="create-scope-header justify-between">
      <div class="flex items-center">
        <h1 class="product-heading-bold" *ngIf="!id && isScopeByRole">Create {{'scope' | lang }} by Role</h1>
        <h1 class="product-heading-bold" *ngIf="!id && isScopeByScenario">Create Scenario Pricing</h1>
        <h1 class="product-heading-bold" *ngIf="!id && !isScopeByRole && !isScopeByScenario">Create {{'scope' | lang }}{{scopeByRoleEnabled() || scopeByScenarioEnabled() ? ' by Work' : ''}}</h1>
        <h1 class="product-heading-bold" *ngIf="id">Duplicate {{'scope' | lang }} {{scopeToDuplicate ? '"' + scopeToDuplicate.name + '"' : ''}}</h1>
      </div>
      <button mat-flat-button [disabled]="!isFormValid() || !isDynamicFieldsValid()" (click)="saveDetails()">Start Scoping</button>
    </div>
    <div class="create-scope-header justify-between mt-10" *ngIf="step < 4">
      <div class="step" (click)="goToStep(1)">
        <div class="number" *ngIf="step < 2">1</div>
        <div class="tick" *ngIf="step > 1"><mat-icon class="material-symbols-rounded orange">done</mat-icon></div>
        <span>Essential information</span>
      </div>
      <div class="line" [ngClass]="{'todo': step < 2}"></div>
      <div class="step" [ngClass]="{'todo': step < 2}" (click)="goToStep(2)">
        <div class="number" *ngIf="step < 3">2</div>
        <div class="tick" *ngIf="step > 2"><mat-icon class="material-symbols-rounded orange">done</mat-icon></div>
        <span>Financials</span>
      </div>
      <ng-container *ngIf="!isScopeByRole || (scopeTypes?.length > 0 || locationsCount)">
        <div class="line" [ngClass]="{'todo': step < 3}"></div>
        <div class="step" [ngClass]="{'todo': step < 3}" (click)="goToStep(3)">
          <div class="number">3</div>
          <span>More information</span>
        </div>
      </ng-container>
    </div>
    <div class="create-scope-panel step-1 flex flex-col mt-4 mb-10 {{!isScopeByRole || scopeTypes?.length > 0 ? '' : 'two-steps'}}" [hidden]="step !== 1">
      <h3 class="product-heading-bold">Essential information</h3>
      <div class="flex-row pt-6">
        <scope-ui-input
          [initialValue]="myNewScope.name"
          [inputLabel]="('scope' | lang) + ' name'"
          [required]="true"
          (onInputChange)="myNewScope.name = $event" />
      </div>
      <div class="flex-row pt-6" [has-privilege]="'OFFICE_LOCATION__VIEW'" *ngIf="(officeLocations$ | async)?.length">
        <scope-ui-autocomplete
          [initialValue]="myNewScope.officeLocation"
          [options]="(officeLocations$ | async)!"
          orderBy="name"
          [label]="'Office location'"
          [optionFn]="officeLocationDisplayName"
          (onSelectionChange)="selectOfficeLocation($event)" />
      </div>
      <div class="flex-row pt-6" *ngIf="isScopeByScenario">
        <scope-ui-autocomplete
          [initialValue]="myNewScope.scenario"
          [options]="(scenarios$ | async)!"
          [label]="'Scenario Form'"
          [required]="true"
          (onSelectionChange)="selectScenario($event)" />
      </div>
      <div class="flex flex-row pt-6 gap-4">
        <scope-ui-autocomplete
          class="flex-1"
          [initialValue]="myNewScope.secondParty"
          [options]="secondPartyOptions"
          orderBy="name"
          [label]="'second_party' | lang"
          [required]="true"
          (onSelectionChange)="onSelectSecondParty($event)" />
        <scope-ui-autocomplete
          class="flex-1"
          *ngIf="selectedSecondParty && selectedSecondParty.brands?.length"
          [initialValue]="myNewScope.brand"
          [options]="selectedSecondParty?.brands || []"
          orderBy="name"
          [label]="('brand' | lang)"
          (onSelectionChange)="onSelectBrand($event)" />
      </div>
      <div class="flex-row pt-6" *ngIf="createNewScope && !isScopeByRole && (scopeTemplates$ | async)?.length">
        <scope-ui-autocomplete
          [initialValue]="myNewScope.scopeTemplate"
          [options]="(scopeTemplates$ | async)!"
          [label]="('scope' | lang) +' Template'"
          (onSelectionChange)="onSelectScopeTemplate($event)" />
      </div>
      <div class="flex flex-row pt-6 gap-4">
        <scope-ui-autocomplete
          class="flex-1"
          [initialValue]="myNewScope.ratecard"
          [options]="ratecards"
          orderBy="name"
          [label]="'Rate Card'"
          [disabled]="!selectedSecondParty"
          [required]="true"
          [markerProperty]="'isCostPlus'"
          [markerText]="'Cost Plus'"
          (onSelectionChange)="onSelectRateCard($event)" />
        <scope-ui-autocomplete
          class="flex-1"
          *ngIf="!isScopeByRole"
          [initialValue]="myNewScope.benchmarkRateCard"
          [has-setting]="'SCOPE__BENCHMARK_RATE_CARD'"
          [options]="benchmarkRateCards!"
          orderBy="name"
          [label]="'Benchmark Rate Card'"
          [disabled]="!selectedSecondParty"
          (onSelectionChange)="onSelectBenchmarkRateCard($event)" />
      </div>
      <ng-container *ngIf="selectedSecondParty">
        <ng-container *ngIf="getContacts() as contacts">
          <div class="flex-row pt-6" *ngIf="contacts.length">
            <scope-ui-autocomplete
              [initialValue]="myNewScope.contact"
              [options]="contacts"
              orderBy="name"
              [label]="'Key Contact'"
              [optionFn]="contactDisplayName"
              [displayFn]="contactDisplayName"
              (onSelectionChange)="onSelectKeyContact($event)" />
          </div>
        </ng-container>
      </ng-container>
      <div class="flex flex-row pt-6 gap-4">
        <scope-ui-autocomplete
          class="flex-1"
          [initialValue]="myNewScope.outputTemplate"
          [options]="(wordTemplates$ | async)!"
          orderBy="name"
          [label]="'Document Template'"
          (onSelectionChange)="onSelectWordTemplate($event)" />
        <scope-ui-autocomplete
          *ngIf="(xlsxTemplates$ | async)?.length"
          class="flex-1"
          [initialValue]="myNewScope.xlsxTemplate"
          [options]="(xlsxTemplates$ | async)!"
          orderBy="name"
          [label]="'Excel Template'"
          (onSelectionChange)="onSelectXlsxTemplate($event)" />
      </div>
      <ng-container *ngIf="!isScopeByRole && selectedSecondParty && selectedSecondParty.trafficImported && myNewScope.ratecard && myNewScope.ratecard.rateCardIdentity.trafficImported">
        <div class="flex-row pt-6" *ngFor="let field of getTrafficFields()">
          <ng-container *ngIf="!field.url">
            <textarea class="resize-none" matInput
                      placeholder="{{field.name}}"
                      (change)="updateTrafficSystemMetadataField(field.uuid)"
                      [(ngModel)]="trafficMetaDataFieldTemp[field.uuid]"
                      type="text" maxlength="122" required></textarea>
          </ng-container>
          <ng-container *ngIf="field.url">
            <scope-ui-autocomplete
              [initialValue]="myNewScope.trafficSystemMetadata?.[field.uuid]"
              [options]="(trafficSystemMetadataSearch$[field.uuid] | async)!"
              [label]="field.name"
              [filterByAction]="true"
              [optionFn]="trafficFieldOption"
              (onSearch)="onSearchTrafficField($event, selectedSecondParty.id, myNewScope.ratecard.rateCardIdentity.id, field.uuid)"
              (onSelectionChange)="selectTrafficField($event, field.uuid)"/>
          </ng-container>
        </div>
      </ng-container>
      <scope-ui-duration
        class="pt-6 block"
        [has-setting]="'SCOPE__START_END_DATE'"
        [(startDate)]='myNewScope.startDate'
        [(endDate)]='myNewScope.endDate'
        [(selectedPeriod)]='selectedPeriod'
        [(countDays)]='countDays' >
      </scope-ui-duration>
      <div class="flex flex-row pt-6 gap-4"
           [has-setting]="'TIMELINE_VIEW__ENABLE'"
           *ngIf="!isScopeByRole">
        <h5 class="product-heading-bold">
          Default view
        </h5>
        <scope-ui-radio class="flex-1"
                        [items]='scopeViews'
                        [(ngModel)]='myNewScope.defaultView'
                        [name]="'selectView'"
                        (ngModelChange)='onViewRadioChange($event)'
        ></scope-ui-radio>
      </div>
      <div class="flex justify-end pt-6 mt-auto">
        <button class="mt-4 text-link" aria-label="Next" [disabled]="!isFormValid()" (click)="goToStep(2)">
          Next
          <mat-icon class="material-symbols-rounded orange">chevron_right</mat-icon>
        </button>
      </div>
    </div>
    <div class="create-scope-panel step-2 flex flex-col mt-4 mb-10"
      [ngClass]="{'two-steps' : !(!isScopeByRole || (scopeTypes?.length > 0 || locationsCount))}"
      [hidden]="step !== 2">
      <h3 class="product-heading-bold">Financials</h3>
      <div class="flex-row pt-6">
        <scope-ui-input
          [initialValue]="myNewScope.budget"
          [inputLabel]="getBudgetLabel()"
          [type]="'text'"
          [parseNumber]="true"
          [isCurrency]="true"
          [currency]="myNewScope.ratecard?.currencyCode"
          (onInputBlurChange)="myNewScope.budget = $event"
          aria-label="Budget"/>
      </div>
      <div class="flex-row pt-6">
        <scope-ui-input
          [initialValue]="myNewScope.thirdPartyCostBudget"
          [inputLabel]="getTPCBudgetLabel()"
          [type]="'text'"
          [parseNumber]="true"
          [isCurrency]="true"
          [currency]="myNewScope.ratecard?.currencyCode"
          (onInputBlurChange)="myNewScope.thirdPartyCostBudget = $event"
          aria-label="Third Party CostBudget"/>
      </div>
      <div class="flex justify-between pt-6 mt-auto">
        <button class="text-link mt-4" aria-label="Next" (click)="goToStep(1)">
          <mat-icon class="material-symbols-rounded orange">chevron_left</mat-icon>
          Previous
        </button>
        <button class="text-link mt-4" aria-label="Next" (click)="goToStep(!isScopeByRole || (scopeTypes?.length > 0) ? 3 : 4)">
          Next
          <mat-icon class="material-symbols-rounded orange">chevron_right</mat-icon>
        </button>
      </div>
    </div>
    <div class="create-scope-panel step-3 flex flex-col mt-4 mb-10" [hidden]="step !== 3">
      <h3 class="product-heading-bold">More information</h3>
      <h5 class="product-heading-bold margin-top-10" *ngIf="!isScopeByRole && myNewScope.scopeVersionCustomFieldValueStructure?.fieldValues?.length">Scope Custom Fields</h5>
      <div class="flex flex-row flex-wrap pt-6 gap-4" *ngIf="!isScopeByRole && myNewScope.scopeVersionCustomFieldValueStructure?.fieldValues?.length">
        <scope-ui-input
          class="custom-field-input flex"
          *ngFor="let customField of myNewScope.scopeVersionCustomFieldValueStructure?.fieldValues | orderBy: 'order'"
          [initialValue]="customField.value"
          [inputLabel]="customField.name!"
          (onInputChange)="customField.value = $event" />
      </div>
      <h5 class="product-heading-bold margin-top-10" [has-setting]="'SCOPE_DYNAMIC_FIELDS'" *ngIf="scopeDynamicFieldSettings?.length > 0">Scope Dynamic Fields</h5>
      <div class="flex flex-row flex-wrap pt-6 gap-4" [has-setting]="'SCOPE_DYNAMIC_FIELDS'" *ngIf="scopeDynamicFieldSettings?.length > 0">
        <scope-ui-input
          class="custom-field-input flex"
          *ngFor="let setting of scopeDynamicFieldTextSettings"
          [initialValue]="getTextDynamicFieldValue(setting)"
          [inputLabel]="setting.displayName!"
          [required]='true'
          (onInputChange)="onInputDynamicFieldTextSettingChange(setting, $event)" />
        <scope-ui-dropdown
          class="flex-1"
          *ngFor="let settingWithOptions of scopeDynamicFieldDropdownSettingsWithOptions"
          [controlLabel]="settingWithOptions.setting.displayName"
          [dropdownOptions]="settingWithOptions.options"
          [selectedValue]="getDropdownDynamicFieldValue(settingWithOptions)"
          [isStandardDropdown]="true"
          (onSelectionChange)="onSelectDropdownDynamicField($event, settingWithOptions.setting.name)"/>
        <scope-ui-dropdown
          class="flex-1"
          *ngFor="let externalSettingWithOptions of scopeDynamicFieldDropdownExternalSettingsWithOptions"
          [controlLabel]="externalSettingWithOptions.setting.displayName"
          [dropdownOptions]="externalSettingWithOptions.options"
          [selectedValue]="getExternalDropdownDynamicFieldValue(externalSettingWithOptions)"
          [isStandardDropdown]="true"
          (onSelectionChange)="onSelectDropdownExternalDynamicField($event, externalSettingWithOptions)"/>
      </div>
      <div class="flex-row pt-6" [has-setting]="'SCOPE__TYPE_VIEW'" *ngIf="scopeTypes?.length > 0">
        <scope-ui-autocomplete
          class="flex-1"
          [initialValue]="myNewScope.scopeType"
          [options]="scopeTypes"
          orderBy="name"
          [label]="('scope' | lang) +' type'"
          (onSelectionChange)="onSelectScopeType($event)" />
      </div>
      <div class="flex flex-row pt-6 gap-4">
        <div class="flex-1 create-scope-location" *ngIf="locationsCount">
          <scope-ui-autocomplete
            [initialValue]="myNewScope.location"
            [options]="getLocations()"
            orderBy="name"
            [label]="'Location'"
            [optionFn]="locationDisplayName"
            [displayFn]="locationSimpleName"
            [disabled]="myNewScope.brand != null"
            (onSelectionChange)="onSelectLocation($event)" />
          <ng-container *ngIf="myNewScope.location">
            <p *ngIf="findLocationByISO(myNewScope.location.country) as country">
              <mat-icon class='material-symbols-rounded'>info</mat-icon>
              <span *ngIf="myNewScope.brand" >Provided by {{'brand|l' | lang }}. </span>
              <span>
                <b>{{myNewScope.location.location}}: </b>
                <span>{{myNewScope.location.getAddress()}} ({{country?.Country_Name}}, {{country?.Continent}})</span>
              </span>
            </p>
          </ng-container>
        </div>
        <scope-ui-dropdown
          *ngIf="!isScopeByRole"
          class="flex-1"
          [selectedValue]="selectedLanguage"
          [controlLabel]="'Language'"
          [dropdownOptions]="languages"
          [isStandardDropdown]="true"
          (onSelectionChange)="onSelectLanguage($event)"/>
      </div>
      <div class="flex justify-between pt-6 mt-auto">
        <button class="text-link mt-4" aria-label="Next" (click)="goToStep(2)">
          <mat-icon class="material-symbols-rounded orange">chevron_left</mat-icon>
          Previous
        </button>
        <button class="text-link mt-4" aria-label="Next" (click)="goToStep(4)">
          See summary
          <mat-icon class="material-symbols-rounded orange">chevron_right</mat-icon>
        </button>
      </div>
    </div>
    <div class="create-scope-panel create-scope-summary flex flex-col flex-1 mt-4 mb-10" [hidden]="step !== 4">
      <h3 class="product-heading-bold">{{'scope' | lang}} details summary</h3>
      <div class="step-section pt-10">
        <div class="flex gap-2">
          <div class="number">1</div>
          <h4 class="product-heading-bold pt-1">Essential information</h4>
        </div>
        <button class='text-link small' aria-label="Edit essential information" (click)="goToStep(1)">
          <mat-icon class="material-symbols-rounded fill orange small">edit</mat-icon>
          Edit
        </button>
      </div>
      <div class="flex-row pt-6 pl-8">
        <label>{{'scope' | lang}} name</label>
        <span class="value">{{myNewScope.name}}</span>
      </div>
      <div class="flex-row pt-6 pl-8" [has-privilege]="'OFFICE_LOCATION__VIEW'" *ngIf="(officeLocations$ | async)?.length">
        <label>Office location</label>
        <span class="value" [ngClass]="{'no-value': !myNewScope.officeLocation}">{{myNewScope.officeLocation?.name || 'Not selected'}}</span>
      </div>
      <div class="flex-row pt-6 pl-8" *ngIf="isScopeByScenario">
        <label>Scenario Form</label>
        <span class="value" [ngClass]="{'no-value': !myNewScope.scenarioId}">{{myNewScope.scenario?.name || 'Not selected'}}</span>
      </div>
      <div class="flex flex-row pt-6 pl-8 gap-4">
        <div class="flex-1">
          <label>{{'second_party' | lang}}</label>
          <span class="value" [ngClass]="{'no-value': !myNewScope.secondParty}">{{myNewScope.secondParty?.name || 'Not selected'}}</span>
        </div>

        <div class="flex-1">
          <label>{{'brand' | lang}}</label>
          <span class="value" [ngClass]="{'no-value': !myNewScope.brand}">{{myNewScope.brand?.name || 'Not selected'}}</span>
        </div>
      </div>
      <div class="flex-row pt-6 pl-8" *ngIf="!isScopeByRole && (scopeTemplates$ | async)?.length && createNewScope">
        <label>{{'scope' | lang}} Template</label>
        <span class="value" [ngClass]="{'no-value': !myNewScope.scopeTemplate}">{{myNewScope.scopeTemplate?.name || 'Not selected'}}</span>
      </div>
      <div class="flex flex-row pt-6 pl-8 gap-4">
        <div class="flex-1">
          <label>Rate Card</label>
          <span class="value" [ngClass]="{'no-value': !myNewScope.ratecard}">{{myNewScope.ratecard?.name || 'Not selected'}}</span>
        </div>
        <div class="flex-1" *ngIf="!isScopeByRole">
          <label>Benchmark Rate Card</label>
          <span class="value" [ngClass]="{'no-value': !myNewScope.benchmarkRateCard}">{{myNewScope.benchmarkRateCard?.name || 'Not selected'}}</span>
        </div>
      </div>
      <ng-container *ngIf="getContacts() as contacts">
        <div class="flex-row pt-6 pl-8" *ngIf="selectedSecondParty && contacts.length">
          <label>Key Contact</label>
          <span class="value" [ngClass]="{'no-value': !myNewScope.contact}">{{myNewScope.contact?.contactName || 'Not selected'}}</span>
        </div>
      </ng-container>
      <div class="flex flex-row pt-6 pl-8 gap-4">
        <div class="flex-1">
          <label>Document Template</label>
          <span class="value" [ngClass]="{'no-value': !myNewScope.outputTemplate}">{{myNewScope.outputTemplate?.name || 'Not selected'}}</span>
        </div>
        <div class="flex-1" *ngIf="(xlsxTemplates$ | async)?.length">
          <label>Excel Template</label>
          <span class="value" [ngClass]="{'no-value': !myNewScope.xlsxTemplate}">{{myNewScope.xlsxTemplate?.name || 'Not selected'}}</span>
        </div>
      </div>
      <ng-container *ngIf="!isScopeByRole && selectedSecondParty && selectedSecondParty.trafficImported && myNewScope.ratecard && myNewScope.ratecard.rateCardIdentity.trafficImported">
        <div class="flex-row pt-6 pl-8" *ngFor="let field of getTrafficFields()">
          <label>{{field.name}}</label>
          <span class="value">{{myNewScope.trafficSystemMetadata ? trafficFieldOption(myNewScope.trafficSystemMetadata[field.uuid]) : ''}}</span>
        </div>
      </ng-container>
      <div class="flex flex-row pt-6 pl-8 gap-4" [has-setting]="'SCOPE__START_END_DATE'">
        <div class="flex flex-1 gap-4">
          <div class="flex-1">
            <label>Duration</label>
            <span class="value">{{selectedPeriod.name}}</span>
          </div>
          <div class="flex-1" [hidden]="selectedPeriod.value !== 'fixed_count_days'">
            <label>Count</label>
            <span class="value">{{countDays}}</span>
          </div>
        </div>
        <div class="flex-1">
          <label>Start Date</label>
          <span class="value">{{myNewScope.startDate | dateFormat: 'shortDate'}}</span>
        </div>
        <div class="flex-1">
          <label>End Date</label>
          <span class="value">{{myNewScope.endDate | dateFormat: 'shortDate'}}</span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="step-section pt-10">
        <div class="flex gap-2">
          <div class="number">2</div>
          <h4 class="product-heading-bold pt-1">Financials</h4>
        </div>
        <button class='text-link small' aria-label="Edit essential information" (click)="goToStep(2)">
          <mat-icon class="material-symbols-rounded fill orange small">edit</mat-icon>
          Edit
        </button>
      </div>
      <div class="flex flex-row pt-6 pl-8">
        <div class="flex-1">
          <label>Budget</label>
          <span class="value" [ngClass]="{'no-value': !myNewScope.budget}">{{(myNewScope.budget | currency: myNewScope.ratecard?.currencyCode) || '--'}}</span>
        </div>
        <div class="flex-1">
          <label>Third Party Cost Budget</label>
          <span class="value" [ngClass]="{'no-value': !myNewScope.thirdPartyCostBudget}">{{(myNewScope.thirdPartyCostBudget | currency: myNewScope.ratecard?.currencyCode) || '--'}}</span>
        </div>
      </div>
      <ng-container *ngIf="!isScopeByRole || (scopeTypes?.length > 0 || locationsCount)">
        <mat-divider></mat-divider>
        <div class="step-section pt-10">
          <div class="flex gap-2">
            <div class="number">3</div>
            <h4 class="product-heading-bold pt-1">More information</h4>
          </div>
          <button class='text-link small' aria-label="Edit essential information" (click)="goToStep(3)">
            <mat-icon class="material-symbols-rounded fill orange small">edit</mat-icon>
            Edit
          </button>
        </div>
        <div class="pt-6 pl-8" *ngIf="!isScopeByRole && myNewScope.scopeVersionCustomFieldValueStructure?.fieldValues?.length">
          <h6 class="product-heading-bold pt-1">Scope Custom Fields</h6>
          <div class="flex flex-row flex-wrap pt-4 gap-4">
            <div class="custom-field-input"
                *ngFor="let customField of myNewScope.scopeVersionCustomFieldValueStructure?.fieldValues | orderBy: 'order'">
              <label>{{customField.name}}</label>
              <span class="value">{{customField.value}}</span>
            </div>
          </div>
        </div>
        <div class="pt-6 pl-8" [has-setting]="'SCOPE_DYNAMIC_FIELDS'" *ngIf="scopeDynamicFieldSettings?.length > 0">
          <h6 class="product-heading-bold pt-1">Scope Dynamic Fields</h6>
          <div class="flex flex-row flex-wrap pt-4 gap-4">
            <div class="custom-field-input"
                *ngFor="let dynamicField of scopeDynamicFieldSettings">
              <label>{{dynamicField.displayName}}</label>
              <ng-container [ngSwitch]="dynamicField.type">
                <span class="value" *ngSwitchCase="DynamicFieldType.DROPDOWN_EXTERNAL">
                  {{ getExternalDropdownDynamicFieldDisplayValue(dynamicField) || '--'}}
                </span> 
                <span class="value" *ngSwitchDefault>
                  {{ getTextDynamicFieldValue(dynamicField) || '--'}}
                </span> 
              </ng-container>
            </div>
          </div>
        </div>
        <div class="flex-row pt-6 pl-8" [has-setting]="'SCOPE__TYPE_VIEW'" *ngIf="scopeTypes?.length > 0">
          <div class="flex-1">
            <label>{{'scope' | lang}} type</label>
            <span class="value" [ngClass]="{'no-value': !myNewScope.scopeType}">{{myNewScope.scopeType?.name || 'Not selected'}}</span>
          </div>
        </div>
        <div class="flex flex-row pt-6 pl-8 gap-4">
          <div class="flex-1" *ngIf="locationsCount">
            <label>Location</label>
            <span class="value" [ngClass]="{'no-value': !myNewScope.location}">{{myNewScope.location?.location || 'Not selected'}}</span>
          </div>
          <div class="flex-1" *ngIf="!isScopeByRole">
            <label>Language</label>
            <span class="value" [ngClass]="{'no-value': !myNewScope.language}">{{getSelectedLanguageName() || 'Not selected'}}</span>
          </div>
        </div>
      </ng-container>
      <div class="flex justify-end mt-10">
        <button mat-flat-button [disabled]="!isFormValid() || !isDynamicFieldsValid()" (click)="saveDetails()">Start Scoping</button>
      </div>
    </div>
  </div>
</div>
