<div *ngIf="isStandardDropdown; then standardDropdown; else checkboxDropdown"></div>

<ng-template #standardDropdown>
  <mat-label *ngIf="customSelectIcon" [style.display]="controlLabel?.length ? 'inline-block' : 'none'">{{ controlLabel }}</mat-label>
  <mat-form-field [class]='dropdownClass' [floatLabel]="controlLabel?.length ? 'auto':  'always'">
    <mat-label *ngIf="!customSelectIcon" [style.display]="controlLabel?.length ? 'inline-block' : 'none'">
      {{ controlLabel }}
    </mat-label>
    <mat-select disableRipple (selectionChange)="onSelection($event)" [compareWith]="compare" [required]="required"
                [formControl]="selectControl" [placeholder]="inputPlaceholder">
      <mat-option [class]='dropdownClass' *ngFor="let option of dropdownOptions" [value]="optionValue(option)">
        {{ viewOption(option) }}
      </mat-option>
    </mat-select>
    <mat-icon class="material-symbols-rounded" *ngIf="customSelectIcon" matPrefix>{{ customSelectIcon }}</mat-icon>
    <mat-icon class="material-symbols-rounded {{matSelect?.panelOpen ? 'panel-open' : ''}}" *ngIf="!isDisabled" matSuffix>expand_more</mat-icon>
    <mat-error *ngIf="selectControl.hasError('required')">This is required</mat-error>
  </mat-form-field>
</ng-template>

<ng-template #checkboxDropdown>
  <mat-form-field [class]='dropdownClass' [floatLabel]="controlLabel?.length ? 'auto':  'always'">
    <mat-label [style.display]="controlLabel?.length ? 'inline-block' : 'none'">
      {{ controlLabel }} ({{ selectControl.value?.length || 0 }})
    </mat-label>
    <mat-select (selectionChange)="onSelection($event)" [formControl]="selectControl" [compareWith]="compare"
                [required]="required" [placeholder]="inputPlaceholder" multiple>
      <mat-option [class]='dropdownClass' *ngFor="let option of dropdownOptions" [value]="optionValue(option)"
                  #matOption [disabled]="minimum && selectControl.value?.length === 1 && matOption.selected">{{ viewOption(option) }}</mat-option>
    </mat-select>
    <mat-icon class="material-symbols-rounded" *ngIf="!isDisabled" matSuffix>expand_more</mat-icon>
  </mat-form-field>
</ng-template>
